<template>
  <div>
    <v-btn-toggle
      v-model="required"
      class="d-flex flex-wrap"
      active-class="active-button"
      borderless
      background-color="rgba(0,0,0,0)"
      @change="requiredSelected"
    >
      <v-btn class="ma-1" value="nexus-360-immediate" :disabled="disabled">
        Nexus 360° (Immediate) *
      </v-btn>

      <v-btn
        class="ma-1"
        value="acrylic-bridge-on-ti-base"
        :disabled="disabled"
      >
        Acrylic Bridge on Ti Base
      </v-btn>

      <v-btn class="ma-1" value="design-only-next-day" :disabled="disabled">
        Design Only: Next Day
      </v-btn>

      <v-btn class="ma-1" value="design-only-same-day" :disabled="disabled">
        Design Only: Same Day
      </v-btn>
    </v-btn-toggle>
    <small class="red--text">
      * 360° Immediates not available in North America, Europe &amp; Japan.
    </small>
  </div>
</template>

<script>
export default {
  name: "RestorativeProtocol",

  props: ["value", "disabled"],

  data() {
    return {
      required: this.value
    };
  },

  methods: {
    requiredSelected() {
      this.$emit("input", this.required);
    }
  }
};
</script>

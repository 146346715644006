<template>
  <v-dialog v-model="tabDialog" fullscreen>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title class="text-h5">
          {{ $t("nexusios.patientOrder.approvalFiles") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeApprovalFileDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs color="white" v-model="tabs">
            <v-tab
              v-for="(obj, index) in tabFileBlurbs"
              :key="index"
              @click="getData(index, obj)"
            >
              <v-icon left color="yellow" v-if="approvalRequired(obj)">
                mdi-exclamation-thick
              </v-icon>
              <v-icon left color="green" v-if="approvalComplete(obj)">
                mdi-check
              </v-icon>
              {{ obj.title | uppercase }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(obj, index) in tabFileBlurbs" :key="index">
            <v-card flat class="px-4">
              <v-card-title class="text-h5">
                {{ obj.title | uppercase }}
              </v-card-title>
            </v-card>
            <approval-files-card
              :dialog="approvalFileDialog"
              :messaging="getCaseStatus(index) === 'Needs Approval'"
              :caseNumber="getCaseNumber(index)"
              :caseMKey="getCaseMKey(index)"
              :caseStatus="getCaseStatus(index)"
              :patientName="getPatientName(index)"
              :practiceName="getPracticeName(index)"
              :loadingDialog="loadingDialog"
              :files="getFiles(index)"
              :notes="getNotes(index)"
              v-on:childShow="closeApprovalFileDialog"
            >
            </approval-files-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApprovalFileCard from "./ApprovalFileCard.vue";

export default {
  name: "PatientOrderTabs",

  components: {
    "approval-files-card": ApprovalFileCard
  },

  props: [
    "tabDialog",
    "caseNumber",
    "caseMKey",
    "patientName",
    "practiceName",
    "caseBundleDetail"
  ],

  data() {
    return {
      selectedOrder: false,
      showOrder: false,
      loading: true,
      approvalFileDialog: false,
      caseBundleData: [],
      loadingApprovalDialog: false,
      loadingDialog: false,
      tabs: null
    };
  },
  computed: {
    tabFileBlurbs() {
      const bundle_ids = this.caseBundleDetail ? this.caseBundleDetail : [];
      let fileBlurbs = [];
      for (const bundle of bundle_ids) {
        fileBlurbs.push({
          title: bundle.jobType,
          caseNumber: bundle.caseNumber,
          caseMKey: bundle.caseMKey,
          caseStatus: bundle.caseStatus
        });
      }
      return fileBlurbs;
    }
  },

  methods: {
    getCaseNumber(idx) {
      return this.caseBundleData[idx]
        ? this.caseBundleData[idx].caseNumber
        : null;
    },

    getCaseMKey(idx) {
      return this.caseBundleData[idx]
        ? this.caseBundleData[idx].caseMKey
        : null;
    },

    getCaseStatus(idx) {
      const status = this.caseBundleData[idx]
        ? this.caseBundleData[idx].caseStatus
        : null;
      return status;
    },

    getPatientName(idx) {
      return this.caseBundleData[idx]
        ? this.caseBundleData[idx].patientName
        : null;
    },

    getPracticeName(idx) {
      return this.caseBundleData[idx]
        ? this.caseBundleData[idx].practiceName
        : null;
    },

    getFiles(idx) {
      return this.caseBundleData[idx] ? this.caseBundleData[idx].files : {};
    },

    getNotes(idx) {
      return this.caseBundleData[idx] ? this.caseBundleData[idx].notes : null;
    },

    approvalRequired(obj) {
      return obj.caseStatus == "Needs Approval" ? true : false;
    },

    approvalComplete(obj) {
      return obj.caseStatus == "Complete"
        ? true
        : obj.caseStatus == "Dispatched"
        ? true
        : false;
    },

    closeApprovalFileDialog() {
      this.approvalFileDialog = false;
      this.noteAttachment = null;
      this.inputNote = "";
      this.changesNote = "";
      this.approvalStatus = "";
      this.alert = false;
      this.$emit("childShow");
    },

    getApprovalFiles: async function(caseNumber) {
      let files = await this.$httpClient.getCaseFilesApproval(caseNumber);
      return {
        caseNumber: caseNumber,
        patientName: this.patientName,
        practiceName: this.practiceName,
        files: files,
        notes: files.client_notes
      };
    },

    getData: async function(index, obj) {
      this.approvalFileDialog = false;
      let files = await this.getApprovalFiles(obj.caseNumber);
      let bundleData = {
        caseNumber: obj.caseNumber,
        caseMKey: obj.caseMKey,
        caseStatus: this.caseBundleDetail[index].caseStatus
          ? this.caseBundleDetail[index].caseStatus
          : null,
        patientName: this.patientName,
        practiceName: this.practiceName,
        files: files.files,
        notes: files.notes
      };
      this.caseBundleData[index] = bundleData;
      this.approvalFileDialog = true;
    }
  },

  watch: {
    tabDialog: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getData(0, {
          caseNumber: this.caseNumber,
          caseMKey: this.caseMKey
        });
      }
      if (newVal) {
        document.title = `${this.caseMKey} (${this.caseNumber}) Approval - Order - Nexus iOS`;
        history.pushState({}, null, this.$route.path + "#");
      } else {
        document.title = "Order - Nexus iOS";
        history.pushState({}, null, this.$route.path);
      }
    }
  }
};
</script>

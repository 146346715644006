<template>
  <v-btn-toggle
    v-model="color"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="colorSelected"
  >
    <v-btn
      class="ma-1"
      value="none"
      :disabled="disabled"
      v-if="indicationType != 'overdenture-on-attachments'"
    >
      {{ $t("common.none") }}
    </v-btn>
    <v-btn class="ma-1 light" value="light" :disabled="disabled">
      {{ $t("productOptions.pinkGingivalColour.light") }}
    </v-btn>
    <v-btn class="ma-1 standard" value="standard" :disabled="disabled">
      {{ $t("productOptions.pinkGingivalColour.standard") }}
    </v-btn>
    <v-btn class="ma-1 dark" value="dark" :disabled="disabled">
      {{ $t("productOptions.pinkGingivalColour.dark") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "PinkGingivalColour",

  props: ["value", "disabled", "indicationType"],

  data() {
    return {
      color: this.value
    };
  },

  methods: {
    colorSelected() {
      this.$emit("input", this.color);
    }
  }
};
</script>

<template>
  <span>
    <v-toolbar light color="white">
      <v-btn icon @click.stop="drawer = !drawer" class="d-md-none">
        <v-icon color="white">mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title class="ma-0 pa-0 pr-5">
        <router-link :to="logoRoute()">
          <v-img
            src="../assets/nexus-logo.png"
            width="200"
            height="40"
            contain
          ></v-img
        ></router-link>
      </v-toolbar-title>
      <v-toolbar-items class="d-none d-md-flex">
        <v-menu
          open-on-hover
          offset-y
          v-for="item in filteredMenuItems()"
          :key="item.path"
        >
          <template v-slot:activator="{ on }">
            <v-btn text :to="item.path" :exact="true" v-on="on">
              <strong>{{ $t(item.text) }}</strong>
            </v-btn>
          </template>
          <v-list v-if="item.menuItems">
            <v-list-item
              v-for="menu_item in item.menuItems"
              :key="menu_item.path"
              :to="menu_item.path"
              :exact="menu_item.exact"
            >
              <v-list-item-title>
                {{ $t(menu_item.text) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <LanguageSwitcher />
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2"
        elevation="0"
        color="primary"
        :to="client ? { name: 'support' } : { name: 'register' }"
      >
        <v-icon class="d-sm-none">
          mdi-phone-message
        </v-icon>
        <span class="d-none d-sm-flex">
          {{ $t("products.chairsideSupport") }}
        </span>
      </v-btn>
      <UserButton />
      <template v-if="$vuetify.breakpoint.smAndUp"></template>
    </v-toolbar>

    <v-navigation-drawer app temporary v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $t("common.nexusIos") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("common.tagLine") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in filteredMenuItems()"
          :key="item.path"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import UserButton from "./AppBar/UserButton";
import LanguageSwitcher from "./i18n/LanguageSwitcher";

export default {
  name: "AppBar",

  components: {
    UserButton,
    LanguageSwitcher
  },

  data() {
    return {
      drawer: false,
      menuItems: [
        {
          text: "common.orderNow",
          path: "/order",
          icon: "mdi-shopping",
          onlyApproved: true
        },
        {
          text: "common.instructions",
          path: "/instructions",
          icon: "mdi-library-books",
          onlyApproved: true
        },
        {
          text: "common.contact",
          path: "/contact",
          icon: "mdi-at",
          onlyApproved: false
        }
      ]
    };
  },

  computed: {
    ...mapGetters(["client", "approvedClient", "user"])
  },

  methods: {
    filteredMenuItems() {
      return this.approvedClient
        ? this.menuItems
        : this.menuItems.filter(item => !item.onlyApproved);
    },

    logoRoute() {
      return this.approvedClient ? "/" : "/register";
    }
  }
};
</script>

<template>
  <v-menu offset-y v-if="!loading">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" elevation="0" v-on="on" @click="buttonClickAction">
        <v-icon class="mr-2">
          mdi-account
        </v-icon>
        <span class="d-none d-sm-flex">{{ userButtonText }}</span>
      </v-btn>
    </template>
    <v-list v-if="loggedIn" color="secondary">
      <v-list-item
        v-for="(item, i) in userMenuItems"
        :key="i"
        :to="{ name: item.route_name }"
        exact
      >
        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
      </v-list-item>
      <v-divider v-if="userMenuItems.length > 0"></v-divider>
      <v-list-item>
        <v-list-item-title>
          <a @click="logout" class="red--text">
            {{ $t("common.logout") }}
            <!-- Logout -->
          </a>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserButton",

  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapGetters(["user", "approvedClient"]),

    userMenuItems() {
      if (this.approvedClient) {
        return [
          {
            route_name: "details",
            text: "common.details",
            icon: "mdi-account"
          },
          {
            route_name: "practices",
            text: "common.practices",
            icon: "mdi-hospital-box"
          },
          {
            route_name: "scan-gauge-kits",
            text: "nexusios.userButton.scanGaugeKits",
            icon: "mdi-blur-linear"
          },
          {
            route_name: "orders",
            text: "nexusios.account.orderHistory.orderHistory",
            icon: "mdi-history"
          }
        ];
      } else {
        return [];
      }
    },

    userButtonText() {
      return this.user ? this.user.friendly_name : "Login";
    },

    loggedIn() {
      return !!this.user;
    }
  },

  methods: {
    ...mapActions(["fetchUser", "logout"]),

    buttonClickAction() {
      if (this.user) {
        return false;
      } else {
        document.location = "/auth/auth0";
      }
    }
  },

  async mounted() {
    this.loading = false;
  }
};
</script>

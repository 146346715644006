var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-item-group',{attrs:{"active-class":"active-button"},on:{"change":_vm.extentionSelected},model:{value:(_vm.extention),callback:function ($$v) {_vm.extention=$$v},expression:"extention"}},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-item',{attrs:{"value":"follow-scan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"ma-1",attrs:{"disabled":_vm.disabled},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm.$t("productOptions.distalExtensions.followScan"))+" ")])]}}])})],1),(_vm.left_or_right == 'left')?_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-item',{attrs:{"value":"second-molar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-molar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,400362071)}),_c('v-item',{attrs:{"value":"first-molar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-molar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,400362071)}),_c('v-item',{attrs:{"value":"second-premolar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-premolar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,1074825456)}),_c('v-item',{attrs:{"value":"first-premolar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-premolar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,1074825456)})],1):_vm._e(),(_vm.left_or_right == 'right')?_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-item',{attrs:{"value":"first-premolar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-premolar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,1074825456)}),_c('v-item',{attrs:{"value":"second-premolar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-premolar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,1074825456)}),_c('v-item',{attrs:{"value":"first-molar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-molar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,400362071)}),_c('v-item',{attrs:{"value":"second-molar","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":require("../../../assets/distal-extensions/nio-molar.png"),"width":"10%","disabled":_vm.disabled},on:{"click":toggle}})]}}],null,false,400362071)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
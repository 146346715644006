<template>
  <v-app v-bind:class="{ nowordbreak: !wordBreak }" v-if="isReady">
    <AppBar />
    <v-container fluid class="fill-height pa-0">
      <router-view />
    </v-container>
    <Footer v-if="$route.name != 'instructions-viewer'" />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Nexus-iOS",

  components: {
    AppBar,
    Footer
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.meta.title) {
          document.title = `${to.meta.title} - Nexus iOS`;
        } else {
          document.title = "Nexus iOS";
        }
      }
    }
  },

  data() {
    return {
      isReady: false
    };
  },

  computed: {
    ...mapGetters(["approvedClient"]),

    wordBreak() {
      return this.$store.state.currentLocale.wordBreak;
    }
  },

  methods: {
    ...mapActions(["fetchUser"])
  },

  beforeCreate: function() {
    this.$vuetify.theme.dark = true;
  },

  async mounted() {
    if (
      this.$route.name !== "register" ||
      this.$route.name !== "register-confirmed" ||
      this.$route.name !== "contact"
    ) {
      await this.fetchUser().then(() => {
        this.isReady = true;
        if (!this.approvedClient) {
          this.$router.push({ name: "register" });
        }
      });
    } else {
      await this.fetchUser().then(() => (this.isReady = true));
    }
  }
};
</script>

<style lang="sass">

$grey: #333
$dark-grey: rgb(25,25,25)
$blue: #147bd1  //#30a4e0
$dark-blue: #0071ff

@media only screen and (min-width : 0px)
  // *
  //   color: yellow

  .nowordbreak h1, h2
    font-size: 1rem

@media only screen and (min-width : 600px)
  // *
  //   color: blue

  .nowordbreak h1, h2
    font-size: 1rem

@media only screen and (min-width : 960px)
  // *
  //   color: green

  .nowordbreak h1, h2
    font-size: 1.5rem

@media only screen and (min-width : 1264px)
  // *
  //   color: red

  .nowordbreak h1, h2
    font-size: 1.5rem

@media only screen and (min-width : 1904px)
  // *
  //   color: yellow

  .nowordbreak h1, h2
    font-size: 1.5rem


.nowordbreak
  // word-break: break-all
  word-break: keep-all
  overflow-wrap: break-word


h1
  font-size: 3em
  font-weight: 600
  line-height: 1.1
  text-transform: uppercase

h2
  font-size: 2em
  font-weight: 300
  line-height: 1.1

h3
  font-size: 1.5em
  font-weight: 400
  line-height: 1.1

p
  font-size: 1em
  font-weight: 400

.border
  border-style: solid
  border-width: 1px

.background-linear-gradient
  background: linear-gradient(-120deg, #131313, #000 100%)

.background-radial-gradient
  background: radial-gradient(at 25% 55%, $grey, black)

.indication-type-background
  background: radial-gradient(at 25% 55%, $grey, black)

.toolbar
  background: linear-gradient(to right, $dark-grey, $grey)

.card
  background: radial-gradient(at 0 0, $grey, $dark-grey) !important
  height: 100%

.tabs-items
  background: rgba(0,0,0,0) !important

.active-tab
  background: rgba(0,0,0,0) !important
  color: white !important

.active-tab-new
  background: #2196f3 !important
  color: white !important

.inactive-tab-new
  background: rgba(0,0,0,0)
  color: white !important

.upload-box, .inactive-tab, .confirm-button, .next-button, .selected-indication-type
  background: linear-gradient(to right, $blue, $dark-blue)
  color: white !important
  opacity: 1 !important

.table
  background-color: rgba(0,0,0,0) !important

.titanium
  background-color: #CCCCCC !important
  color: $dark-grey !important

.zirconia-abutment
  background-color: #FFF7E6 !important
  color: $dark-grey !important

.cobalt-chrome
  background-color: #F2F2F2 !important
  color: $dark-grey !important

.cobaltChrome
  background-color: #F2F2F2 !important
  color: $dark-grey !important

.peek
  background-color: #FFF7E6 !important
  color: $dark-grey !important

.a1
  background-color: #FFF7E6 !important
  color: $dark-grey !important

.a2
  background-color: #FFEFCC !important
  color: $dark-grey !important

.a3
  background-color: #FFE7B3 !important
  color: $dark-grey !important

.a35
  background-color: #FFE3A6 !important
  color: $dark-grey !important

.b1
  background-color: #E6DECF !important
  color: $dark-grey !important

.c2
  background-color: #CCBFA3 !important
  color: $dark-grey !important

.d3
  background-color: #B3A27D !important
  color: $dark-grey !important

.bl1
  background-color: #FFFFFF !important
  color: $dark-grey !important

.bl2
  background-color: #FFFBF2 !important
  color: $dark-grey !important

.bl3
  background-color: #FFF7E6 !important
  color: $dark-grey !important

.light
  background-color: #FFDCDB !important
  color: $dark-grey !important

.standard
  background-color: #FFA6A3 !important
  color: $dark-grey !important

.dark
  background-color: #FF8883 !important
  color: $dark-grey !important

.pink
  background-image: linear-gradient(to right, #E16AFF , #9C15FF)

.gold
  background-image: linear-gradient(to right, #FBD604 , #DD9300)
  color: $dark-grey !important

.none
  background-color: #CCCCCC !important
  color: $dark-grey !important

.v-btn-toggle > .v-btn.v-btn
  opacity: 1 !important

.tooth-shade-toggle > .v-btn.v-btn
  width: 48px

.active-button
  box-shadow: 0 0 0 4px $blue !important

.dropshadow
  filter: drop-shadow(20px 20px 20px rgba(0,0,0,0.8))

.dropshadow-text
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.8))

.bold
  font-weight: 600

.v-card__text, .v-card__title
  word-break: normal
</style>

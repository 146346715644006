<template>
  <div class="flex fill-height pa-5">
    <h1 v-if="!launch">
      {{ $t("products.chairsideSupport") | capitalizeAll }}
      <!-- Chairside Support -->
    </h1>
    <h1 v-if="launch">
      <v-btn fab @click="launch = false">
        <v-icon x-large>mdi-chevron-left</v-icon>
      </v-btn>
      {{ $t("nexusios.chairsideSupport.launchSession.launchSession") }}
      <!-- Launch Session -->
    </h1>
    <v-flex class="pt-12">
      <v-row justify="center" v-if="!launch">
        <v-col cols="12" md="4" sm="6" class="text-center white--text ma-0">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pa-4"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              style="background-color:rgba(200,200,200,0)"
              @click="book"
            >
              <v-img src="../assets/graphics/nios-booking.png" />
              <h2 class="mt-12">
                {{ $t("nexusios.chairsideSupport.bookSession.bookSession") }}
                <!-- Book Session -->
              </h2>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="text-center white--text ma-0" v-if="false">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pa-4"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              style="background-color:rgba(200,200,200,0)"
              @click="launch = true"
            >
              <v-img src="../assets/graphics/nios-jitsi-computer.png" />
              <h2 class="mt-12">
                {{
                  $t("nexusios.chairsideSupport.launchSession.launchSession")
                }}
                <!-- Launch Session -->
              </h2>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="launch">
        <v-col cols="12" md="4" sm="6" class="text-center white--text ma-0">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pa-4"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              style="background-color:rgba(200,200,200,0)"
              @click="launchSession('desktop')"
            >
              <v-img
                :src="
                  require('../assets/chairside-support/nios-chairside-desktop.png')
                "
                class="mb-4"
                height="18em"
                max-height="48em"
                contain
              >
              </v-img>
              <h2 class="mt-12">
                {{ $t("nexusios.chairsideSupport.launchSession.desktop") }}
                <!-- Desktop -->
              </h2>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="text-center white--text ma-0">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pa-4"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              style="background-color:rgba(200,200,200,0)"
              @click="launchSession('phone')"
            >
              <v-img
                :src="
                  require('../assets/chairside-support/nios-chairside-phone.png')
                "
                class="mb-4"
                height="18em"
                max-height="48em"
                contain
              >
              </v-img>
              <h2 class="mt-12">
                {{ $t("nexusios.chairsideSupport.launchSession.desktopPhone") }}
                <!-- Desktop + Phone -->
              </h2>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="text-center white--text ma-0">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pa-4"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              style="background-color:rgba(200,200,200,0)"
              @click="launchSession('glasses')"
            >
              <v-img
                :src="
                  require('../assets/chairside-support/nios-chairside-glasses.png')
                "
                class="mb-4"
                height="18em"
                max-height="48em"
                contain
              >
              </v-img>
              <h2 class="mt-12">
                {{ $t("nexusios.chairsideSupport.launchSession.desktopAr") }}
                <!-- Desktop + AR Glasses -->
              </h2>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-flex>
  </div>
</template>

<script>
export default {
  name: "Support",

  data() {
    return {
      launch: false
    };
  },

  methods: {
    book() {
      this.$router.push({ name: "book-support" });
    },

    launchSession(deviceConfiguration) {
      this.$router.push({
        name: "support-session",
        params: { configuration: deviceConfiguration }
      });
    }
  }
};
</script>

<template>
  <div class="fill-height flex">
    <v-container
      style="height: 100%; max-width: 100%; font-size: 4em; font-weight: 300;"
    >
      <div v-if="pdf" id="pdf-app">
        <div id="pdf-wrapper">
          <pdf-app :pdf="pdf" :config="config"></pdf-app>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PdfApp from "vue-pdf-app";

export default {
  name: "InstructionsViewer",

  components: {
    PdfApp
  },

  data() {
    return {
      loading: true,
      pdf: this.pdfFilename(),
      config: {
        toolbar: {
          toolbarViewerLeft: {
            next: true
          },
          toolbarViewerRight: {
            print: false,
            download: false,
            openFile: false,
            secondaryToolbarToggle: false
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters(["approvedClient"])
  },

  watch: {
    approvedClient(value) {
      if (!value) {
        return;
      }
      this.pdf = this.pdfFilename();
    }
  },

  methods: {
    ...mapActions(["fetchUser"]),
    pdfFilename() {
      if (!this.approvedClient) {
        return;
      }
      this.filename = "";
      this.path = process.env.VUE_APP_DOCBUCKET_TARGET;
      if (this.path == "" || !!this.path == false) {
        this.path = "https://nexusios-shared-files.storage.googleapis.com";
      }
      this.path = this.path + `/public/${this.approvedClient.language}/`;
      if (this.$route.params.type == "medit") {
        this.filename = "Nexusios-Osteon-Scan-Medit.pdf";
      }
      if (this.$route.params.type == "trios") {
        this.filename = "Nexusios-Osteon-Scan-Trios.pdf";
      }
      if (this.$route.params.type == "itero") {
        this.filename = "Nexusios-Osteon-Scan-Itero.pdf";
      }
      if (this.$route.params.type == "cerec") {
        this.filename = "Nexusios-Osteon-Scan-Cerecprimescan.pdf";
      }
      return this.path + this.filename;
    }
  },

  async mounted() {
    this.fetchUser();
    this.loading = false;
  }
};
</script>

<style>
#pdf-app {
  height: 100%;
  display: flex;
}

#pdf-wrapper {
  flex: 10 0 auto;
}

#pdf-button {
  text-align: center;
  flex: 2 0 auto;
  align-self: center;
}
</style>

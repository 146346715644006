<template>
  <div>
    <v-row justify="center">
      <template v-for="(item, i) in productCategories">
        <v-col
          :key="i"
          cols="12"
          md="3"
          sm="6"
          class="text-center white--text ma-0"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pa-4"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              style="background-color:rgba(200,200,200,0)"
              @click="$emit('input', item.value)"
            >
              <v-img
                :src="require('../../../assets/indication-renders/' + item.img)"
                class="mb-4"
                height="18em"
                max-height="48em"
                contain
              >
              </v-img>
              <h2>{{ item.name }}</h2>
              <br />
              <p>
                {{ $t(`nexusios.patientOrder.descriptions.${item.value}`) }}
              </p>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
// yes, australia and (not australia) are the same.
// remains as is in case of future differentiation
export default {
  name: "IntendedProductCategorySelector",

  props: ["value", "country", "intended", "category"],

  data() {
    return {
      productCategory: "",
      indicationType: "",
      step: 0
    };
  },

  computed: {
    productCategories() {
      if (this.country === "Australia") {
        if (this.intended == "full-arch") {
          return this.fullArchProducts();
        } else {
          return this.immediateFullArchProducts();
        }
      } else {
        if (this.intended == "full-arch") {
          return this.fullArchProducts();
        } else {
          return this.immediateFullArchProducts();
        }
      }
    }
  },

  methods: {
    fullArchProducts() {
      return [
        {
          name: this.$t("productCategories.fixed"),
          value: "fixed",
          img: "nio-indication-hybrid.png"
        },
        {
          name: this.$t("productCategories.removable"),
          value: "removable",
          img: "nio-indication-removable.png"
        }
      ];
    },

    immediateFullArchProducts() {
      if (this.category == "pre-op") {
        return [
          {
            name: this.$t("productCategories.preOp"),
            value: "pre-op",
            img: "nio-indication-smiledesign.png"
          }
        ];
      }

      if (this.category == "immediate") {
        return [
          {
            name: this.$t("productCategories.immediate"),
            value: "immediate",
            img: "nio-indication-ti-base.png"
          }
        ];
      }
      // definitive
      return [
        {
          name: this.$t("productCategories.fixed"),
          value: "fixed",
          img: "nio-indication-hybrid.png"
        },
        {
          name: this.$t("productCategories.removable"),
          value: "removable",
          img: "nio-indication-removable.png"
        }
      ];
    }
  }
};
</script>

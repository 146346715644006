<template>
  <v-container flex>
    <v-container flex v-if="indicationType !== 'design-only-immediate'">
      <v-img :src="analogeSrc" contain eager max-height="500px">
        <v-img :src="attachmentSrc" contain eager max-height="500px">
          <v-img :src="barSrc" contain eager max-height="500px">
            <v-img :src="toothShadeSrc" contain eager max-height="500px">
              <v-img :src="pinkGingivalSrc" contain eager max-height="500px">
              </v-img>
            </v-img>
          </v-img>
        </v-img>
      </v-img>
    </v-container>
    <v-container flex v-if="indicationType === 'design-only-immediate'">
      <v-img :src="designOnlyImmediate" contain eager max-height="500px" />
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "OrderPreviewImage",
  props: ["value", "set", "indicationType"],

  computed: {
    designOnlyImmediate() {
      return require("../../assets/indication-renders/design-only-immediate.png");
    },
    analogeSrc() {
      if (this.isSingleOrBridge) {
        return "";
      } else if (this.indicationType === "nexus-hra" && this.set === "lower") {
        return require("../../assets/design-hra/design-hra-lower.png");
      } else if (this.indicationType === "nexus-hra" && this.set === "upper") {
        return require("../../assets/design-hra/design-hra-upper.png");
      } else {
        return require("../../assets/nio-implant-normal.png");
      }
    },

    isSingleOrBridge() {
      return (
        this.indicationType === "nexus-single-implant-crown" ||
        this.indicationType === "nexus-micro-unit-bridge" ||
        this.indicationType === "custom-abutment-bridge"
      );
    },

    folderName() {
      if (this.set == "upper") {
        return "design-overdenture";
      } else {
        return "lower-overdenture";
      }
    },

    attachmentSrc() {
      let folderName;
      if (this.indicationType === "overdenture-on-attachments") {
        folderName = "design-overdenture";
      } else if (this.set == "upper") {
        folderName = "design-overdenture";
      } else {
        folderName = "lower-overdenture";
      }
      switch (this.value.attachments) {
        case "locator":
          return require(`../../assets/${folderName}/nio-implant-locator.png`);
        case "equator":
          return require(`../../assets/${folderName}/nio-implant-equator.png`);
        case "unicone":
          return require(`../../assets/${folderName}/nio-implant-unicone.png`);
        default:
          return "";
      }
    },

    barSrc() {
      switch (this.indicationType) {
        case "nexus-hybrid":
          switch (this.value.barTinting) {
            case "gold":
              return require("../../assets/design-hybrid/nio-hybrid-bar-gti.png");
            case "pink":
              return require("../../assets/design-hybrid/nio-hybrid-bar-pti.png");
            default:
              switch (this.value.barMaterial) {
                case "cobalt-chrome":
                  return require("../../assets/design-hybrid/nio-hybrid-bar-cobaltchrome.png");
                case "titanium":
                  return require("../../assets/design-hybrid/nio-hybrid-bar-titanium.png");
                default:
                  return "";
              }
          }
        case "nexus-bridge":
          switch (this.value.barTinting) {
            case "gold":
              return require("../../assets/design-bridge/nio-bridge-nexus-gti.png");
            case "pink":
              return require("../../assets/design-bridge/nio-bridge-nexus-pti.png");
            default:
              switch (this.value.barMaterial) {
                case "cobalt-chrome":
                  return require("../../assets/design-bridge/nio-bridge-nexus-cobaltchrome.png");
                case "titanium":
                  return require("../../assets/design-bridge/nio-bridge-nexus-titanium.png");
                default:
                  return "";
              }
          }
        case "bridge-on-ti-base":
        case "bridge-on-ti-base-immediate":
          return require("../../assets/design-immediate/nio-immediate-tibase.png");
        case "nexus-wraparound":
        case "nexus-wraparound-immediate":
          switch (this.value.barTinting) {
            case "gold":
              return require("../../assets/design-immediate/nio-immediate-wraparound-gti.png");
            case "pink":
              return require("../../assets/design-immediate/nio-immediate-wraparound-pti.png");
            default:
              switch (this.value.barMaterial) {
                case "titanium":
                  return require("../../assets/design-immediate/nio-immediate-wraparound-titanium.png");
                default:
                  return "";
              }
          }
        case "nexus-removable":
          switch (this.value.titaniumSleeveSuperstructure) {
            case "yes":
              return require("../../assets/design-removable/nio-removable-sleeve-low.png");
            default:
              switch (this.value.barMaterial) {
                case "titanium":
                  return require("../../assets/design-removable/nio-removable-bar-titanium.png");
                default:
                  return "";
              }
          }
        case "nexus-hra":
          switch (this.value.barTinting) {
            case "gold":
              return require("../../assets/design-hra/design-hra-bar-gti.png");
            case "pink":
              return require("../../assets/design-hra/design-hra-bar-pti.png");
            default:
              switch (this.value.barMaterial) {
                case "titanium":
                  return require("../../assets/design-hra/design-hra-bar.png");
                default:
                  return "";
              }
          }
        case "nexus-reinforced":
          switch (this.value.barMaterial) {
            case "peek":
              return require("../../assets/design-immediate/nio-immediate-reinforced.png");
            default:
              return "";
          }
        case "overdenture-on-attachments":
          switch (this.value.baseMaterial) {
            case "acrylic":
              return require("../../assets/design-overdenture/nio-overdenture-base.png");
            default:
              return "";
          }
        case "nexus-micro-unit-bridge":
          switch (this.value.barTinting) {
            case "gold":
              return require("../../assets/design-micro/micro-bar-gti.png");
            case "pink":
              return require("../../assets/design-micro/micro-bar-pti.png");
            default:
              return require("../../assets/design-micro/micro-bar-ti.png");
          }
        case "custom-abutment-bridge":
          switch (this.value.barTinting) {
            case "gold":
              return require("../../assets/design-custom-abutment-bridge/cab-abutment-gti.png");
            case "pink":
              return require("../../assets/design-custom-abutment-bridge/cab-abutment-pti.png");
            default:
              return require("../../assets/design-custom-abutment-bridge/cab-abutment-ti.png");
          }
        case "nexus-single-implant-crown":
          switch (this.value.barMaterial) {
            case "titanium":
              switch (this.value.barTinting) {
                case "gold":
                  return require("../../assets/design-su/su-central-abutment-gti.png");
                case "pink":
                  return require("../../assets/design-su/su-central-abutment-pti.png");
                default:
              }
              return require("../../assets/design-su/su-central-abutment-ti.png");
            case "zirconia-abutment":
              switch (this.value.barTinting) {
                case "gold":
                  return require("../../assets/design-su/su-central-abutment-zi-gti.png");
                case "pink":
                  return require("../../assets/design-su/su-central-abutment-zi-pti.png");
                default:
              }
              return require("../../assets/design-su/su-central-abutment-zi-ti.png");
            default:
              return "";
          }

        default:
          return "";
      }
    },

    toothShadeSrc() {
      var toothShade = this.value.toothShade;
      if (
        this.value.overlayMaterial !== undefined &&
        toothShade === undefined
      ) {
        toothShade = "a1";
      }

      switch (this.indicationType) {
        case "overdenture-on-attachments":
          if (this.set == "upper") {
            switch (toothShade) {
              case "a1":
                return require("../../assets/design-overdenture/upper-a1.png");
              case "a2":
                return require("../../assets/design-overdenture/upper-a2.png");
              case "a3":
                return require("../../assets/design-overdenture/upper-a3.png");
              case "a35":
                return require("../../assets/design-overdenture/upper-a35.png");
              case "b1":
                return require("../../assets/design-overdenture/upper-b1.png");
              case "c2":
                return require("../../assets/design-overdenture/upper-c2.png");
              case "d3":
                return require("../../assets/design-overdenture/upper-d3.png");
              case "bl1":
                return require("../../assets/design-overdenture/upper-bl1.png");
              case "bl2":
                return require("../../assets/design-overdenture/upper-bl2.png");
              case "bl3":
                return require("../../assets/design-overdenture/upper-bl3.png");
              case "other":
                return require("../../assets/design-overdenture/upper-a1.png");
              default:
                return "";
            }
          } else {
            switch (toothShade) {
              case "a1":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-a1.png");
              case "a2":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-a2.png");
              case "a3":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-a3.png");
              case "a35":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-a35.png");
              case "b1":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-b1.png");
              case "c2":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-c2.png");
              case "d3":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-d3.png");
              case "bl1":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-bl1.png");
              case "bl2":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-bl2.png");
              case "bl3":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-bl3.png");
              case "other":
                return require("../../assets/lower-overdenture/nio-lower-overdenture-tooth-a1.png");
              default:
                return "";
            }
          }
        case "nexus-micro-unit-bridge":
          // return  toothShadeFile("design-micro/micro-tooth-{{toothShade}}.png", toothShade);
          switch (toothShade) {
            case "a1":
              return require("../../assets/design-micro/micro-tooth-a1.png");
            case "a2":
              return require("../../assets/design-micro/micro-tooth-a2.png");
            case "a3":
              return require("../../assets/design-micro/micro-tooth-a3.png");
            case "a35":
              return require("../../assets/design-micro/micro-tooth-a35.png");
            case "b1":
              return require("../../assets/design-micro/micro-tooth-b1.png");
            case "c2":
              return require("../../assets/design-micro/micro-tooth-c2.png");
            case "d3":
              return require("../../assets/design-micro/micro-tooth-d3.png");
            case "bl1":
              return require("../../assets/design-micro/micro-tooth-bl1.png");
            case "bl2":
              return require("../../assets/design-micro/micro-tooth-bl2.png");
            case "bl3":
              return require("../../assets/design-micro/micro-tooth-bl3.png");
            case "other":
              return require("../../assets/design-micro/micro-tooth-a1.png");
            default:
              return "";
          }
        case "custom-abutment-bridge":
          switch (toothShade) {
            case "a1":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-a1.png");
            case "a2":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-a2.png");
            case "a3":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-a3.png");
            case "a35":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-a35.png");
            case "b1":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-b1.png");
            case "c2":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-c2.png");
            case "d3":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-d3.png");
            case "bl1":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-bl1.png");
            case "bl2":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-bl2.png");
            case "bl3":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-bl3.png");
            case "other":
              return require("../../assets/design-custom-abutment-bridge/cab-tooth-a1.png");
            default:
              return "";
          }
        case "nexus-single-implant-crown":
          switch (toothShade) {
            case "a1":
              return require("../../assets/design-su/su-central-crown-a1.png");
            case "a2":
              return require("../../assets/design-su/su-central-crown-a2.png");
            case "a3":
              return require("../../assets/design-su/su-central-crown-a35.png");
            case "a35":
              return require("../../assets/design-su/su-central-crown-a3.png");
            case "b1":
              return require("../../assets/design-su/su-central-crown-b1.png");
            case "c2":
              return require("../../assets/design-su/su-central-crown-c2.png");
            case "d3":
              return require("../../assets/design-su/su-central-crown-d3.png");
            case "bl1":
              return require("../../assets/design-su/su-central-crown-bl1.png");
            case "bl2":
              return require("../../assets/design-su/su-central-crown-bl2.png");
            case "bl3":
              return require("../../assets/design-su/su-central-crown-bl3.png");
            case "other":
              return require("../../assets/design-su/su-central-crown-a1.png");
            default:
              return "";
          }
        default:
          if (this.set == "upper") {
            switch (toothShade) {
              case "a1":
                return require("../../assets/design-overlay/nio-upper-tooth-a1.png");
              case "a2":
                return require("../../assets/design-overlay/nio-upper-tooth-a2.png");
              case "a3":
                return require("../../assets/design-overlay/nio-upper-tooth-a3.png");
              case "a35":
                return require("../../assets/design-overlay/nio-upper-tooth-a35.png");
              case "b1":
                return require("../../assets/design-overlay/nio-upper-tooth-b1.png");
              case "c2":
                return require("../../assets/design-overlay/nio-upper-tooth-c2.png");
              case "d3":
                return require("../../assets/design-overlay/nio-upper-tooth-d3.png");
              case "bl1":
                return require("../../assets/design-overlay/nio-upper-tooth-bl1.png");
              case "bl2":
                return require("../../assets/design-overlay/nio-upper-tooth-bl2.png");
              case "bl3":
                return require("../../assets/design-overlay/nio-upper-tooth-bl3.png");
              case "other":
                return require("../../assets/design-overlay/nio-upper-tooth-a1.png");
              default:
                return "";
            }
          } else {
            switch (toothShade) {
              case "a1":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-a1.png");
              case "a2":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-a2.png");
              case "a3":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-a3.png");
              case "a35":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-a35.png");
              case "b1":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-b1.png");
              case "c2":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-c2.png");
              case "d3":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-d3.png");
              case "bl1":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-bl1.png");
              case "bl2":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-bl2.png");
              case "bl3":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-bl3.png");
              case "other":
                return require("../../assets/design-lower-overlay/nio-lower-tooth-a1.png");
              default:
                return "";
            }
          }
      }
    },

    pinkGingivalSrc: function() {
      switch (this.indicationType) {
        case "nexus-micro-unit-bridge":
          switch (this.value.pinkGingivalColour) {
            case "light":
              return require("../../assets/design-micro/nexus-micro-gingiva-light.png");
            case "standard":
              return require("../../assets/design-micro/nexus-micro-gingiva-medium.png");
            case "dark":
              return require("../../assets/design-micro/nexus-micro-gingiva-dark.png");
            default:
              return "";
          }
        case "custom-abutment-bridge":
          switch (this.value.pinkGingivalColour) {
            case "light":
              return require("../../assets/design-custom-abutment-bridge/cab-gingiva-light.png");
            case "standard":
              return require("../../assets/design-custom-abutment-bridge/cab-gingiva-medium.png");
            case "dark":
              return require("../../assets/design-custom-abutment-bridge/cab-gingiva-dark.png");
            default:
              return "";
          }
        case "nexus-single-implant-crown":
          return "";
        default:
          if (this.set == "upper") {
            switch (this.indicationType) {
              case "overdenture-on-attachments":
                switch (this.value.pinkGingivalColour) {
                  case "light":
                    return require("../../assets/design-overdenture/nio-overdenture-gum-light.png");
                  case "dark":
                    return require("../../assets/design-overdenture/nio-overdenture-gum-dark.png");
                  default:
                    return "";
                }
              default:
                switch (this.value.pinkGingivalColour) {
                  case "light":
                    return require("../../assets/design-overlay/nio-upper-gum-light.png");
                  case "standard":
                    return require("../../assets/design-overlay/nio-upper-gum-medium.png");
                  case "dark":
                    return require("../../assets/design-overlay/nio-upper-gum-dark.png");
                  default:
                    return "";
                }
            }
          } else {
            switch (this.indicationType) {
              case "overdenture-on-attachments":
                switch (this.value.pinkGingivalColour) {
                  case "light":
                    return require("../../assets/lower-overdenture/nio-lower-overdenture-gum-light.png");
                  case "dark":
                    return require("../../assets/lower-overdenture/nio-lower-overdenture-gum-dark.png");
                  default:
                    return "";
                }
              default:
                switch (this.value.pinkGingivalColour) {
                  case "light":
                    return require("../../assets/design-lower-overlay/nio-lower-gum-light.png");
                  case "standard":
                    return require("../../assets/design-lower-overlay/nio-lower-gum-medium.png");
                  case "dark":
                    return require("../../assets/design-lower-overlay/nio-lower-gum-dark.png");
                  default:
                    return "";
                }
            }
          }
      }
    }
  }
};
</script>

<template>
  <div>
    <v-alert type="success" v-if="success">
      {{ $t("nexusios.account.practices.practiceDetailsUpdated") }}
      <!-- Practice details updated. -->
    </v-alert>
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-card v-if="!loading">
      <v-toolbar color="secondary">
        <v-toolbar-title>
          {{ $t("common.practices") }}
          <!-- Practices -->
        </v-toolbar-title>
      </v-toolbar>
      <v-dialog v-model="dialog" fullscreen>
        <v-card>
          <v-toolbar color="secondary">
            <v-spacer />
            <v-spacer />
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-alert type="error" v-if="error">
              {{ $t("errors.unexpectedErrorContactUs") }}
              <!-- An unexpected error has occurred, please contact us for -->
              <!-- assistance. -->
            </v-alert>
            <v-form ref="practiceForm">
              <v-container>
                <v-row>
                  <v-col class="col-12 col-md-6">
                    <v-card :elevation="0">
                      <v-card-title>
                        {{ $t("nexusios.account.practices.practiceDetails") }}
                        <!-- Practice Details -->
                      </v-card-title>
                      <v-container>
                        <v-text-field
                          v-model="editItem.business_name"
                          :label="$t('details.practiceName')"
                          solo
                          light
                          :rules="[rules.required]"
                        ></v-text-field>
                        <v-text-field
                          v-model="editItem.contact_person"
                          :label="$t('details.contactPerson')"
                          solo
                          light
                        ></v-text-field>
                        <v-text-field
                          v-model="editItem.email"
                          :label="$t('details.emailAddress')"
                          :rules="[rules.email]"
                          solo
                          light
                        ></v-text-field>
                        <v-text-field
                          v-model="editItem.phone"
                          :label="$t('details.phoneNumber')"
                          solo
                          light
                        ></v-text-field>
                      </v-container>
                    </v-card>
                    <v-card :elevation="0">
                      <v-card-title>
                        {{ $t("details.billingAddress") }}
                        <!-- Billing Address -->
                      </v-card-title>
                      <v-container>
                        <v-text-field
                          v-model="editItem.billing_address_1"
                          :label="$t('details.address1')"
                          solo
                          light
                        ></v-text-field>
                        <v-text-field
                          v-model="editItem.billing_address_2"
                          :label="$t('details.address2')"
                          solo
                          light
                        ></v-text-field>
                        <v-row>
                          <v-col class="col-12 col-md-4">
                            <v-text-field
                              v-model="editItem.billing_address_suburb"
                              :label="$t('details.suburb')"
                              solo
                              light
                            ></v-text-field>
                          </v-col>
                          <v-col class="col-12 col-md-4">
                            <v-text-field
                              v-model="editItem.billing_address_state"
                              :label="$t('details.state')"
                              solo
                              light
                            ></v-text-field>
                          </v-col>
                          <v-col class="col-12 col-md-4">
                            <v-text-field
                              v-model="editItem.billing_address_postcode"
                              :label="$t('details.postcode')"
                              solo
                              light
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-select
                          v-model="editItem.billing_address_country"
                          :label="$t('details.country')"
                          solo
                          light
                          :items="countries"
                        ></v-select>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-col class="col-12 col-md-6">
                    <v-card :elevation="0">
                      <v-card-title>
                        {{ $t("details.shippingAddress") }}
                        <!-- Shipping Address -->
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="useBilling">
                          {{ $t("nexusios.account.practices.useBilling") }}
                          <!-- Use Billing -->
                        </v-btn>
                      </v-card-title>
                      <v-container>
                        <v-text-field
                          v-model="editItem.shipping_address_1"
                          :label="$t('details.address1')"
                          solo
                          light
                        ></v-text-field>
                        <v-text-field
                          v-model="editItem.shipping_address_2"
                          :label="$t('details.address2')"
                          solo
                          light
                        ></v-text-field>
                        <v-row>
                          <v-col class="col-12 col-md-4">
                            <v-text-field
                              v-model="editItem.shipping_address_suburb"
                              :label="$t('details.suburb')"
                              solo
                              light
                            ></v-text-field>
                          </v-col>
                          <v-col class="col-12 col-md-4">
                            <v-text-field
                              v-model="editItem.shipping_address_state"
                              :label="$t('details.state')"
                              solo
                              light
                            ></v-text-field>
                          </v-col>
                          <v-col class="col-12 col-md-4">
                            <v-text-field
                              v-model="editItem.shipping_address_postcode"
                              :label="$t('details.postcode')"
                              solo
                              light
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-select
                          v-model="editItem.shipping_address_country"
                          :label="$t('details.country')"
                          solo
                          light
                          :items="countries"
                        ></v-select>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click="close">{{ $t("common.cancel") }}</v-btn>
            <v-btn large color="success" @click="submit">{{
              $t("common.save")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        :headers="headers"
        :items="practices"
        :items-per-page="5"
        class="my-5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editPractice(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deletePractice(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="newPractice">
          {{ $t("nexusios.account.practices.addAnotherPractice") }}
          <!-- Add Another Practice -->
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  validationRequired,
  validationEmail
} from "../../shared/ValidationRules";
import { mapGetters, mapActions } from "vuex";
import { getNames } from "country-list";

export default {
  name: "Practices",

  props: ["id"],

  data() {
    return {
      loading: true,
      error: false,
      success: false,
      dialog: false,
      editItem: {},
      newItem: {
        business_name: "",
        contact_person: "",
        email: "",
        phone: "",
        shipping_address_1: "",
        shipping_address_2: "",
        shipping_address_suburb: "",
        shipping_address_state: "",
        shipping_address_postcode: "",
        shipping_address_country: "",
        billing_address_1: "",
        billing_address_2: "",
        billing_address_suburb: "",
        billing_address_state: "",
        billing_address_postcode: "",
        billing_address_country: ""
      },

      rules: {
        required: validationRequired,
        email: validationEmail
      }

      // headers: [
      // { text: "Name", value: "business_name" },
      // { text: "Contact", value: "contact_person" },
      // { text: "Email", value: "email" },
      // { text: "Phone", value: "phone" },
      // { text: "Actions", value: "actions", sortable: false }
      // ]
    };
  },

  computed: {
    headers() {
      return [
        { text: this.$t("details.name"), value: "business_name" },
        { text: this.$t("common.contact"), value: "contact_person" },
        { text: this.$t("details.email"), value: "email" },
        { text: this.$t("details.phone"), value: "phone" },
        { text: this.$t("common.actions"), value: "actions", sortable: false }
      ];
    },
    ...mapGetters(["practices"]),

    countries() {
      return getNames().sort();
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["fetchUser"]),

    newPractice() {
      this.editItem = Object.assign({}, this.newItem);
      this.dialog = true;
    },

    editPractice(item) {
      this.editItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deletePractice(item) {
      if (confirm(`Are you sure you want to delete ${item.business_name}?`)) {
        this.loading = true;
        this.$httpClient
          .deletePractice(item)
          .then(async () => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            alert("Could not delete this practice.");
          });
      }
    },

    useBilling() {
      this.editItem.shipping_address_1 = this.editItem.billing_address_1;
      this.editItem.shipping_address_2 = this.editItem.billing_address_2;
      this.editItem.shipping_address_suburb = this.editItem.billing_address_suburb;
      this.editItem.shipping_address_state = this.editItem.billing_address_state;
      this.editItem.shipping_address_postcode = this.editItem.billing_address_postcode;
      this.editItem.shipping_address_country = this.editItem.billing_address_country;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({});
      });
    },

    async submit() {
      if (this.$refs.practiceForm.validate()) {
        this.loading = true;
        let saveAction;
        if (this.editItem.id && this.editItem.id.length > 0) {
          saveAction = this.$httpClient.updatePractice;
        } else {
          saveAction = this.$httpClient.createPractice;
        }
        saveAction(this.editItem)
          .then(async () => {
            this.loading = false;
            this.success = true;
            this.close();
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
          });
      }
    }
  },

  mounted() {
    this.loading = false;
    if (this.id) {
      if (this.id === "new") {
        this.dialog = true;
      } else {
        this.editItem = this.practices.filter(practice => {
          return practice.id === this.id;
        })[0];
        this.dialog = true;
      }
    }
  }
};
</script>

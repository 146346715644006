<template>
  <div class="flex">
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-container fluid class="ma-0 pa-0" justify="center" v-if="!loading">
      <v-col cols="12" order-md="2" order="1">
        <v-container fill-height align-center text-center>
          <v-col md="12">
            <h1>
              <v-btn :to="{ name: 'instructions' }" class="mx-1 my-3" fab>
                <v-icon x-large>mdi-chevron-left</v-icon>
              </v-btn>
              {{ $t("nexusios.instructions.itero.comingSoon") }}
              <!-- iTero Coming Soon -->
            </h1>
          </v-col>
        </v-container>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "InstructionsItero",

  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapGetters(["approvedClient"])
  },

  methods: {
    ...mapActions(["fetchUser", "logout"])
  },

  async mounted() {
    this.loading = false;
  }
};
</script>

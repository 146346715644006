import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import DatetimePicker from "vuetify-datetime-picker";
import HTTPClient from "./shared/HTTPClient";
import store from "./store.js";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import VueLodashFilters from "vue-lodash-filters";
import Sticky from "vue-sticky-directive";
import Vuetify from "vuetify/lib";
import VueI18n from "vue-i18n";
import vuetifyEn from "vuetify/es5/locale/en";
import "@mdi/font/css/materialdesignicons.css";
import VueLuxon from "vue-luxon";

Vue.config.productionTip = false;
Vue.use(DatetimePicker);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueLodashFilters);
Vue.use(Sticky);
Vue.use(VueI18n);
Vue.use(Vuetify);

Vue.filter("capitalizeAll", (value) =>
  value
    ? value
        .toString()
        .toLowerCase()
        .split(/-| /)
        .map(
          (word) =>
            word
              .toString()
              .charAt(0)
              .toUpperCase() + word.slice(1)
        )
        .join(" ")
    : ""
);

Vue.filter("uppercase", (value) =>
  value ? value.toString().toUpperCase() : ""
);

Vue.prototype.$httpClient = new HTTPClient();

Vue.use(VueLuxon);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
});

Vue.prototype.$httpClient.fetchLanguage("en").then((messages) => {
  i18n.setLocaleMessage("en", { $vuetify: vuetifyEn, ...messages });
});

Vue.prototype.$t = function(key, locale, values) {
  const result = this.$i18n.t(key, locale, values);
  if (result === "") {
    const default_value = this.$i18n.t(key, "en", values);
    return default_value ? default_value : key;
  }
  return result;
};

const vuetify = new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#147BD1",
        secondary: "#333F48",
        accent: "#C7C9C7",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});

new Vue({
  i18n,
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <v-btn-toggle
    v-model="superstructure"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="superstructureSelected"
  >
    <v-btn class="ma-1" value="yes" :disabled="disabled">
      {{ $t("common.yes") }}
    </v-btn>
    <v-btn class="ma-1" value="no" :disabled="disabled">
      {{ $t("common.no") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "TitaniumSleeveSuperstructure",

  props: ["value", "disabled"],

  data() {
    return {
      superstructure: this.value
    };
  },

  methods: {
    superstructureSelected() {
      this.$emit("input", this.superstructure);
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('ToothPicker',{attrs:{"convention":_vm.internationalToothConvention,"selected":_vm.teeth,"customColors":_vm.customColors},on:{"toothClicked":_vm.selectTooth}}),_c('br'),_c('v-btn-toggle',{attrs:{"dense":"","tile":""},model:{value:(_vm.internationalToothConvention),callback:function ($$v) {_vm.internationalToothConvention=$$v},expression:"internationalToothConvention"}},[_c('v-btn',{attrs:{"tile":"","small":"","value":"fdi"}},[_vm._v("FDI")]),_c('v-btn',{attrs:{"tile":"","small":"","value":"palmer"}},[_vm._v("Pal.")]),_c('v-btn',{attrs:{"tile":"","small":"","value":"universal"}},[_vm._v("Uni.")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.implantsHeaders,"items":_vm.value,"hide-default-header":"","hide-default-footer":"","no-data-text":_vm.$t('nexusios.orderNow.noImplants')},scopedSlots:_vm._u([{key:"item.tooth",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getToothNumber(item.tooth))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"red--text",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-minus-circle ")])]}}])})],1)],1),(_vm.indicationType === 'nexus-wraparound-immediate')?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"pb-0",attrs:{"dense":"","border":"left","colored-border":"","type":"warning","elevation":"2","icon":"mdi-information"}},[_c('p',{staticStyle:{"font-size":"11px"}},[_vm._v(" This product is not available in the US and Europe. ")])])],1)],1):_vm._e(),(_vm.indicationType === 'pre-op')?_c('v-row',[_c('v-col',[_c('v-alert',{staticClass:"pb-0",attrs:{"dense":"","border":"left","colored-border":"","type":"warning","elevation":"2","icon":"mdi-information"}},[_c('p',{staticStyle:{"font-size":"11px"}},[_vm._v(" Nexus Provisionals can only be designed and manufactured on a range of Multi-Unit Abutments."),_c('br'),_vm._v(" For further compatibility information "),_c('a',{attrs:{"href":"https://www.nexusios.com/provisional-compatibility","target":"_blank"}},[_vm._v("click here")]),_vm._v(". ")])])],1)],1):_vm._e(),(_vm.indicationType !== 'pre-op')?_c('v-row',[_c('v-col',[(
          !_vm.$t('nexusios.orderNow.implantSelectionCaveat') ||
            _vm.$t('nexusios.orderNow.implantSelectionCaveat').length > 1
        )?_c('v-alert',{staticClass:"pb-0",attrs:{"dense":"","border":"left","colored-border":"","type":"warning","elevation":"2","icon":"mdi-information"}},[_c('p',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.$t("nexusios.orderNow.implantSelectionCaveat"))+" ")])]):_vm._e()],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.implantDialog),callback:function ($$v) {_vm.implantDialog=$$v},expression:"implantDialog"}},[_c('v-card',{staticClass:"dialog-card"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("nexusios.orderNow.implantSelection"))+" ")]),_c('v-card-text',[(_vm.selectedTooth)?_c('v-btn',{staticClass:"my-4",attrs:{"x-large":""}},[_vm._v(" "+_vm._s(_vm.getToothNumber(_vm.selectedTooth))+" ")]):_vm._e(),_c('v-autocomplete',{attrs:{"items":_vm.implantPlatforms,"label":_vm.$t('nexusios.orderNow.implantPlatform'),"solo":"","light":""},model:{value:(_vm.implantPlatform),callback:function ($$v) {_vm.implantPlatform=$$v},expression:"implantPlatform"}}),(_vm.isSingleOrBridge)?_c('v-select',{attrs:{"items":_vm.scanBodyTypes,"label":_vm.$t('nexusios.orderNow.scanBodyType'),"solo":"","light":""},model:{value:(_vm.scanBodyType),callback:function ($$v) {_vm.scanBodyType=$$v},expression:"scanBodyType"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"close-button",attrs:{"tile":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("common.close"))+" ")]),_c('v-btn',{staticClass:"confirm-button",attrs:{"tile":"","disabled":!_vm.implantPlatform},on:{"click":_vm.confirmImplant}},[_vm._v(" "+_vm._s(_vm.$t("common.confirm"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog
    v-model="changeIndicationDialog"
    max-width="350"
    @click:outside="optionSelected('no')"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t("nexusios.orderNow.changeIndicationType?") }}
      </v-card-title>

      <v-card-text>
        {{ $t("nexusios.orderNow.anyOptionsWillBeLost") }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="optionSelected('no')">
          {{ $t("common.no") }}
          <!-- No -->
        </v-btn>

        <v-btn color="green darken-1" text @click="optionSelected('yes')">
          {{ $t("common.yes") }}
          <!-- Yes -->
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ChangeIndicationPrompt",

  props: ["changeIndicationDialog"],

  methods: {
    optionSelected(value) {
      this.$emit("choice", value);
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.item.preop_status != 'Deleted' ||
      (_vm.item.definitive_status != 'Deleted' && _vm.item.preop_status)
  )?_c('v-card',{staticClass:"d-flex flex-column",attrs:{"color":"black","elevation":"0"}},[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[(_vm.item.intended == 'immediate-restoration-order')?_c('v-timeline-item',{attrs:{"color":"black"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',[(
              _vm.item.preop_status == 'Ordered' ||
                _vm.item.preop_status == 'Pending' ||
                _vm.item.preop_status == 'Complete' ||
                _vm.item.preop_status == 'Dispatched'
            )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.item.preop_status == 'Skipped')?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-minus-thick")]):_vm._e(),(_vm.item.preop_status == 'Incomplete')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-help")]):_vm._e(),(
              _vm.item.preop_status == 'Needs Approval' ||
                _vm.item.preop_status == 'Missing Information'
            )?_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-exclamation-thick")]):_vm._e()],1)]},proxy:true}],null,false,1124306863)},[_c('v-list-item',{staticClass:"mx-0 px-0"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"overline font-weight-bold",class:_vm.setTextColor(_vm.item.preop_status)},[_vm._v(" "+_vm._s(_vm.item.preop_status == "Skipped" ? _vm.$t("nexusios.patientOrder.status.skippedPreOp") : _vm.$t( ("nexusios.patientOrder.status." + (_vm.i18n(_vm.item.preop_status))) ))+" ")]),_c('v-list-item-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm._f("capitalizeAll")(_vm.$t("productCategories.preOp")))+" ")])],1),(_vm.preop.oep_case_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(!_vm.isEnglish)?_c('v-btn',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openApprovalFiles(_vm.item, _vm.preop)}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e()]}}],null,false,1108092341)},[_c('span',[_vm._v(_vm._s(_vm.$t("nexusios.patientOrder.approvalFiles")))])]):_vm._e(),(_vm.isEnglish && _vm.preopLink)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handover(_vm.preopLink)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e(),(_vm.isDelete && _vm.item.preop_status == 'Incomplete')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-4",attrs:{"color":"white"},on:{"click":_vm.deleteOrder}},on),[_vm._v(" mdi-delete ")])]}}],null,false,4052746232)},[_c('span',[_vm._v(_vm._s(_vm.$t("nexusios.patientOrder.delete")))])]):_vm._e()],1)],1):_vm._e(),(_vm.item.intended == 'immediate-restoration-order')?_c('v-timeline-item',{attrs:{"color":"black"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',[(
              _vm.item.immediate_status == 'Ordered' ||
                _vm.item.immediate_status == 'Pending' ||
                _vm.item.immediate_status == 'Complete' ||
                _vm.item.immediate_status == 'Dispatched'
            )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.item.immediate_status == 'Incomplete')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-help")]):_vm._e(),(
              _vm.item.immediate_status == 'Needs Approval' ||
                _vm.item.immediate_status == 'Missing Information'
            )?_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-exclamation-thick")]):_vm._e()],1)]},proxy:true}],null,false,4211853451)},[_c('v-list-item',{staticClass:"mx-0 px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"overline font-weight-bold",class:_vm.setTextColor(_vm.item.immediate_status)},[_vm._v(" "+_vm._s(_vm.$t( ("nexusios.patientOrder.status." + (_vm.i18n(_vm.item.immediate_status))) ))+" ")]),_c('v-list-item-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm._f("capitalizeAll")((this.immediate.product_category ? _vm.$t("productCategories.immediate") : "Immediate")))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("capitalizeAll")(_vm.getCorrectIndication(this.immediate.indications)))+" ")])],1),(_vm.immediate.oep_case_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(!_vm.isEnglish)?_c('v-btn',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openApprovalFiles(_vm.item, _vm.immediate)}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e()]}}],null,false,343003068)},[_c('span',[_vm._v(_vm._s(_vm.$t("nexusios.patientOrder.approvalFiles")))])]):_vm._e(),(_vm.isEnglish && _vm.immediateLink)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handover(_vm.immediateLink)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e(),(_vm.isDelete && _vm.item.immediate_status == 'Incomplete')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-4",attrs:{"color":"white"},on:{"click":_vm.deleteOrder}},on),[_vm._v(" mdi-delete ")])]}}],null,false,4052746232)},[_c('span',[_vm._v(_vm._s(_vm.$t("nexusios.patientOrder.delete")))])]):_vm._e()],1)],1):_vm._e(),_c('v-timeline-item',{attrs:{"color":"black"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-avatar',[(
              _vm.item.definitive_status == 'Ordered' ||
                _vm.item.definitive_status == 'Pending' ||
                _vm.item.definitive_status == 'Complete' ||
                _vm.item.definitive_status == 'Dispatched'
            )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.item.definitive_status == 'Incomplete')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-help")]):_vm._e(),(
              _vm.item.definitive_status == 'Needs Approval' ||
                _vm.item.definitive_status == 'Missing Information'
            )?_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-exclamation-thick")]):_vm._e()],1)]},proxy:true}],null,false,2902807229)},[_c('v-list-item',{staticClass:"mx-0 px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"overline font-weight-bold",class:_vm.setTextColor(_vm.item.definitive_status)},[_vm._v(" "+_vm._s(_vm.$t( ("nexusios.patientOrder.status." + (_vm.i18n(_vm.item.definitive_status))) ))+" ")]),_c('v-list-item-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm._f("capitalizeAll")((this.preop.product_category != "pre-op" ? _vm.getCorrectCategory(this.preop.product_category) : this.definitive.product_category && this.definitive.indications ? _vm.getCorrectCategory(this.definitive.product_category) : _vm.$t("productCategories.definitive"))))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("capitalizeAll")((this.preop.product_category != "pre-op" ? _vm.getCorrectIndication(this.preop.indications) : _vm.getCorrectIndication(this.definitive.indications))))+" ")])],1),(
            _vm.item.product_category == 'immediate'
              ? _vm.definitive.oep_case_id
              : _vm.preop.product_category == 'pre-op'
              ? _vm.definitive.oep_case_id
              : _vm.preop.oep_case_id
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(!_vm.isEnglish)?_c('v-btn',_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openApprovalFiles(
                  _vm.item,
                  _vm.item.intended == 'immediate-restoration-order'
                    ? _vm.definitive
                    : _vm.preop
                )}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e()]}}],null,false,2284754228)},[_c('span',[_vm._v(_vm._s(_vm.$t("nexusios.patientOrder.approvalFiles")))])]):_vm._e(),(_vm.isEnglish && _vm.finalLink)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handover(_vm.finalLink)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e(),(_vm.isDelete && _vm.item.definitive_status == 'Incomplete')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-4",attrs:{"color":"white"},on:{"click":_vm.deleteOrder}},on),[_vm._v(" mdi-delete ")])]}}],null,false,4052746232)},[_c('span',[_vm._v(_vm._s(_vm.$t("nexusios.patientOrder.delete")))])]):_vm._e()],1)],1)],1),_c('approval-file-tabs',{attrs:{"tabDialog":_vm.approvalFileDialog,"caseNumber":_vm.caseNumber,"caseMKey":_vm.caseMKey,"patientName":_vm.patientName,"practiceName":_vm.practiceName,"caseBundleDetail":_vm.caseBundleData},on:{"childShow":_vm.closeApprovalFileDialog}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-footer light color="white" padless>
      <v-row justify="center" no-gutters>
        <v-col class="lighten-2 py-4 text-center" cols="12">
          <v-img
            src="../assets/osteon-powered.png"
            max-width="135px"
            class="ma-auto mt-5"
          ></v-img>
        </v-col>
        <v-col class="lighten-2 py-4 text-center" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>
            {{ $t("common.nexusIos") }}
            <!-- Nexus iOS -->
          </strong>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

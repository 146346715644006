var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedOrder)?_c('v-card',[_c('OrderDetails',{attrs:{"orderId":_vm.caseNumber},on:{"back":function($event){_vm.selectedOrder = false}}})],1):_c('div',[(!_vm.loading)?_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("nexusios.account.orderHistory.orderHistory"))+" ")])],1),_c('v-data-table',{staticClass:"my-5",attrs:{"headers":_vm.headers,"items":_vm.item_orders,"items-per-page":5},scopedSlots:_vm._u([{key:"item.oep_case_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCaseMKey(item.oep_case_id))+" ("+_vm._s(item.oep_case_id || _vm.$t("common.pending"))+") ")]}},{key:"item.indication_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalizeAll")(item.payload.indication_type))+" ")]}},{key:"item.patient_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payload.patient_name)+" ")]}},{key:"item.practice_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPracticeName(item.practice_id))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDateTime(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalizeAll")(_vm.getStatus(item.oep_case_id)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',[_c('v-col',{staticClass:"text-center"},[(_vm.isEnglish && _vm.getPortalLink(item.oep_case_id))?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.openPortal(_vm.getPortalLink(item.oep_case_id))}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e(),(
                      !_vm.isEnglish &&
                        _vm.getStatus(item.oep_case_id).toUpperCase() !=
                          'CANCELLED'
                    )?_c('v-btn',_vm._g({attrs:{"small":"","color":"primary","disabled":!item.oep_case_id},on:{"click":function($event){return _vm.openApprovalFiles(item)}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" "+_vm._s(_vm.$t("common.open"))+" ")],1):_vm._e()],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("nexusios.patientOrder.approvalFiles")))])])]}}],null,false,2311587284)})],1):_vm._e(),_c('approval-files',{attrs:{"dialog":_vm.approvalFileDialog,"case-number":_vm.caseNumber,"case-m-key":_vm.caseMKey,"patient-name":_vm.patientName,"practice-name":_vm.practiceName,"messaging":_vm.canMessage,"files":_vm.files,"notes":_vm.notes},on:{"childShow":_vm.closeApprovalFileDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="fill-height flex">
    <v-card-title
      ><h3>
        {{ $t("nexusios.patientOrder.product.fullArch") }}
      </h3></v-card-title
    >
    <v-stepper v-model="step" class="fill-height" v-if="!loading">
      <v-stepper-items class="fill-height">
        <!-- Select Product Category (Step 0)-->
        <v-stepper-content
          step="0"
          class="fill-height pa-6 background-linear-gradient"
        >
          <h1>
            {{ $t("nexusios.orderNow.selectProductCategory") }}
          </h1>
          <IntendedProductCategorySelector
            :country="this.approvedClient.country"
            :intended="this.intended"
            v-model="productCategory"
            @input="step = 1"
          ></IntendedProductCategorySelector>
        </v-stepper-content>

        <!-- Select Indication Type (Step 1) -->
        <v-stepper-content
          step="1"
          class="fill-height pa-6 background-linear-gradient"
        >
          <h1>
            <v-btn fab @click="step = 0">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
            {{ $t("nexusios.orderNow.selectIndicationType") }}
          </h1>
          <IndicationTypeSelector
            :product-category="productCategory"
            v-model="indicationType"
            @input="indicationTypeSelected"
          ></IndicationTypeSelector>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters, mapActions } from "vuex";
import IndicationTypeSelector from "../IndicationTypeSelector";
import IntendedProductCategorySelector from "./IntendedProductCategorySelector";

export default {
  name: "FullArchUpgradeOrder",

  components: {
    IndicationTypeSelector,
    IntendedProductCategorySelector
  },

  data() {
    return {
      loading: false,
      productCategory: "",
      indicationType: "",
      step: 0,
      intended: "full-arch",
      orderId: null,
      detailId: null
    };
  },

  computed: {
    ...mapGetters(["approvedClient", "kits", "practices"])
  },

  methods: {
    ...mapActions(["fetchUser"]),

    prettyDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
    },

    indicationTypeSelected() {
      this.$router.push({
        name: "customise-order",
        params: { type: this.indicationType },
        query: {
          intended: "full-arch-order",
          id: this.orderId,
          odid: this.detailId,
          product: this.productCategory
        }
      });
    }
  },

  async mounted() {
    this.loading = false;
    this.orderId = this.$route.query.id;
    this.detailId = this.$route.query.odid;
  }
};
</script>

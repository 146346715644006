<template>
  <v-btn-toggle
    v-model="attachment"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="attachmentSelected"
  >
    <v-btn class="ma-1" value="locator" :disabled="disabled">
      {{ $t("productOptions.attachements.locator") }}
    </v-btn>
    <v-btn class="ma-1" value="equator" :disabled="disabled">
      {{ $t("productOptions.attachements.equator") }}
    </v-btn>
    <v-btn class="ma-1" value="unicone" :disabled="disabled">
      {{ $t("productOptions.attachements.uniCone") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "Attachments",

  props: ["value", "disabled"],

  data() {
    return {
      attachment: this.value
    };
  },

  methods: {
    attachmentSelected() {
      this.$emit("input", this.attachment);
    }
  }
};
</script>

<template>
  <div v-if="this.value && this.value.length > 0">
    <div v-if="!started">
      <v-row>
        <v-col class="text-center">
          <h4>Getting ready to upload your files...</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center py-12">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </div>
    <div v-if="started">
      <v-row>
        <v-col>
          <v-card color="primary" class="pb-4">
            <v-card-title>
              <h4 class="mx-auto">
                <v-icon class="mr-2">mdi-cloud-upload</v-icon>
                Uploading file {{ fileIndex + 1 }} of {{ totalFiles }}...
              </h4>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="text-center">
                  Current File Progress:
                  <v-progress-linear
                    class="progress-file-upload"
                    :value="currentFileProgress"
                    color="white"
                    height="25"
                    rounded
                  >
                    <span :class="{ 'black--text': currentFileProgress > 49 }"
                      >{{ currentFileProgress }} %</span
                    >
                  </v-progress-linear>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  Total Progress:
                  <v-progress-linear
                    class="progress-file-upload"
                    :value="totalProgress"
                    color="white"
                    height="25"
                    rounded
                  >
                    <span :class="{ 'black--text': totalProgress > 49 }">
                      {{ totalProgress }} %</span
                    >
                  </v-progress-linear>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card color="warning" min-height="100%">
            <v-card-title>
              <h4>
                <v-icon class="mr-2">mdi-clock</v-icon>
                Waiting to be Uploaded
                <v-badge :content="value.length.toString()" class="ml-2" />
              </h4>
            </v-card-title>
            <v-card-text>
              <v-card class="overflow-y-auto" max-height="280">
                <v-simple-table>
                  <tbody>
                    <tr v-for="(file, index) in value" :key="index">
                      <td>
                        <div class="mt-5" v-if="index === 0"></div>
                        <v-icon class="mr-2">mdi-file</v-icon>
                        {{ file.name }}
                        <div class="my-5" v-if="index === 0">
                          <v-progress-linear
                            height="10"
                            indeterminate
                            rounded
                            color="warning"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr v-if="value.length === 0">
                      <td class="text-center">
                        All files have been uploaded.
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card color="success" min-height="100%">
            <v-card-title>
              <h4>
                <v-icon class="mr-2">mdi-checkbox-marked-circle</v-icon>
                Upload Completed
                <v-badge
                  :content="(uploaded.length + hasError.length).toString()"
                  class="ml-2"
                />
              </h4>
            </v-card-title>
            <v-card-text>
              <v-card class="overflow-y-auto" max-height="280">
                <v-simple-table>
                  <tbody is="transition-group">
                    <tr
                      v-for="(file, index) in uploaded"
                      :key="index"
                      transition="slide-x-transition"
                    >
                      <td>
                        <v-icon class="mr-2">mdi-file</v-icon>
                        {{ file.name }}
                      </td>
                      <td class="text-right">
                        <v-icon color="success">mdi-check</v-icon>
                      </td>
                    </tr>
                    <tr v-for="(file, index) in hasError" :key="index">
                      <td>
                        <v-icon class="mr-2">mdi-file</v-icon>
                        {{ file.name }}
                      </td>
                      <td class="text-right">
                        <v-icon color="error" class="mr-2">mdi-alert</v-icon>
                        Upload Error
                      </td>
                    </tr>
                    <tr v-if="uploaded.length + hasError.length === 0">
                      <td class="text-center">
                        No files uploaded yet...
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Uploader",
  props: ["value", "start", "uploadUrl"],
  data() {
    return {
      started: false,
      axiosClient: null,
      fileIndex: 0,
      totalFiles: 0,
      uploaded: [],
      hasError: [],
      currentFileProgress: 0
    };
  },
  watch: {
    start(value) {
      if (!value || this.started) {
        return;
      }
      this.started = true;
      this.startUploading();
    }
  },
  computed: {
    totalProgress() {
      if (this.totalFiles === 0) {
        return 100;
      }
      const fileWeight = 1 / this.totalFiles;
      const completedPortion =
        fileWeight * (this.uploaded.length + this.hasError.length);
      const totalProgress =
        completedPortion + fileWeight * (this.currentFileProgress / 100);

      return (totalProgress * 100).toFixed(0);
    }
  },
  methods: {
    startUploading() {
      this.totalFiles = this.value.length;
      this.queueNextFile();
    },
    queueNextFile() {
      this.currentFileProgress++;
      if (this.value.length > 0) {
        this.uploadFile(this.value[0]);
      } else {
        this.$emit("complete", true);
      }
    },
    async uploadFile(file) {
      const form = new FormData();
      form.append("file", file);
      this.axiosClient
        .post(this.uploadUrl, form, {
          timeout: 86_400_000,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: progressEvent => {
            this.currentFileProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          }
        })
        .then(() => {
          this.fileIndex++;
          this.currentFileProgress = 0;
          this.uploaded.unshift(this.value[0]);
          this.value.splice(0, 1);
          this.queueNextFile();
        })
        .catch(() => {
          this.fileIndex++;
          this.currentFileProgress = 0;
          this.hasError.unshift(this.value[0]);
          this.value.splice(0, 1);
          this.queueNextFile();
        });
    }
  },
  mounted() {
    this.axiosClient = this.$httpClient.client();
  }
};
</script>

<style>
.progress-file-upload .v-progress-linear__determinate {
  transition: none !important;
}
.progress-file-upload .v-progress-linear__background {
  transition: none !important;
}
</style>

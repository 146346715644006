<template>
  <div class="flex">
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <div v-if="!loading">
      <v-container fluid class="ma-0 pa-0" justify="center">
        <v-row wrap>
          <v-col cols="12" order-md="2" order="1">
            <v-container fill-height align-center text-center>
              <v-col md="12">
                <h1>
                  <v-btn :to="{ name: 'instructions' }" class="mx-1 my-3" fab>
                    <v-icon x-large>mdi-chevron-left</v-icon>
                  </v-btn>
                  {{ $t("nexusios.instructions.medit.medit") }}
                  <!-- Medit -->
                </h1>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="py-5 black">
        <v-container fluid class="ma-0 pa-0">
          <v-row wrap>
            <v-col cols="12" order-md="2" order="1">
              <v-container fill-height align-center text-left>
                <v-col md="6">
                  <h1>
                    {{
                      $t(
                        "nexusios.instructions.medit.meditScanningInstructions"
                      )
                    }}
                    <!-- Medit Scanning Instructions -->
                  </h1>
                  <p></p>
                </v-col>
                <v-col md="6">
                  <v-img src="../assets/instruction-images/scanner-medit.png">
                  </v-img>
                </v-col>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <v-expansion-panels tile accordion>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center"
              ><h2>
                {{ $t("nexusios.instructions.steps.1Preparations") }}
                <!-- 1. Preparations -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-1a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.1.1a") }}
                        <!-- 1a. Open the Medit application -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-1b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.1.1b") }}
                        <!-- 1b. Select “New Case” -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-1c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.1.1c") }}
                        <!-- 1c. Enter your patient information -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-1d.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.1.1d") }}
                        <!-- 1d. Select orthdontics scan option -->
                      </h2>
                      <p></p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center"
              ><h2>
                {{ $t("nexusios.instructions.steps.2FoundationScans") }}
                <!-- 2. Foundation Scans -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2a") }}
                        <!-- 2a. Select the arch that requires the prosthesis -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2b") }}
                        <!-- 2b. Select scan -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2c") }}
                        <!-- 2c. Select the arch to begin scanning -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2d.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2d") }}
                        <!-- 2d. Follow the on screen instructions to perform a scan
                        of the arch on which the restoration is to be designed -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2e.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2e") }}
                        <!-- 2e. Follow the on screen instructions to perform a scan
                        of the opposing arch -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2f.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2f") }}
                        <!-- 2f. Scan occlusion. Make sure that booth sides of the
                        bite are scanned -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2g.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2g") }}
                        <!-- 2g. Select the option that maintains the scan
                        information closest to the original -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2h.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2h") }}
                        <!-- 2h. Confirm that your scan data is correct, and click
                        order -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-2i.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.2.2i") }}
                        <!-- 2i. Order your case with “Osteon Medical” selected as
                        your partner -->
                      </h2>
                      <p></p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center"
              ><h2>
                {{ $t("nexusios.instructions.steps.3ScanGaugeScans") }}
                <!-- 3. Scan Gauge Scans -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3a") }}
                        <!-- 3a. Select “New Case” -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3b") }}
                        <!-- 3b. Enter your patient information -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3c") }}
                        <!-- 3c.Select orthodontics scanning option -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3d.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3d") }}
                        <!-- 3d. Turn on HD mode for the Scan Gauge scan. -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3e.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3e") }}
                        <!-- 3e. Place the scan gauges along the arch and scan from
                        right to left in one continuous sweep -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3f.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3f") }}
                        <!-- 3f. Select the next scanning window, and scan from left
                        to right in one continuous sweep -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3g.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3g") }}
                        <!-- 3g. Select the option that maintains the scan
                        information closest to the original -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-3h.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.3.3h") }}
                        <!-- 3h. Order your case with “Osteon Medical” selected as
                        your partner -->
                      </h2>
                      <p></p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center"
              ><h2>
                {{ $t("nexusios.instructions.steps.4TissueAndExtra") }}
                <!-- 4. Tissue and Extra Oral Denture Scan -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-4a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.4.4a") }}
                        <!-- 4a. Select “New Case” -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-4b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.4.4b") }}
                        <!-- 4b. Enter your patient information -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-4c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.4.4c") }}
                        <!-- 4c. Select orthodontics scanning option -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-4d.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.4.4d") }}
                        <!-- 4d. Scan the tissue of the arch on which the restoration
                        is to be designed -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-4e.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.4.4e") }}
                        <!-- 4e. Attach 3 analogs to the denture, and start denture -->
                        <!-- scan (extra-oral). -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/medit/medit-4f.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.medit.4.4f") }}
                        <!-- 4f. Order your case with “Osteon Medical” selected as -->
                        <!-- your partner -->
                      </h2>
                      <p></p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <InstructionsChecklist />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InstructionsChecklist from "./InstructionsChecklist.vue";

export default {
  name: "InstructionsMedit",
  components: {
    InstructionsChecklist
  },
  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapGetters(["approvedClient"])
  },

  methods: {
    ...mapActions(["fetchUser", "logout"])
  },

  async mounted() {
    this.loading = false;
  }
};
</script>

<template>
  <v-btn-toggle
    v-model="shade"
    class="d-flex flex-wrap"
    active-class="active-button"
    background-color="rgba(0,0,0,0)"
    borderless
    @change="shadeSelected"
  >
    <v-btn
      v-for="item in shadeItems"
      :key="item.value"
      :class="item.class"
      :value="item.value"
      :disabled="disabled"
    >
      {{ item.text }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "ToothShade",

  props: ["value", "disabled"],

  data() {
    return {
      shade: this.value
    };
  },
  computed: {
    shadeItems() {
      return [
        {
          value: "a1",
          class: "ma-1 a1",
          text: this.$t("productOptions.toothShades.a1")
        },
        {
          value: "a2",
          class: "ma-1 a2",
          text: this.$t("productOptions.toothShades.a2")
        },
        {
          value: "a3",
          class: "ma-1 a3",
          text: this.$t("productOptions.toothShades.a3")
        },
        {
          value: "a35",
          class: "ma-1 a35",
          text: this.$t("productOptions.toothShades.a35")
        },

        {
          value: "b1",
          class: "ma-1 b1",
          text: this.$t("productOptions.toothShades.b1")
        },
        {
          value: "c2",
          class: "ma-1 c2",
          text: this.$t("productOptions.toothShades.c2")
        },
        {
          value: "d3",
          class: "ma-1 d3",
          text: this.$t("productOptions.toothShades.d3")
        },

        {
          value: "bl1",
          class: "ma-1 bl1",
          text: this.$t("productOptions.toothShades.bl1")
        },
        {
          value: "bl2",
          class: "ma-1 bl2",
          text: this.$t("productOptions.toothShades.bl2")
        },
        {
          value: "bl3",
          class: "ma-1 bl3",
          text: this.$t("productOptions.toothShades.bl3")
        },
        {
          value: "other",
          class: "ma-1",
          text: this.$t("common.other")
        }
      ];
    }
  },
  methods: {
    shadeSelected() {
      this.$emit("input", this.shade);
    }
  }
};
</script>

<template>
  <div class="flex">
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-container fluid class="ma-0 pa-0" justify="center" v-if="!loading">
      <v-row wrap>
        <v-col cols="12" order-md="2" order="1">
          <v-container fill-height>
            <v-col md="12">
              <h1>
                {{ $t("nexusios.instructions.nexusIosInstructions") }}
              </h1>
            </v-col>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="pa-5" justify="center">
        <v-col cols="12" sm="4" class="ma-3">
          <v-row class="ma-3 text-center" justify="center">
            <h2>
              {{ $t("nexusios.instructions.generalInstructions") }}
              <!-- General Instructions -->
            </h2>
          </v-row>
          <v-row justify="center">
            <v-col class="text-center">
              <v-card color="secondary">
                <v-card-text>
                  <img
                    src="../assets/instruction-images/general-instructions.png"
                    style="height: inherit; width: inherit;"
                  />
                  <v-btn
                    :to="{ name: 'instructions-general' }"
                    class="mx-1 my-3"
                    tile
                    outlined
                  >
                    {{ $t("nexusios.instructions.generalInstructions") }}
                    <!-- General Instructions -->
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="7" class="ma-3">
          <v-row class="ma-3 text-center" justify="center">
            <h2>
              {{ $t("nexusios.instructions.scannerSpecificInstructions") }}
              <!-- Scanner Specific Instructions -->
            </h2>
          </v-row>
          <v-row justify="center" class="text-center">
            <v-col cols="12" md="3" sm="5">
              <v-card color="secondary">
                <v-card-text>
                  <img
                    src="../assets/instruction-images/scanner-medit.png"
                    style="height: inherit; width: inherit;"
                  />
                  <v-btn
                    :to="{
                      name: 'instructions-viewer',
                      params: { type: 'medit' }
                    }"
                    class="mx-1 my-3"
                    tile
                    outlined
                    target="_blank"
                  >
                    {{ $t("nexusios.instructions.medit.medit") }}
                    <!-- Medit -->
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="5">
              <v-card color="secondary">
                <v-card-text>
                  <img
                    src="../assets/instruction-images/scanner-trios.png"
                    style="height: inherit; width: inherit;"
                  />
                  <v-btn
                    :to="{
                      name: 'instructions-viewer',
                      params: { type: 'trios' }
                    }"
                    class="mx-1 my-3"
                    tile
                    outlined
                    target="_blank"
                  >
                    {{ $t("nexusios.instructions.trios.trios") }}
                    <!-- Trios -->
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="5">
              <v-card color="secondary">
                <v-card-text>
                  <img
                    src="../assets/instruction-images/scanner-itero.png"
                    style="height: inherit; width: inherit;"
                  />
                  <v-btn
                    :to="{
                      name: 'instructions-viewer',
                      params: { type: 'itero' }
                    }"
                    class="mx-1 my-3"
                    tile
                    outlined
                    target="_blank"
                  >
                    {{ $t("nexusios.instructions.itero.itero") }}
                    <!-- iTero -->
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="3" sm="5">
              <v-card color="secondary">
                <v-card-text>
                  <img
                    src="../assets/instruction-images/scanner-cerec.png"
                    style="height: inherit; width: inherit;"
                  />
                  <v-btn
                    :to="{
                      name: 'instructions-viewer',
                      params: { type: 'cerec' }
                    }"
                    class="mx-1 my-3"
                    tile
                    outlined
                    target="_blank"
                  >
                    Cerec <br />Primescan
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Instructions",

  props: ["filename"],

  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapGetters(["approvedClient"])
  },

  methods: {
    ...mapActions(["fetchUser", "logout"])
  },

  async mounted() {
    this.loading = false;
  }
};
</script>

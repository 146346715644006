<template>
  <v-btn-toggle
    v-model="overlayMaterial"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="overlayMaterialSelected"
  >
    <v-btn
      class="ma-1"
      value="zirconia-monolithic"
      :disabled="disabled"
      v-if="options.includes('zirconia-monolithic')"
    >
      {{ $t("material.zirconiaMonolithic") }}
    </v-btn>
    <v-btn
      class="ma-1"
      value="zirconia-layered"
      :disabled="disabled"
      v-if="options.includes('zirconia-layered')"
    >
      {{ $t("material.zirconiaLayered") }}
    </v-btn>
    <v-btn
      class="ma-1"
      value="acrylic"
      :disabled="disabled"
      v-if="options.includes('acrylic') && barMaterial === 'titanium'"
    >
      {{ $t("material.acrylic") }}
    </v-btn>
    <v-btn
      class="ma-1"
      value="pfm"
      :disabled="disabled"
      v-if="options.includes('pfm') && barMaterial === 'titanium'"
    >
      {{ $t("material.pfm") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "OverlayMaterialSelections",

  props: ["value", "disabled", "options", "barMaterial"],

  data() {
    return {
      overlayMaterial: this.value
    };
  },

  methods: {
    overlayMaterialSelected() {
      this.$emit("input", this.overlayMaterial);
    }
  },

  mounted() {
    if (this.options.length === 1) {
      this.value = this.options[0];
      this.$emit("input", this.value);
    }
  }
};
</script>

<template>
  <div class="fill-height flex" style="min-height: 45em;">
    <v-container class="pa-12 mt-12 text-center" v-if="!ready">
      <v-progress-circular
        ref="jitsiLoading"
        :size="300"
        :width="20"
        indeterminate
      >
        <!-- {{ loadingMessage }} -->
        {{ $t(loadingMessage) }}
      </v-progress-circular>
    </v-container>
    <v-toolbar v-if="ready">
      <v-btn
        @click="executeJitsiCommand('toggleAudio')"
        :color="muteButtonColor"
      >
        {{ $t("nexusios.chairsideSupport.supportSession.mute") }}
        <!-- Mute -->
        <v-icon right>mdi-microphone-off</v-icon>
      </v-btn>
      <v-btn
        @click="executeJitsiCommand('toggleShareScreen')"
        :color="screenSharingButtonColor"
      >
        {{ $t("nexusios.chairsideSupport.supportSession.shareScreen") }}
        <!-- Share Screen -->
        <v-icon right>mdi-monitor-screenshot</v-icon>
      </v-btn>
      <v-btn @click="phoneLink = true">
        {{ $t("nexusios.chairsideSupport.supportSession.addMobile") }}
        <!-- Add Mobile -->
        <v-icon right>mdi-cellphone</v-icon>
      </v-btn>
      <v-btn @click="glassesQR = true">
        {{ $t("nexusios.chairsideSupport.supportSession.addARGlasses") }}
        <!-- Add AR Glasses -->
        <v-icon right>mdi-glasses</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="red" @click="executeJitsiCommand('hangup')">
        {{ $t("nexusios.chairsideSupport.supportSession.end") }}
        <!-- End -->
        <v-icon right>mdi-phone-hangup</v-icon>
      </v-btn>
    </v-toolbar>
    <vue-jitsi-meet
      ref="jitsiRef"
      domain="support.nexusios.com"
      v-if="ready"
      :options="jitsiOptions"
    >
    </vue-jitsi-meet>
    <v-dialog v-model="glassesQR" width="600">
      <v-card>
        <v-card-title class="headline">
          {{ $t("nexusios.chairsideSupport.supportSession.connectARGlasses") }}
          <!-- Connect AR Glasses -->
        </v-card-title>
        <v-flex text-center class="pa-12" style="background-color: white;">
          <qrcode-vue
            :value="roomName"
            size="200"
            level="H"
            renderAs="svg"
          ></qrcode-vue>
        </v-flex>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="glassesQR = false">
            {{ $t("common.close") }}
            <!-- Close -->
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="phoneLink" width="600">
      <v-card>
        <v-card-title class="headline">
          {{
            $t("nexusios.chairsideSupport.supportSession.connectMobilePhone")
          }}
          <!-- Connect Mobile Phone -->
        </v-card-title>
        <v-card-text style="min-height: 300px;">
          <VuePhoneNumberInput
            dark
            size="lg"
            v-model="phoneNumber"
            @update="setPhoneNumber"
          />
          <div class="text-center">
            <v-btn
              color="success"
              x-large
              class="ma-12"
              @click="sendSupportLink"
            >
              {{ $t("nexusios.chairsideSupport.supportSession.sendLink") }}
              <!-- Send Link -->
            </v-btn>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="phoneLink = false">
            {{ $t("common.close") }}
            <!-- Close -->
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { JitsiMeet } from "@mycure/vue-jitsi-meet";
import QrcodeVue from "qrcode.vue";
import { mapGetters, mapActions } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "SupportSession",

  props: ["configuration"],

  components: {
    VueJitsiMeet: JitsiMeet,
    QrcodeVue,
    VuePhoneNumberInput
  },

  data() {
    return {
      session: null,
      loadingMessage:
        "nexusios.chairsideSupport.supportSession.waitingForAgent",
      ready: false,
      muted: false,
      screenSharing: false,
      glassesQR: false,
      phoneLink: false,
      phoneNumber: null,
      formattedPhoneNumber: null
    };
  },

  computed: {
    ...mapGetters(["approvedClient"]),

    jitsiOptions() {
      return {
        roomName: this.session.room_name,
        noSSL: false,
        userInfo: {
          email: this.approvedClient.email,
          displayName: this.approvedClient.name
        },
        configOverwrite: {
          enableNoisyMicDetection: true
        },
        interfaceConfigOverwrite: {
          SETTINGS_SECTIONS: [],
          TOOLBAR_BUTTONS: []
        },
        onload: this.onIFrameLoad
      };
    },

    roomName() {
      if (this.session !== null) {
        return this.session.room_name;
      } else {
        return null;
      }
    },

    muteButtonColor() {
      if (this.muted) {
        return "primary";
      } else {
        return "default";
      }
    },

    screenSharingButtonColor() {
      if (this.screenSharing) {
        return "primary";
      } else {
        return "default";
      }
    }
  },

  methods: {
    ...mapActions(["fetchUser"]),

    onIFrameLoad() {
      this.$refs.jitsiRef.addEventListener(
        "videoConferenceLeft",
        this.conferenceEnded
      );
      this.$refs.jitsiRef.addEventListener(
        "screenSharingStatusChanged",
        this.screenSharingChanged
      );
      this.$refs.jitsiRef.addEventListener(
        "audioMuteStatusChanged",
        this.audioMuteChanged
      );
    },

    async pollSession() {
      this.session = await this.$httpClient.getSupportSession(this.session);
      if (this.session.answered_at === null) {
        setTimeout(this.pollSession, 1000);
      } else {
        this.loadingMessage = "Connecting";
        setTimeout(this.startConference, 5000);
      }
    },

    startConference() {
      this.ready = true;
      if (this.configuration === "glasses") {
        setTimeout(() => {
          this.glassesQR = true;
        }, 5000);
      } else if (this.configuration === "phone") {
        setTimeout(() => {
          this.phoneLink = true;
        }, 5000);
      }
    },

    audioMuteChanged(status) {
      this.muted = status.muted;
    },

    screenSharingChanged(status) {
      this.screenSharing = status.on;
    },

    conferenceEnded() {
      document.location = "/";
    },

    executeJitsiCommand(command) {
      this.$refs.jitsiRef.executeCommand(command);
    },

    setPhoneNumber(payload) {
      this.formattedPhoneNumber = payload.formattedNumber;
    },

    sendSupportLink() {
      this.phoneLink = false;
      this.$httpClient.sendSupportLink(this.session, this.formattedPhoneNumber);
    }
  },

  async mounted() {
    this.$httpClient.createSupportSession().then(session => {
      this.session = session;
      this.pollSession();
    });
  }
};
</script>

<template>
  <v-btn-toggle
    v-model="required"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="requiredSelected"
  >
    <v-btn class="ma-1" value="yes" :disabled="disabled">
      {{ $t("common.yes") }}
    </v-btn>
    <v-btn class="ma-1 no" value="no" :disabled="disabled">
      {{ $t("common.no") }}
    </v-btn>
    <small class="mt-5">
      {{ $t("nexusios.orderNow.dentureUsedAsInterim") }}
    </small>
    <small class="mt-5 red--text">
      *This will incur an additional cost.
    </small>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "InterimDenture",

  props: ["value", "disabled"],

  data() {
    return {
      required: this.value
    };
  },

  methods: {
    requiredSelected() {
      this.$emit("input", this.required);
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"fixed",staticClass:"pa-8 pa-lg-12",staticStyle:{"background":"linear-gradient(-120deg, #2e2e2e, #000 100%)"},attrs:{"fluid":""}},[(_vm.loading)?_c('v-container',{staticClass:"pa-12 text-center"},[_c('v-progress-circular',{attrs:{"size":100,"width":10,"indeterminate":""}})],1):_vm._e(),(!_vm.loading)?_c('v-form',{ref:"supportForm"},[(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" An unexpected error has occurred, please contact us for assistance. ")]):_vm._e(),_c('v-row',{staticClass:"ma-5"},[_c('v-col',{attrs:{"md":"8"}},[_c('h1',{staticClass:"my-3"},[_vm._v("Book Chairside Support")])])],1),_c('v-row',{staticClass:"ma-5"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"max-height":"600","src":require('../assets/intraoral-scanning.jpg')}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-select',{attrs:{"solo":"","light":"","label":"Practice","items":_vm.practiceNames,"rules":[_vm.rules.required]},model:{value:(_vm.supportRequest.practice_id),callback:function ($$v) {_vm.$set(_vm.supportRequest, "practice_id", $$v)},expression:"supportRequest.practice_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"color":"primary","x-large":"","to":{
                name: 'edit-practice',
                params: { id: 'new' }
              }}},[_vm._v("New ")])],1)],1),(_vm.selectedPractice)?_c('v-card',[_c('v-card-title',[_vm._v("Contact Details")]),(!_vm.practiceMissingDetails)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.selectedPractice.contact_person)),_c('br'),_vm._v(" "+_vm._s(_vm.selectedPractice.email)),_c('br'),_vm._v(" "+_vm._s(_vm.selectedPractice.phone)+" ")]):_vm._e(),(_vm.practiceMissingDetails)?_c('v-card-text',[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" We have no contact details on file for this practice. ")]),_vm._v(" Please ensure you update contact details before creating your booking. ")],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"to":{
                name: 'edit-practice',
                params: { id: _vm.selectedPractice.id }
              }}},[_vm._v("Update")])],1)],1):_vm._e(),_c('br'),_c('v-text-field',{attrs:{"solo":"","light":"","label":"Patient name","rules":[_vm.rules.required]},model:{value:(_vm.supportRequest.patient_name),callback:function ($$v) {_vm.$set(_vm.supportRequest, "patient_name", $$v)},expression:"supportRequest.patient_name"}}),_c('v-textarea',{attrs:{"solo":"","light":"","label":"Message"},model:{value:(_vm.supportRequest.message),callback:function ($$v) {_vm.$set(_vm.supportRequest, "message", $$v)},expression:"supportRequest.message"}}),_c('v-select',{attrs:{"solo":"","light":"","items":_vm.appointmentType,"label":"Appointment Type","rules":[_vm.rules.required]},model:{value:(_vm.supportRequest.appointment_type),callback:function ($$v) {_vm.$set(_vm.supportRequest, "appointment_type", $$v)},expression:"supportRequest.appointment_type"}}),_c('v-datetime-picker',{attrs:{"label":("Desired Appointment (" + _vm.timeZone + " Time)"),"datePickerProps":{
            min: this.supportRequest.desired_appointment.toISOString()
          },"timePickerProps":{ allowedMinutes: [0, 30] },"textFieldProps":{ solo: true, light: true }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-calendar")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock-outline")])]},proxy:true}],null,false,1765633503),model:{value:(_vm.supportRequest.desired_appointment),callback:function ($$v) {_vm.$set(_vm.supportRequest, "desired_appointment", $$v)},expression:"supportRequest.desired_appointment"}}),_c('v-container',[(_vm.differentZones)?_c('v-row',[_c('v-col',{attrs:{"col":"2"}},[_c('strong',[_vm._v("Desired Appointment:")])])],1):_vm._e(),(_vm.differentZones)?_c('v-row',[_c('v-col',[_vm._v(" Your Time: ")]),_c('v-col',[_vm._v(" "+_vm._s(this.theirAppointmentTime)+" ")])],1):_vm._e(),(_vm.differentZones)?_c('v-row',[_c('v-col',[_vm._v(" Our Time: ")]),_c('v-col',[_vm._v(" "+_vm._s(this.ourAppointmentTime)+" ")])],1):_vm._e(),(!_vm.differentZones)?_c('v-row',[_c('v-col',[_c('strong',[_vm._v("Desired Appointment:")])]),_c('v-col',[_vm._v(" "+_vm._s(this.theirAppointmentTime)+" ")])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" We will confirm your requested booking time with you via email. ")],1)],1)],1),_c('v-container',{staticClass:"text-right"},[_c('v-btn',{attrs:{"x-large":"","color":"success"},on:{"click":_vm.submit}},[_vm._v(" Book Now ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-btn-toggle
      v-model="required"
      class="d-flex flex-wrap"
      active-class="active-button"
      borderless
      background-color="rgba(0,0,0,0)"
      @change="requiredSelected"
    >
      <v-btn class="ma-1" value="yes" :disabled="disabled">
        {{ $t("common.yes") }}
      </v-btn>
      <v-btn class="ma-1" value="no" :disabled="disabled">
        {{ $t("common.no") }}
      </v-btn>
    </v-btn-toggle>
    <div class="my-3 red--text" v-if="value === 'yes'">
      Please be advised material thickness limits may need to be increased to
      accommodate such cases.
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryOfBruxism",

  props: ["value", "disabled"],

  data() {
    return {
      required: this.value
    };
  },

  methods: {
    requiredSelected() {
      this.$emit("input", this.required);
    }
  }
};
</script>

import axios from "axios";

const instance = axios.create();

export default class HTTPClient {
  client() {
    return instance;
  }

  async fetchLanguage(lang) {
    const response = await instance.get(`/api/language/${lang}`);
    return response.data;
  }

  async changeLocale(locale) {
    const response = await instance.post(`/api/user_locale/${locale}`);
    return response.data;
  }

  async createMessage(message) {
    const response = await instance.post("/api/message", message);
    return response.data;
  }

  async fetchGalleryImages() {
    const response = await instance.get("/api/gallery");
    return response.data;
  }

  async fetchImplantPlatforms(practiceID) {
    const response = await instance.get(
      `/api/implant_platforms?practice_id=${practiceID}`
    );
    return response.data;
  }

  async fetchMe() {
    const response = await instance.get("/api/me");
    return response.data;
  }

  async fetchCases(case_id) {
    const response = await instance.get("/api/case/" + case_id + "/children/");
    return response.data;
  }

  async fetchCasesStatus(cases) {
    const response = await instance.post("/api/case/status", cases);
    return response.data;
  }

  async fetchNotes(case_id) {
    const response = await instance.get(
      "/api/case/" + case_id + "/child-notes/"
    );
    return response.data;
  }

  async fetchParentNotes(case_id) {
    const response = await instance.get("/api/case/" + case_id + "/notes/");
    return response.data;
  }

  async logout() {
    const response = await instance.post("/api/logout");
    return response.data;
  }

  async register(client, practice) {
    const response = await instance.post("/api/client", { client, practice });
    return response.data;
  }

  async updateClient(client) {
    const response = await instance.put("/api/client", client);
    return response.data;
  }

  async createPractice(practice) {
    const response = await instance.post("/api/practices", practice);
    return response.data;
  }

  async updatePractice(practice) {
    const response = await instance.put(
      `/api/practices/${practice.id}`,
      practice
    );
    return response.data;
  }

  async deletePractice(practice) {
    const response = await instance.delete(`/api/practices/${practice.id}`);
    return response.data;
  }

  async createSupport(support) {
    const response = await instance.post("/api/support", support);
    return response.data;
  }

  async createSupportSession() {
    const response = await instance.post("/api/support/session");
    return response.data;
  }

  async getSupportSession(session) {
    const response = await instance.get(`/api/support/session/${session.id}`);
    return response.data;
  }

  async sendSupportLink(session, phoneNumber) {
    const response = await instance.post(
      `/api/support/session/${session.id}/send_sms`,
      {
        phone_number: phoneNumber
      }
    );
    return response.data;
  }

  async createKitOrder(order) {
    const response = await instance.post("/api/scan_gauge_kits", order);
    return response.data;
  }

  async createOrder(order) {
    const response = await instance.post("/api/order", order);
    return response.data;
  }

  async getCaseFilesApproval(oep_case_id) {
    const response = await instance.get(`/api/case/files/${oep_case_id}`);
    return response.data;
  }

  async postCaseFilesApproval(oep_case_id, formData) {
    const response = await instance.post(
      `/api/case/files/${oep_case_id}`,
      formData
    );
    return response.data;
  }

  async createPatientOrder(patientOrder) {
    const response = await instance.post("/api/patient/order", patientOrder);
    return response.data;
  }

  async deletePatientOrder(id, detailID) {
    const response = await instance.delete(
      `/api/patient/order/${id}/?detail_id=${detailID}`
    );
    return response.data;
  }

  async cancelEmail(scheduled_email_id) {
    const response = await instance.delete(
      `/api/patient/order/cancel_email/${scheduled_email_id}`
    );
    return response.data;
  }

  async createPatientImmediateOrder(patientOrder) {
    const response = await instance.post(
      "/api/patient/immediate_order",
      patientOrder
    );
    return response.data;
  }

  async getPatientOrder(orderId) {
    const response = await instance.get(`/api/patient/order/${orderId}`);
    return response.data;
  }

  async getAllPatientOrders(clientId) {
    const response = await instance.get(`/api/patient/orders/${clientId}`);
    return response.data;
  }

  async getPatientOrderDetail(orderId, detailID) {
    const response = await instance.get(
      `/api/patient/order/detail/${orderId}/?detail_id=${detailID}`
    );
    return response.data;
  }

  async updatePatientOrderDetail(
    orderId,
    detailId,
    immediateId,
    definitiveId,
    patientOrder
  ) {
    let url = `/api/patient/order/detail/${orderId}/?detail_id=${detailId}`;
    if (immediateId) {
      url = url + `&immediate_detail_id=${immediateId}`;
    }
    if (definitiveId) {
      url = url + `&definitive_detail_id=${definitiveId}`;
    }
    const response = await instance.post(url, patientOrder);
    return response.data;
  }

  async patchOrderDetail(orderId, patientOrder) {
    const response = await instance.post(
      `/api/patient/order/details/${orderId}`,
      patientOrder
    );
    return response.data;
  }

  async updatePatientOrderStatus(orderId, detailId, stageStatus) {
    const response = await instance.put(
      `/api/patient/order/detail/${orderId}/stage_status/?detail_id=${detailId}&stage=${stageStatus}`
    );
    return response.data;
  }

  async fetchPortalKey(practiceID, oepCaseID) {
    const response = await instance.get(
      `/api/patient/orders/${practiceID}/key/${oepCaseID}`
    );
    return response.data;
  }
}

<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="ma-0 pa-0">
        <v-card elevation="0" v-if="!loading">
          <v-toolbar color="secondary">
            <template v-slot:extension>
              <v-tabs color="white" v-model="tabs">
                <v-tab v-for="(obj, index) in tabBlurbs" :key="index">
                  <v-badge
                    :color="badgeColor(index)"
                    :value="recordExists(index)"
                  >
                    <template v-slot:badge>
                      <span v-if="index === 0">
                        {{ needsAction.length }}
                      </span>
                      <span v-if="index === 1">
                        {{ incompleteOrders.length }}
                      </span>
                      <span v-if="index === 2">
                        {{ items.length }}
                      </span>
                    </template>
                    {{ obj.title }}
                  </v-badge>
                </v-tab>
              </v-tabs>
            </template>
            <v-toolbar-title>
              {{ $t("nexusios.patientOrder.tabs.allOrders") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchValue"
              append-icon="mdi-magnify"
              solo
              light
              dense
              hide-details
              clearable
              type="text"
              :label="$t('common.search')"
              class="shrink"
            ></v-text-field>
          </v-toolbar>
          <v-tabs-items v-model="tabs">
            <v-tab-item v-for="(obj, index) in tabBlurbs" :key="index">
              <v-card flat class="px-4">
                <v-card-title class="text-h5">
                  {{ obj.title }}
                </v-card-title>
                <v-card-text class="py-0">
                  <p>
                    {{ obj.blurb }}
                  </p>
                </v-card-text>
                <v-row
                  class="ma-4 pa-4"
                  justify="center"
                  v-if="recordExists(index)"
                >
                  <patient-card
                    :item="patient"
                    :isContinue="isContinue(patient)"
                    :isDelete="isDelete(patient)"
                    v-for="patient in search(getItems(index))"
                    :key="patient.id"
                    v-on:deleteOrder="deleteOrder(patient)"
                    v-on:continueOrder="continueOrder(patient)"
                  >
                  </patient-card>
                </v-row>
                <v-card-text v-if="!recordExists(index)">
                  <v-row>
                    <v-col cols="12" class="text-center my-5">
                      <v-icon class="mr-2">mdi-information</v-icon>
                      {{ $t("nexusios.patientOrder.tabs.noData") }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-spacer></v-spacer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import PatientCard from "./PatientCard";

export default {
  name: "PatientOrderTabs",

  components: {
    "patient-card": PatientCard
  },

  props: ["items", "needsAction", "incompleteOrders", "submittedOrders"],

  data() {
    return {
      searchValue: null,
      loading: true,
      tabs: null
    };
  },

  computed: {
    tabBlurbs() {
      return [
        {
          title: this.$t("nexusios.patientOrder.tabs.needsAction"),
          blurb: this.$t("nexusios.patientOrder.tabs.needsActionBlurb")
        },
        {
          title: this.$t("nexusios.patientOrder.status.incomplete"),
          blurb: this.$t("nexusios.patientOrder.tabs.incompleteBlurb")
        },
        {
          title: this.$t("nexusios.patientOrder.tabs.currentOrders"),
          blurb: this.$t("nexusios.patientOrder.tabs.allOrdersBlurb")
        }
      ];
    }
  },

  methods: {
    deleteOrder(item) {
      this.$emit("deleteOrder", item);
    },
    continueOrder(item) {
      this.$emit("continueOrder", item);
    },
    prettyDateTime(dateTime) {
      if (dateTime) {
        return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATE_SHORT);
      }
    },
    isContinue(item) {
      let enableBtn =
        item.preop_status && item.preop_status == "Incomplete"
          ? true
          : item.immediate_status && item.immediate_status == "Incomplete"
          ? true
          : item.definitive_status && item.definitive_status == "Incomplete"
          ? true
          : false;
      if (!enableBtn && item.intended == "immediate-restoration-order") {
        if (
          item.preop_status == "Ordered" ||
          item.preop_status == "Pending" ||
          item.preop_status == "Dispatched" ||
          item.preop_status == "Complete" ||
          item.preop_status == "Skipped" ||
          item.preop_status == "Needs Approval"
        ) {
          if (
            item.immediate_status == "Ordered" ||
            item.immediate_status == "Pending" ||
            item.immediate_status == "Dispatched" ||
            item.immediate_status == "Complete" ||
            item.immediate_status == "Needs Approval"
          ) {
            enableBtn = !item.definitive_status
              ? true
              : item.definitive_status == "Incomplete" ||
                item.definitive_status == "Deleted"
              ? true
              : false;
          } else {
            enableBtn = !item.immediate_status
              ? true
              : item.immediate_status == "Incomplete" ||
                item.definitive_status == "Deleted"
              ? true
              : false;
          }
        }
      }

      return enableBtn;
    },

    isDelete(item) {
      let enableBtn =
        item.preop_status && item.preop_status == "Incomplete"
          ? true
          : item.immediate_status && item.immediate_status == "Incomplete"
          ? true
          : item.definitive_status && item.definitive_status == "Incomplete"
          ? true
          : false;
      return enableBtn;
    },

    getGroupItems() {
      return this.groupedItems;
    },

    getIncompleteOrders() {
      const incompleteOrders = this.items.filter(item => {
        return (
          item.preop_status === "Incomplete" ||
          item.immediate_status === "Incomplete" ||
          item.definitive_status === "Incomplete"
        );
      });
      return incompleteOrders;
    },

    recordExists(index) {
      let hasRecord = false;
      if (index == 0) {
        hasRecord = this.needsAction.length > 0;
      }
      if (index == 1) {
        hasRecord = this.incompleteOrders.length > 0;
      }
      if (index == 2) {
        hasRecord = this.items.length > 0;
      }
      return hasRecord;
    },

    badgeColor(index) {
      if (index === 0) {
        return "error";
      }
      if (index === 1) {
        return "warning";
      }
      return "primary";
    },

    search(array) {
      if (this.searchValue == null) return array;

      const searchResults = array.filter(item => {
        return this.searchValue
          .toLowerCase()
          .split(" ")
          .every(
            v =>
              item.patient_name.toLowerCase().includes(v) ||
              item.intended_description.toLowerCase().includes(v) ||
              DateTime.fromISO(item.order_date)
                .toLocaleString(DateTime.DATE_SHORT)
                .includes(v) ||
              (item.preop_status
                ? item.preop_status.toLowerCase()
                : ""
              ).includes(v) ||
              (item.immediate_status
                ? item.immediate_status.toLowerCase()
                : ""
              ).includes(v) ||
              (item.definitive_status
                ? item.definitive_status.toLowerCase()
                : ""
              ).includes(v) ||
              item.details.filter(i => {
                return (
                  (i.product_category
                    ? i.product_category.toLowerCase()
                    : ""
                  ).includes(v) ||
                  (i.indications ? i.indications.toLowerCase() : "").includes(
                    v
                  ) ||
                  (i.oep_case_id ? i.oep_case_id : "").includes(v)
                );
              }).length > 0
          );
      });
      return searchResults;
    },

    getItems(index) {
      let patientItems = null;
      if (index == 0) {
        patientItems = this.needsAction;
      }
      if (index == 1) {
        patientItems = this.incompleteOrders;
      }
      if (index == 2) {
        patientItems = this.items;
        patientItems = patientItems.filter(item => {
          return (
            !this.incompleteOrders.includes(item) &&
            !this.needsAction.includes(item)
          );
        });
      }
      return patientItems;
    }
  },

  mounted() {
    this.loading = false;
  }
};
</script>

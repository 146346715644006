<template>
  <div class="fill-height flex">
    <v-card-title
      ><h3>
        {{ $t("nexusios.patientOrder.product.immediateRestoration") }}
      </h3></v-card-title
    >
    <v-stepper v-model="step" class="fill-height" v-if="!loading">
      <v-stepper-items class="fill-height">
        <!-- Select Product Category (Step 0)-->
        <v-stepper-content
          step="0"
          class="fill-height pa-6 background-linear-gradient"
        >
          <h1>
            {{ $t("nexusios.orderNow.selectProductCategory") }}
          </h1>
          <IntendedProductCategorySelector
            :country="this.approvedClient.country"
            :intended="this.intended"
            :category="this.category"
            v-model="productCategory"
            @input="nextStep()"
          ></IntendedProductCategorySelector>
        </v-stepper-content>

        <!-- Select Indication Type (Step 1) -->
        <v-stepper-content
          step="1"
          class="fill-height pa-6 background-linear-gradient"
        >
          <h1>
            <v-btn fab @click="step = 0">
              <v-icon x-large>mdi-chevron-left</v-icon>
            </v-btn>
            {{ $t("nexusios.orderNow.selectIndicationType") }}
          </h1>
          <IndicationTypeSelector
            :product-category="productCategory"
            v-model="indicationType"
            @input="indicationTypeSelected"
          ></IndicationTypeSelector>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters, mapActions } from "vuex";
import IndicationTypeSelector from "../IndicationTypeSelector";
import IntendedProductCategorySelector from "./IntendedProductCategorySelector";

export default {
  name: "ImmediateRestorationOrder",

  components: {
    IndicationTypeSelector,
    IntendedProductCategorySelector
  },

  data() {
    return {
      loading: false,
      productCategory: "",
      indicationType: "",
      step: 0,
      intended: "immediate-full-arch",
      orderId: null,
      detailId: null,
      immediateId: null,
      category: "pre-op"
    };
  },

  computed: {
    ...mapGetters(["approvedClient", "kits", "practices"])
  },

  methods: {
    ...mapActions(["fetchUser"]),

    prettyDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
    },

    indicationTypeSelected() {
      const ordDetailId =
        this.category == "pre-op" && this.productCategory == "immediate"
          ? this.immediateId
          : this.detailId;
      if (ordDetailId != this.detailId) {
        this.skipPreop(ordDetailId);
      } else {
        this.$router.push({
          name: "customise-order",
          params: { type: this.indicationType },
          query: {
            intended: "immediate-restoration-order",
            id: this.orderId,
            odid: ordDetailId,
            product: this.productCategory
          }
        });
      }
    },

    nextStep() {
      if (this.productCategory == "pre-op") {
        this.indicationType = "pre-op";
        this.indicationTypeSelected();
      }
      this.step = 1;
    },

    skipPreop(ordDetailId) {
      this.patchPreOpOrder(ordDetailId);
    },

    async selectRestoration(selected) {
      console.log("selectRestoration:", selected);
    },

    async patchPreOpOrder(immediateDetailID) {
      await this.$httpClient
        .updatePatientOrderStatus(this.orderId, this.detailId, "Skipped")
        .then(response => {
          console.log(
            "Updating PreOp Order Status successful response:",
            response
          );
          this.$router.push({
            name: "customise-order",
            params: { type: this.indicationType },
            query: {
              intended: "immediate-restoration-order",
              id: this.orderId,
              odid: immediateDetailID,
              product: this.productCategory
            }
          });
        })
        .catch(error => {
          console.log("Updating PreOp Order Status failed. Error:", error);
        });
    }
  },

  async mounted() {
    this.loading = false;
    this.orderId = this.$route.query.id;
    this.detailId = this.$route.query.odid;
    this.immediateId = this.$route.query.imdid;
    this.category = this.$route.query.cat ? this.$route.query.cat : "pre-op";
  }
};
</script>

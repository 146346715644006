<template>
  <v-btn-toggle
    v-model="tint"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="tintSelected"
  >
    <v-btn
      class="ma-1 gold"
      value="gold"
      :disabled="disabled"
      v-if="options.includes('gold')"
    >
      {{ $t("productOptions.tintings.gold") }}
    </v-btn>
    <v-btn
      class="ma-1 pink"
      value="pink"
      :disabled="disabled"
      v-if="options.includes('pink')"
    >
      {{ $t("productOptions.tintings.pink") }}
    </v-btn>
    <v-btn class="ma-1 none" value="none" :disabled="disabled">
      {{ $t("common.none") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "BarTintingSelections",

  props: ["value", "options", "disabled", "barMaterial"],

  data() {
    return {
      tint: this.value
    };
  },

  methods: {
    tintSelected() {
      this.$emit("input", this.tint);
    }
  }
};
</script>

<template>
  <v-container>
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-container fluid fill-height v-if="!loading">
      <v-container>
        <v-alert type="success" v-if="success">
          {{ $t("nexusios.contact.thankYou") }}
          <!-- Thank you, we have received your message and will be in touch shortly. -->
        </v-alert>
        <v-alert type="error" v-if="error">
          {{ $t("errors.unexpectedError") }}
          <!-- An unexpected error has occurred. -->
        </v-alert>
      </v-container>
      <v-row>
        <v-col cols="12" md="6">
          <h1 class="ml-5">
            {{ $t("common.contactUs") }}
          </h1>
          <v-container class="mt-5">
            <v-row>
              <v-col cols="6">
                <p>
                  {{ $t("nexusios.contact.withinAustralia") }}:
                  <!-- Within Australia: -->
                </p>
                <v-icon>mdi-phone</v-icon> 1300 16 26 96
              </v-col>
              <v-col cols="6">
                <p>
                  {{ $t("nexusios.contact.outsideAustralia") }}:
                  <!-- Outside Australia: -->
                </p>
                <v-icon>mdi-phone</v-icon> +61 3 9264 0111
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="5" align-self="center">
          <v-form ref="contactForm">
            <v-text-field
              v-model="message.name"
              :label="$t('details.name')"
              solo
              light
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="message.email"
              :label="$t('details.emailAddress')"
              solo
              light
              :rules="[rules.required, rules.email]"
            ></v-text-field>
            <v-select
              v-model="message.country"
              :label="$t('details.country')"
              solo
              light
              :items="countries"
              :rules="[rules.required]"
            >
            </v-select>
            <v-select
              solo
              light
              v-model="message.enquiry_type"
              :items="enquiryType"
              :label="$t('details.enquiryType')"
              :rules="[rules.required]"
            >
            </v-select>
            <v-textarea
              v-model="message.message"
              :label="$t('common.message')"
              solo
              light
              :rules="[rules.required]"
            ></v-textarea>
            <v-btn large class="m" color="primary" @click="submit">
              {{ $t("common.sendMessage") }}
              <!-- Send Message -->
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { getNames } from "country-list";

export default {
  name: "Contact",

  computed: {
    countries() {
      return getNames().sort();
    },

    enquiryType() {
      return [
        {
          text: this.$t("details.enquiryTypeList.scanGauge"),
          value: "scan_gauge_order"
        },
        {
          text: this.$t("details.enquiryTypeList.distribution"),
          value: "distribution_enquiry"
        },
        {
          text: this.$t("details.enquiryTypeList.sampleDemo"),
          value: "request_sample_demo_kit"
        },
        {
          text: this.$t("details.enquiryTypeList.technical"),
          value: "technical_enquiry"
        },
        {
          text: this.$t("details.enquiryTypeList.general"),
          value: "general_enquiry"
        }
      ];
    },

    rules() {
      return {
        required: value => !!value || this.$t("common.required"),
        email: value => {
          if (value) {
            return /.+@.+/.test(value) || this.$t("common.requiredEmail");
          }
          return true;
        }
      };
    }
  },

  data: () => ({
    loading: false,
    error: false,
    success: false,
    message: {}
  }),

  created: function() {
    this.setEnquiryType();
  },

  methods: {
    async submit() {
      if (this.$refs.contactForm.validate()) {
        this.loading = true;
        await this.$httpClient
          .createMessage(this.message)
          .then(() => {
            this.message = Object.assign({});
            this.loading = false;
            this.success = true;
          })
          .catch(() => {
            this.loading = false;
            this.error = false;
          });
      }
    },

    setEnquiryType() {
      if (this.$route.query.type) {
        this.message = {
          enquiry_type: this.$route.query.type
        };
      }
    }
  }
};
</script>

<template>
  <div class="col-12 pa-0">
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :color="snackbarColor"
      centered
      top
    >
      {{ snackbarMessage }}
    </v-snackbar>
    <v-dialog v-model="newPatientdialog" max-width="600">
      <v-card>
        <v-toolbar color="secondary">
          <v-toolbar-title>{{
            $t("nexusios.patientOrder.newPatientOrder")
          }}</v-toolbar-title>
        </v-toolbar>
        <v-form ref="patientForm" class="mt-6 mx-6">
          <v-select
            solo
            light
            v-model="practice_id"
            :label="$t('nexusios.account.scanGaugeKits.selectAPractice')"
            :items="practiceNames"
            :rules="[rules.required]"
          ></v-select>
          <v-text-field
            v-model="patientName"
            :label="$t('details.patientName')"
            solo
            light
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-if="select_intended_restoration === 'immediate-restoration-order'"
            v-model="restorativeClinician"
            hide-details
            label="Name of Future Clinician (Optional)"
            solo
            light
          ></v-text-field>
          <p
            class="mt-1"
            v-if="select_intended_restoration === 'immediate-restoration-order'"
          >
            <small>
              Leave blank if you will be performing the final restoration.
            </small>
          </p>
        </v-form>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="closeNewPatientDialog">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn
            color="success"
            dark
            @click="selectRestoration(select_intended_restoration)"
            :disabled="!isValidForm"
          >
            {{ $t("nexusios.patientOrder.continue") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card tile class="my-6">
      <v-toolbar color="secondary">
        <v-toolbar-title>
          {{ $t("nexusios.patientOrder.intendedRestoration") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-row class="pa-4" justify="center">
        <v-col sm="8" md="4" lg="3" class="text-left">
          <v-card
            color="black"
            tile
            elevation="16"
            height="100%"
            class="d-flex flex-column"
          >
            <v-img
              max-height="240px"
              min-height="240px"
              src="../../../assets/indication-renders/nios-neworder-immediate.jpg"
            >
            </v-img>
            <v-card-text>
              <!-- <strong><b>Immediately Loaded Full Arch Restoration</b></strong> -->
              <h3>
                <strong
                  ><b>{{
                    $t("nexusios.patientOrder.product.immediateRestoration")
                  }}</b></strong
                >
              </h3>
              <br />
              <b>
                {{
                  $t(
                    "nexusios.patientOrder.immediateRestorationFeatures.orderVirtualSmileDesign"
                  )
                }}<br />
                {{
                  $t(
                    "nexusios.patientOrder.immediateRestorationFeatures.bookSurgeryDate"
                  )
                }}<br />
                {{
                  $t(
                    "nexusios.patientOrder.immediateRestorationFeatures.selectFutureRestorationType"
                  )
                }}
              </b>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="align-right pa-4">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                @click="
                  selectIntendedRestoration('immediate-restoration-order')
                "
              >
                {{ $t("nexusios.patientOrder.select") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="8" md="4" lg="3" class="text-left">
          <v-card
            tile
            elevation="16"
            color="black"
            height="100%"
            class="d-flex flex-column"
          >
            <v-img
              max-height="240px"
              min-height="240px"
              src="../../../assets/indication-renders/nios-neworder-definitive.jpg"
            ></v-img>
            <v-card-text>
              <h3>
                <strong
                  ><b>{{
                    $t("nexusios.patientOrder.product.fullArch")
                  }}</b></strong
                >
              </h3>
              <br />
              <b>
                {{
                  $t(
                    "nexusios.patientOrder.fullArchFeatures.fixedDefinitiveSolutions"
                  )
                }}<br />
                {{
                  $t(
                    "nexusios.patientOrder.fullArchFeatures.removableDefinitiveSolutions"
                  )
                }}
              </b>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="align-right pa-4">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                @click="selectIntendedRestoration('full-arch-order')"
              >
                {{ $t("nexusios.patientOrder.select") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col sm="8" md="4" lg="3" class="text-left">
          <v-card
            tile
            elevation="16"
            color="black"
            height="100%"
            class="d-flex flex-column"
          >
            <v-img
              max-height="240px"
              min-height="240px"
              src="../../../assets/indication-renders/nios-neworder-single-bridge.jpg"
            ></v-img>
            <v-card-text>
              <h3>
                <strong
                  ><b>{{
                    $t("nexusios.patientOrder.product.singleBridge")
                  }}</b></strong
                >
              </h3>
              <br />
              <b>
                {{
                  $t(
                    "nexusios.patientOrder.singleBridgeFeatures.singleUnitSolutions"
                  )
                }}<br />
                {{
                  $t(
                    "nexusios.patientOrder.singleBridgeFeatures.bridgeSolutions"
                  )
                }}
              </b>
              <h6 style="color:#777777;line-height:1.4;">
                {{
                  $t("nexusios.patientOrder.singleBridgeFeatures.noticeMessage")
                }}
              </h6>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="align-right pa-4">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                @click="selectIntendedRestoration('single-bridge-order')"
              >
                {{ $t("nexusios.patientOrder.select") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import { validationRequired } from "../../../shared/ValidationRules";

export default {
  name: "NewPatient",

  props: ["approvedClient", "user"],

  data() {
    return {
      newPatientdialog: false,
      snackbar: false,
      snackbarColor: "success",
      snackbarMessage: "",
      restorativeClinician: "",
      patientName: "",
      practice_id: "",
      scanGaugeKitId: "",
      select_intended_restoration: "",
      localTimeZone: process.env.VUE_APP_BASE_TIMEZONE,
      rules: {
        required: validationRequired
      },
      headers: [
        { text: this.$t("common.serial#"), value: "serial_number" },
        { text: this.$t("common.dateOrdered"), value: "created_at" }
      ]
    };
  },

  computed: {
    ...mapGetters(["kits", "practices"]),

    practiceNames() {
      if (this.practices) {
        return this.practices.map(practice => {
          return { text: practice.business_name, value: practice.id };
        });
      }
      return { text: null, value: null };
    },

    isValidForm() {
      return this.practice_id && this.patientName;
    },

    isSinglebridgeValidForm() {
      return this.practice_id && this.patientName;
    },

    kitSerialNumbers() {
      let serialNumbers = [];
      if (this.kits) {
        serialNumbers = this.kits.map(kit => {
          return { text: kit.serial_number, value: kit.serial_number };
        });
      }
      serialNumbers.push({ text: "Other", value: "other" });
      return serialNumbers;
    }
  },

  methods: {
    selectIntendedRestoration(restoration) {
      this.select_intended_restoration = restoration;
      this.newPatientdialog = true;
    },

    closeNewPatientDialog() {
      this.patientName = "";
      this.practice_id = "";
      this.newPatientdialog = false;
    },

    prettyDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
    },

    popErrorMessage(message) {
      this.snackbar = true;
      this.snackbarColor = "error";
      this.snackbarMessage = message;
    },

    async createOrder(selected, payload) {
      await this.$httpClient
        .createPatientOrder(payload)
        .then(data => {
          this.$router.push({
            name: selected,
            query: { id: data["order_id"], odid: data["detail_id"] }
          });
        })
        .catch(error => {
          console.log("Creating Order Details auto-save failed. Error:", error);
          this.popErrorMessage(
            "An unexpected error has occurred, please contact our admin or try again later."
          );
        });

      setTimeout(() => {
        this.closeNewPatientDialog();
      }, 3000);
    },

    async createImmediateOrder(selected, payload) {
      await this.$httpClient
        .createPatientImmediateOrder(payload)
        .then(data => {
          this.$router.push({
            name: selected,
            query: {
              id: data["order_id"],
              odid: data["detail_id"],
              imdid: data["immediate_id"]
            }
          });
        })
        .catch(error => {
          console.log(
            "Creating Immediate Order Details auto-save failed. Error:",
            error
          );
          this.popErrorMessage(
            "An unexpected error has occurred, please contact our admin or try again later."
          );
        });

      setTimeout(() => {
        this.closeNewPatientDialog();
      }, 3000);
    },

    async selectRestoration(selected) {
      let payload = {
        client_id: this.approvedClient.id,
        practice_id: this.practice_id,
        patient_name: this.patientName,
        restorative_clinician: this.restorativeClinician,
        intended: selected,
        scan_gauge_kit_id: this.scanGaugeKitId,
        order_date: new Date(),
        created_by: this.user.email,
        updated_by: this.user.email
      };
      if (selected == "immediate-restoration-order") {
        this.createImmediateOrder(selected, payload);
      } else {
        this.createOrder(selected, payload);
      }
    }
  }
};
</script>

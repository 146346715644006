<template>
  <v-snackbar
    v-model="snackbar"
    right
    timeout="-1"
    transition="slide-x-reverse-transition"
    color="primary">
    <v-icon>mdi-information</v-icon>
    Preview the new Nexus IOS ordering platform
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="openNewVersion()">
        Preview
      </v-btn>
      <v-btn icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "NewVersionSnackbar",
  data () {
    return {
      snackbar: false
    };
  },
  methods: {
    openNewVersion () {
      window.open("https://portal-v2.nexusios.com/");
    },
    showSnackBar () {
      this.snackbar = true;
    }
  },
  mounted () {
    if (
      localStorage.snackbarDate &&
      new Date(localStorage.snackbarDate).setHours(0, 0, 0, 0) >=
        new Date().setHours(0, 0, 0, 0)
    ) {
      return;
    }
    setTimeout(this.showSnackBar, 1000);
    localStorage.snackbarDate = new Date();
  }
};
</script>

<template>
  <div>
    <v-row justify="center">
      <template v-for="(item, i) in indicationTypes">
        <v-col
          :key="i"
          cols="12"
          :md="item.wide ? 6 : 3"
          :sm="item.wide ? 12 : 6"
          class="text-center white--text ma-0"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pa-4"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              style="background-color:rgba(200,200,200,0)"
              @click="enabled && $emit('input', item.value)"
            >
              <v-img
                :src="require('../../assets/indication-renders/' + item.img)"
                class="mb-4"
                height="18em"
                max-height="48em"
                contain
              >
              </v-img>
              <h2>{{ item.name }}</h2>
              <p class="mt-1">{{ item.subDescription }}</p>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
    <div class="text-center" v-if="!enabled">
      <br />
      <h2>
        {{ $t("nexusios.indicationTypeSelector.comingSoon") }}
      </h2>
      <br />
      <h4>
        {{ $t("nexusios.indicationTypeSelector.ifYouWish") }}
        <a href="/contact">
          {{ $t("nexusios.indicationTypeSelector.contactForm") }}
        </a>
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndicationTypeSelector",

  props: ["value", "productCategory"],

  computed: {
    fixedItems() {
      return [
        {
          name: this.$t("products.nexusHybrid"),
          subDescription: this.$t("products.nexusHybridSubDescription"),
          value: "nexus-hybrid",
          img: "nio-indication-hybrid.png"
        },
        {
          name: this.$t("products.nexusBridge"),
          subDescription: this.$t("products.nexusBridgeSubDescription"),
          value: "nexus-bridge",
          img: "nio-indication-bridge.png"
        },
        {
          name: this.$t("products.nexusWraparound"),
          subDescription: this.$t("products.nexusWraparoundSubDescription"),
          value: "nexus-wraparound",
          img: "nio-indication-wrap-around.png"
        }
      ];
    },

    removableItems() {
      return [
        {
          name: this.$t("products.nexusRemovable"),
          subDescription: this.$t("products.nexusRemovableSubDescription"),
          value: "nexus-removable",
          img: "nio-indication-removable.png"
        },
        {
          name: this.$t("products.nexusHRA"),
          subDescription: this.$t("products.nexusHRASubDescription"),
          value: "nexus-hra",
          img: "nio-indication-hra.png"
        }
      ];
    },
    immediateItems() {
      return [
        {
          name: this.$t("products.designOnly"),
          subDescription: this.$t("products.designOnlySubDescription"),
          value: "design-only-immediate",
          img: "design-only-immediate.png"
        },
        {
          name: this.$t("products.bridgeOnTiBaseImmediate"),
          subDescription: this.$t(
            "products.bridgeOnTiBaseImmediateSubDescription"
          ),
          value: "bridge-on-ti-base-immediate",
          img: "nio-indication-ti-base.png"
        },
        {
          name: this.$t("products.titaniumReinforcedImmediate"),
          subDescription: this.$t(
            "products.titaniumReinforcedImmediateSubDescription"
          ),
          value: "nexus-wraparound-immediate",
          img: "nio-indication-bridge.png"
        }
      ];
    },
    singleBridgeItems() {
      return [
        {
          name: this.$t("products.nexusSingleImplantCrown"),
          subDescription: this.$t(
            "products.nexusSingleImplantCrownSubDescription"
          ),
          value: "nexus-single-implant-crown",
          img: "nio-indication-su.png"
        },
        {
          name: this.$t("products.nexusMicroUnitBridge"),
          subDescription: this.$t(
            "products.nexusMicroUnitBridgeSubDescription"
          ),
          value: "nexus-micro-unit-bridge",
          img: "nio-indication-bridge2.png"
        },
        {
          name: this.$t("products.customAbutmentBridge"),
          subDescription: this.$t(
            "products.customAbutmentBridgeSubDescription"
          ),
          value: "custom-abutment-bridge",
          img: "custom-abutment-bridge.png"
        }
      ];
    },
    preOpItems() {
      return [
        {
          name: this.$t("productCategories.preOp"),
          value: "pre-op",
          img: "nio-indication-smiledesign.png"
        }
      ];
    },

    indicationTypes() {
      switch (this.productCategory) {
        case "fixed":
          return this.fixedItems;
        case "removable":
          return this.removableItems;
        case "immediate":
          return this.immediateItems;
        case "single-bridge":
          return this.singleBridgeItems;
        case "pre-op":
          return this.preOpItems;
        default:
          return [];
      }
    },
    enabled() {
      switch (this.productCategory) {
        case "fixed":
          return true;
        case "removable":
          return true;
        case "immediate":
          return true;
        case "single-bridge":
          return true;
        case "pre-op":
          return true;
        default:
          return [];
      }
    }
  },

  data() {
    return {};
  }
};
</script>

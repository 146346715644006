<template>
  <v-container flex>
    <v-row>
      <v-col md="12">
        <v-img :src="require('../../assets/vsd-hero.png')" contain eager />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PreopPreviewImage",
  props: ["value", "options"],

  methods: {
    arches(type) {
      return this.value.archesRestored && this.value.archesRestored === type;
    },

    archesRestoredSelected() {
      return Boolean(this.value.archesRestored);
    },

    virtualSmileDesignSelected() {
      return Boolean(this.value.virtualSmileDesign);
    },

    surgicalScanTemplateSelected() {
      return Boolean(
        this.value.surgicalScanTemplate &&
          this.value.surgicalScanTemplate === "no"
      );
    },

    conversionDentureSelected() {
      return Boolean(
        this.value.restorativeProtocol &&
          this.value.restorativeProtocol === "conversion-denture"
      );
    },

    restorativeProtocolSelected() {
      return Boolean(this.value.restorativeProtocol);
    },

    rentScanGaugeKitSelected() {
      return Boolean(
        this.value.rentScanGaugeKit && this.value.rentScanGaugeKit === "yes"
      );
    },

    interimDentureSelected() {
      return Boolean(
        this.value.interimDenture && this.value.interimDenture === "yes"
      );
    }
  },

  computed: {
    archesRestoredImage() {
      if (this.arches("upper")) {
        return require("../../assets/design-preop/preop-model-upper.png");
      } else if (this.arches("lower")) {
        return require("../../assets/design-preop/preop-model-lower.png");
      } else if (this.arches("full-full")) {
        return require("../../assets/design-preop/preop-model-ff.png");
      }

      return "";
    },

    surgicalScanTemplateImage() {
      if (this.surgicalScanTemplateSelected()) {
        if (this.arches("upper")) {
          return require("../../assets/design-preop/preop-sst-upper.png");
        } else if (this.arches("lower")) {
          return require("../../assets/design-preop/preop-sst-lower.png");
        } else if (this.arches("full-full")) {
          return require("../../assets/design-preop/preop-sst-ff.png");
        }
      }

      return "";
    },

    virtualSmileDesignImage() {
      if (this.virtualSmileDesignSelected()) {
        return require("../../assets/design-preop/preop-nios-vsd.png");
      }

      return "";
    },

    restorativeProtocolImage() {
      if (this.value.restorativeProtocol) {
        if (this.value.restorativeProtocol === "conversion-denture") {
          if (this.arches("upper")) {
            return require("../../assets/design-preop/preop-conversion-upper.png");
          } else if (this.arches("lower")) {
            return require("../../assets/design-preop/preop-conversion-lower.png");
          } else if (this.arches("full-full")) {
            return require("../../assets/design-preop/preop-conversion-ff.png");
          }
        } else if (
          this.value.restorativeProtocol === "titanium-reinforced-immediate-bar"
        ) {
          if (this.arches("upper")) {
            return require("../../assets/design-preop/ti-reinforced-immediate-upper.png");
          } else if (this.arches("lower")) {
            return require("../../assets/design-preop/ti-reinforced-immediate-lower.png");
          } else if (this.arches("full-full")) {
            return require("../../assets/design-preop/ti-reinforced-immediate-ff.png");
          }
        } else if (
          this.value.restorativeProtocol === "peek-reinforced-immediate-bar"
        ) {
          if (this.arches("upper")) {
            return require("../../assets/design-preop/peek-reinforced-immediate-upper.png");
          } else if (this.arches("lower")) {
            return require("../../assets/design-preop/peek-reinforced-immediate-lower.png");
          } else if (this.arches("full-full")) {
            return require("../../assets/design-preop/peek-reinforced-immediate-ff.png");
          }
        } else if (
          this.value.restorativeProtocol === "acrylic-bridge-on-ti-base"
        ) {
          if (this.arches("upper")) {
            return require("../../assets/design-preop/ti-base-upper.png");
          } else if (this.arches("lower")) {
            return require("../../assets/design-preop/ti-base-lower.png");
          } else if (this.arches("full-full")) {
            return require("../../assets/design-preop/ti-base-ff.png");
          }
        }
      }
      return "";
    },

    interimDentureImage() {
      if (this.interimDentureSelected()) {
        if (this.arches("upper")) {
          return require("../../assets/design-preop/preop-denture-upper.png");
        } else if (this.arches("lower")) {
          return require("../../assets/design-preop/preop-denture-lower.png");
        } else if (this.arches("full-full")) {
          return require("../../assets/design-preop/preop-denture-ff.png");
        }
      }

      return "";
    },

    rentScanGaugeKitImage() {
      if (this.rentScanGaugeKitSelected()) {
        return require("../../assets/design-preop/preop-sg-kit.png");
      }
      return "";
    }
  }
};
</script>

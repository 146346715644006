import Vue from "vue";
import Vuex from "vuex";
import HTTPClient from "./shared/HTTPClient";

Vue.use(Vuex);

const httpClient = new HTTPClient();

export default new Vuex.Store({
  state: {
    user: null,
    currentLocale: {
      locale: "en",
      title: "nexusios.LocaleSwitcher.english",
      flag: "au.png",
      wordBreak: true
    },
    cases: null,
    selectedCaseId: null,
    caseDetails: null,
    caseNotes: {},
    fetched: false
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
      state.fetched = true;
    },

    clearUser(state) {
      state.user = null;
      state.fetched = true;
    },

    setCurrentLocale(state, locale) {
      state.currentLocale = locale;
    },

    setCases(state, cases) {
      state.cases = cases;
      state.fetched = true;
    },

    clearCases(state) {
      state.cases = null;
      state.fetched = true;
    },

    setCaseDetails(state, caseDetails) {
      state.caseDetails = caseDetails;
      state.fetched = true;
    },

    clearCaseDetails(state) {
      state.caseDetails = null;
      state.fetched = true;
    },

    setCaseNotes(state, caseNotes) {
      state.caseNotes = caseNotes;
      state.fetched = true;
    },

    clearCaseNotes(state) {
      state.caseNotes = null;
      state.fetched = true;
    }
  },

  getters: {
    currentLocale: state => {
      return state.currentLocale;
    },

    user: state => {
      return state.user ? state.user : false;
    },

    client: state => {
      return state.user && state.user.client ? state.user.client : false;
    },

    cases: state => {
      return state.user && state.cases ? state.cases : false;
    },

    approvedClient: state => {
      return state.user && state.user.client && state.user.client.approved_at
        ? state.user.client
        : false;
    },

    practices: state => {
      if (state.user && state.user.practices) {
        return state.user.practices;
      }
    },

    orders: state => {
      if (state.user && state.user.cases) {
        return state.user.cases;
      }
    },

    orders_done: state => {
      if (state.user && state.user.cases_done) {
        return state.user.cases_done;
      }
    },

    orderDetails: state => {
      if (state.user && state.user.caseDetails) {
        return state.user.caseDetails;
      }
    },

    caseDetails: state => {
      if (state.user && state.caseDetails) {
        return state.caseDetails;
      }
    },

    caseNotes: state => {
      if (state.user && state.caseNotes) {
        return state.caseNotes;
      }
    },

    kitPriceAUD: state => {
      if (state.user) {
        return state.user.scan_gauge_kit_price_aud;
      }
    },

    kits: state => {
      if (state.user && state.user.scan_gauge_kits) {
        return state.user.scan_gauge_kits;
      }
    }
  },

  actions: {
    async changeUserLocale({ commit }, lang) {
      await httpClient
        .changeLocale(lang)
        .then(result => {
          commit("setUser", result);
        })
        .catch(() => {
          commit("clearUser");
        });
    },

    async fetchUser({ commit }) {
      await httpClient
        .fetchMe()
        .then(user => {
          commit("setUser", user);
        })
        .catch(() => {
          commit("clearUser");
        });
    },

    async fetchCases({ commit }, caseId) {
      await httpClient
        .fetchCases(caseId)
        .then(caseDetails => {
          commit("setCaseDetails", caseDetails);
        })
        .catch(() => {
          commit("clearCaseDetails");
        });
    },

    async fetchNotes({ commit }, caseId) {
      console.log("FETCH NOTES", this.caseDetails);
      // for (const order of this.caseDetails) {
      //   console.log(order)
      // }
      await httpClient
        .fetchNotes(caseId)
        .then(caseNotes => {
          commit("setCaseNotes", caseNotes);
        })
        .catch(() => {
          commit("clearCaseNotes");
        });
    },

    async logout({ commit }) {
      await httpClient
        .logout()
        .then(response => {
          commit("clearUser");
          document.location = response.logout_path;
        })
        .catch(() => {
          commit("clearUser");
          document.location = "/";
        });
    },

    setCurrentLocale({ commit }, locale) {
      commit("setCurrentLocale", locale);
    }
  }
});

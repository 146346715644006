<template>
  <div>
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-container v-if="!loading">
      <v-alert type="success" v-if="success">
        {{ $t("nexusios.account.scanGaugeKits.thankYouOrderRecieved") }}
        <!--         Thank you, your order has been received, a confirmation email has been
  sent to you. -->
      </v-alert>
      <v-alert type="error" v-if="error">
        {{ $t("errors.unexpectedErrorContactUs") }}
        <!-- An unexpected error has occurred, please contact us for assistance. -->
      </v-alert>
    </v-container>
    <v-card v-if="!loading">
      <v-toolbar color="secondary">
        <v-toolbar-title>
          {{ $t("nexusios.account.scanGaugeKits.existingKits") }}
          <!-- Existing Scan Gauge Kits -->
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="kits"
        :items-per-page="5"
        class="my-5"
      >
        <template v-slot:item.practice_id="{ item }">
          {{ getPracticeName(item.practice_id) }}
        </template>
        <template v-slot:item.serial_number="{ item }">
          {{ item.serial_number || $t("common.pending") }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { validationRequired } from "../../shared/ValidationRules";
import { mapGetters, mapActions } from "vuex";
import numeral from "numeral";
import { DateTime } from "luxon";

export default {
  name: "ScanGaugeKits",

  computed: {
    headers() {
      return [
        { text: this.$t("common.practice"), value: "practice_id" },
        { text: this.$t("common.serial#"), value: "serial_number" }
      ];
    },
    ...mapGetters(["kits", "practices", "kitPriceAUD"]),

    kitPriceAUDFormatted() {
      return numeral(this.kitPriceAUD).format("$0,0.00");
    },

    practiceNames() {
      return this.practices.map(practice => {
        return { text: practice.business_name, value: practice.id };
      });
    },

    selectedPractice() {
      if (this.scanGaugeOrder.practice_id) {
        return this.practices.filter(practice => {
          return practice.id === this.scanGaugeOrder.practice_id;
        })[0];
      }
      return false;
    },

    practiceDetailsOK() {
      // TODO - use javascript optional chaining
      // need to ensure full mobile support by looking at
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining

      let ok =
        Boolean(
          this.selectedPractice.billing_address_1 &&
            this.selectedPractice.billing_address_1.length > 0
        ) &&
        Boolean(
          this.selectedPractice.billing_address_suburb &&
            this.selectedPractice.billing_address_suburb.length > 0
        ) &&
        Boolean(
          this.selectedPractice.billing_address_postcode &&
            this.selectedPractice.billing_address_postcode.length > 0
        ) &&
        Boolean(
          this.selectedPractice.billing_address_state &&
            this.selectedPractice.billing_address_state.length > 0
        ) &&
        Boolean(
          this.selectedPractice.billing_address_country &&
            this.selectedPractice.billing_address_country.length > 0
        ) &&
        Boolean(
          this.selectedPractice.shipping_address_1 &&
            this.selectedPractice.shipping_address_1.length > 0
        ) &&
        Boolean(
          this.selectedPractice.shipping_address_suburb &&
            this.selectedPractice.shipping_address_suburb.length > 0
        ) &&
        Boolean(
          this.selectedPractice.shipping_address_postcode &&
            this.selectedPractice.shipping_address_postcode.length > 0
        ) &&
        Boolean(
          this.selectedPractice.shipping_address_state &&
            this.selectedPractice.shipping_address_state.length > 0
        ) &&
        Boolean(
          this.selectedPractice.shipping_address_country &&
            this.selectedPractice.shipping_address_country.length > 0
        );

      return ok;
    }
  },

  data() {
    return {
      loading: true,
      hideOrder: false,
      success: false,
      error: false,
      scanGaugeOrder: {},

      rules: {
        required: validationRequired
      },

      credentials: {
        sandbox: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENT_ID,
        production: process.env.VUE_APP_PAYPAL_PROD_CLIENT_ID
      }
    };
  },

  methods: {
    ...mapActions(["fetchUser"]),

    getPracticeName(id) {
      return this.practices.filter(practice => practice.id === id)[0]
        .business_name;
    },

    prettyDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
    },

    orderStatus(order) {
      if (order.shipped_at && order.paid_at) {
        return "Shipped";
      } else if (order.paid_at) {
        return "Paid - Awaiting Shipment";
      }
    },

    async submit(payment) {
      this.loading = true;
      await this.$httpClient
        .createKitOrder({
          practice_id: this.scanGaugeOrder.practice_id,
          shipping_address_1: this.selectedPractice.shipping_address_1,
          shipping_address_2: this.selectedPractice.shipping_address_2,
          shipping_address_suburb: this.selectedPractice
            .shipping_address_suburb,
          shipping_address_state: this.selectedPractice.shipping_address_state,
          shipping_address_postcode: this.selectedPractice
            .shipping_address_postcode,
          shipping_address_country: this.selectedPractice
            .shipping_address_country,
          price_aud: this.kitPriceAUD,
          payment_method: "paypal",
          payment_transaction_id: payment.id,
          paid_at: payment.create_time
        })
        .then(async () => {
          this.hideOrder = true;
          this.success = true;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    }
  },

  mounted() {
    this.loading = false;
  }
};
</script>

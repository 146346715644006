<template>
  <v-card
    v-if="
      item.preop_status != 'Deleted' ||
        (item.definitive_status != 'Deleted' && item.preop_status)
    "
    class="d-flex flex-column"
    color="black"
    elevation="0"
  >
    <v-timeline align-top dense>
      <v-timeline-item
        color="black"
        v-if="item.intended == 'immediate-restoration-order'"
      >
        <template v-slot:icon>
          <v-avatar>
            <v-icon
              v-if="
                item.preop_status == 'Ordered' ||
                  item.preop_status == 'Pending' ||
                  item.preop_status == 'Complete' ||
                  item.preop_status == 'Dispatched'
              "
              color="green"
            >
              mdi-check
            </v-icon>
            <v-icon v-if="item.preop_status == 'Skipped'" color="grey"
              >mdi-minus-thick</v-icon
            >
            <v-icon v-if="item.preop_status == 'Incomplete'" color="red"
              >mdi-help</v-icon
            >
            <v-icon
              v-if="
                item.preop_status == 'Needs Approval' ||
                  item.preop_status == 'Missing Information'
              "
              color="yellow"
              >mdi-exclamation-thick</v-icon
            >
          </v-avatar></template
        >

        <v-list-item class="mx-0 px-0">
          <v-list-item-content>
            <v-list-item-title
              class="overline font-weight-bold"
              v-bind:class="setTextColor(item.preop_status)"
            >
              {{
                item.preop_status == "Skipped"
                  ? $t("nexusios.patientOrder.status.skippedPreOp")
                  : $t(
                      `nexusios.patientOrder.status.${i18n(item.preop_status)}`
                    )
              }}
            </v-list-item-title>
            <v-list-item-title class="text-h5">
              {{ $t("productCategories.preOp") | capitalizeAll }}
            </v-list-item-title>
          </v-list-item-content>
          <v-tooltip bottom v-if="preop.oep_case_id">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="primary"
                @click="openApprovalFiles(item, preop)"
                v-if="!isEnglish"
                v-on="on"
              >
                <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                {{ $t("common.open") }}
              </v-btn>
            </template>
            <span>{{ $t("nexusios.patientOrder.approvalFiles") }}</span>
          </v-tooltip>
          <v-btn
            small
            color="primary"
            v-if="isEnglish && preopLink"
            @click="handover(preopLink)"
          >
            <v-icon small class="mr-2">mdi-open-in-new</v-icon>
            {{ $t("common.open") }}
          </v-btn>
          <v-tooltip
            bottom
            v-if="isDelete && item.preop_status == 'Incomplete'"
          >
            <template v-slot:activator="{ on }">
              <v-icon class="mx-4" color="white" @click="deleteOrder" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t("nexusios.patientOrder.delete") }}</span>
          </v-tooltip>
        </v-list-item>
      </v-timeline-item>

      <v-timeline-item
        color="black"
        v-if="item.intended == 'immediate-restoration-order'"
      >
        <template v-slot:icon>
          <v-avatar>
            <v-icon
              v-if="
                item.immediate_status == 'Ordered' ||
                  item.immediate_status == 'Pending' ||
                  item.immediate_status == 'Complete' ||
                  item.immediate_status == 'Dispatched'
              "
              color="green"
            >
              mdi-check
            </v-icon>
            <v-icon v-if="item.immediate_status == 'Incomplete'" color="red"
              >mdi-help</v-icon
            >
            <v-icon
              v-if="
                item.immediate_status == 'Needs Approval' ||
                  item.immediate_status == 'Missing Information'
              "
              color="yellow"
              >mdi-exclamation-thick</v-icon
            >
          </v-avatar></template
        >

        <v-list-item class="mx-0 px-0" two-line>
          <v-list-item-content>
            <v-list-item-title
              class="overline font-weight-bold"
              v-bind:class="setTextColor(item.immediate_status)"
            >
              {{
                $t(
                  `nexusios.patientOrder.status.${i18n(item.immediate_status)}`
                )
              }}
            </v-list-item-title>

            <v-list-item-title class="text-h5">
              {{
                (this.immediate.product_category
                  ? $t("productCategories.immediate")
                  : "Immediate") | capitalizeAll
              }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                getCorrectIndication(this.immediate.indications) | capitalizeAll
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-tooltip bottom v-if="immediate.oep_case_id">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="primary"
                @click="openApprovalFiles(item, immediate)"
                v-if="!isEnglish"
                v-on="on"
              >
                <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                {{ $t("common.open") }}
              </v-btn>
            </template>
            <span>{{ $t("nexusios.patientOrder.approvalFiles") }}</span>
          </v-tooltip>
          <v-btn
            small
            color="primary"
            v-if="isEnglish && immediateLink"
            @click="handover(immediateLink)"
          >
            <v-icon small class="mr-2">mdi-open-in-new</v-icon>
            {{ $t("common.open") }}
          </v-btn>
          <v-tooltip
            bottom
            v-if="isDelete && item.immediate_status == 'Incomplete'"
          >
            <template v-slot:activator="{ on }">
              <v-icon class="mx-4" color="white" @click="deleteOrder" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t("nexusios.patientOrder.delete") }}</span>
          </v-tooltip>
        </v-list-item>
      </v-timeline-item>

      <v-timeline-item color="black">
        <template v-slot:icon>
          <v-avatar>
            <v-icon
              v-if="
                item.definitive_status == 'Ordered' ||
                  item.definitive_status == 'Pending' ||
                  item.definitive_status == 'Complete' ||
                  item.definitive_status == 'Dispatched'
              "
              color="green"
            >
              mdi-check
            </v-icon>
            <v-icon v-if="item.definitive_status == 'Incomplete'" color="red"
              >mdi-help</v-icon
            >
            <v-icon
              v-if="
                item.definitive_status == 'Needs Approval' ||
                  item.definitive_status == 'Missing Information'
              "
              color="yellow"
              >mdi-exclamation-thick</v-icon
            >
          </v-avatar></template
        >

        <v-list-item class="mx-0 px-0" two-line>
          <v-list-item-content>
            <v-list-item-title
              class="overline font-weight-bold"
              v-bind:class="setTextColor(item.definitive_status)"
            >
              {{
                $t(
                  `nexusios.patientOrder.status.${i18n(item.definitive_status)}`
                )
              }}
            </v-list-item-title>
            <v-list-item-title class="text-h5">
              {{
                (this.preop.product_category != "pre-op"
                  ? getCorrectCategory(this.preop.product_category)
                  : this.definitive.product_category &&
                    this.definitive.indications
                  ? getCorrectCategory(this.definitive.product_category)
                  : $t("productCategories.definitive")) | capitalizeAll
              }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                (this.preop.product_category != "pre-op"
                  ? getCorrectIndication(this.preop.indications)
                  : getCorrectIndication(this.definitive.indications))
                  | capitalizeAll
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-tooltip
            bottom
            v-if="
              item.product_category == 'immediate'
                ? definitive.oep_case_id
                : preop.product_category == 'pre-op'
                ? definitive.oep_case_id
                : preop.oep_case_id
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="primary"
                @click="
                  openApprovalFiles(
                    item,
                    item.intended == 'immediate-restoration-order'
                      ? definitive
                      : preop
                  )
                "
                v-if="!isEnglish"
                v-on="on"
              >
                <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                {{ $t("common.open") }}
              </v-btn>
            </template>
            <span>{{ $t("nexusios.patientOrder.approvalFiles") }}</span>
          </v-tooltip>
          <v-btn
            small
            color="primary"
            v-if="isEnglish && finalLink"
            @click="handover(finalLink)"
          >
            <v-icon small class="mr-2">mdi-open-in-new</v-icon>
            {{ $t("common.open") }}
          </v-btn>
          <v-tooltip
            bottom
            v-if="isDelete && item.definitive_status == 'Incomplete'"
          >
            <template v-slot:activator="{ on }">
              <v-icon class="mx-4" color="white" @click="deleteOrder" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t("nexusios.patientOrder.delete") }}</span>
          </v-tooltip>
        </v-list-item>
      </v-timeline-item>
    </v-timeline>
    <approval-file-tabs
      :tabDialog="approvalFileDialog"
      :caseNumber="caseNumber"
      :caseMKey="caseMKey"
      :patientName="patientName"
      :practiceName="practiceName"
      :caseBundleDetail="caseBundleData"
      v-on:childShow="closeApprovalFileDialog"
    >
    </approval-file-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApprovalFileTabs from "./ApprovalFileTabs.vue";

export default {
  name: "DetailsCard",

  props: ["item", "preop", "immediate", "definitive", "isContinue", "isDelete"],
  data() {
    return {
      loading: true,
      approvalFileDialog: false,
      caseNumber: String,
      caseMKey: String,
      patientName: String,
      practiceName: String,
      selectedItemOrderDetail: null,
      caseBundleData: []
    };
  },

  components: {
    "approval-file-tabs": ApprovalFileTabs
  },

  computed: {
    ...mapGetters(["currentLocale"]),
    isEnglish() {
      return this.currentLocale.locale === "en";
    },
    preopLink() {
      if (!this.item.practice_id) {
        return null;
      }
      if (
        this.preop &&
        this.preop.case_bundle_ids &&
        this.preop.case_bundle_ids.length > 0
      )
        return this.preop.portal_key;
      return null;
    },
    immediateLink() {
      if (!this.item.practice_id) {
        return null;
      }
      if (
        this.immediate &&
        this.immediate.case_bundle_ids &&
        this.immediate.case_bundle_ids.length > 0
      )
        return this.immediate.portal_key;
      return null;
    },
    definitiveLink() {
      if (!this.item.practice_id) {
        return null;
      }
      if (
        this.definitive &&
        this.definitive.case_bundle_ids &&
        this.definitive.case_bundle_ids.length > 0
      )
        return this.definitive.portal_key;
      return null;
    },
    finalLink() {
      if (this.item.intended == "immediate-restoration-order") {
        return this.definitiveLink;
      } else {
        return this.preopLink;
      }
    }
  },

  methods: {
    handover(key) {
      window.open(`${this.item.handover_uri}${key}`);
    },
    openApprovalFiles: async function(item, orderDetail) {
      this.caseNumber = orderDetail.oep_case_id;
      this.caseMKey = orderDetail.oep_case_mkey;
      this.patientName = item.patient_name;
      this.practiceName = item.practice_name;
      this.selectedItemOrderDetail = orderDetail;
      let caseBundles = [];
      const bundleIds = this.selectedItemOrderDetail
        ? this.selectedItemOrderDetail["case_bundle_ids"]
        : [];
      for (const bundle of bundleIds) {
        if (bundle.approval_email_count > 0 || bundle.notes_count > 0) {
          let bundleFiles = {
            caseNumber: bundle.case_id,
            caseMKey: bundle.case_mkey,
            patientName: this.patientName,
            practiceName: this.practiceName,
            caseStatus: bundle.case_status,
            jobType: bundle.job_type
          };
          caseBundles.push(bundleFiles);
        }
      }
      if (caseBundles.length == 0) {
        caseBundles.push({
          caseNumber: this.caseNumber,
          caseMKey: this.caseMKey,
          patientName: this.patientName,
          practiceName: this.practiceName,
          caseStatus: this.selectedItemOrderDetail.stage_status,
          jobType: this.selectedItemOrderDetail
            ? this.selectedItemOrderDetail.indications
            : "Design File"
        });
      }
      this.caseNumber = caseBundles[0].caseNumber;
      this.caseBundleData = caseBundles;
      this.approvalFileDialog = true;
    },

    deleteOrder() {
      this.$emit("deleteOrder");
    },

    closeApprovalFileDialog() {
      this.approvalFileDialog = false;
    },

    getCorrectCategory(category) {
      if (this.preop.intended == "single-bridge-order") {
        return this.preop.indications == null
          ? ""
          : this.preop.indications == "nexus-single-implant-crown"
          ? this.$t("productCategories.single")
          : this.$t("productCategories.bridge");
      }
      if (category == "fixed") {
        return this.$t("productCategories.fixed");
      }
      if (category == "removable") {
        return this.$t("productCategories.removable");
      }
      return category;
    },
    getCorrectIndication(indication) {
      if (!indication) {
        return this.$t("products.undefined");
      }
      if (indication == "bridge-on-ti-base") {
        return this.$t("products.bridgeOnTiBase");
      }
      if (indication == "bridge-on-ti-base-immediate") {
        return this.$t("products.bridgeOnTiBase");
      }
      if (indication == "custom-abutment-bridge") {
        return this.$t("products.customAbutmentBridge");
      }
      if (indication == "nexus-bridge") {
        return this.$t("products.nexusBridge");
      }
      if (indication == "nexus-hra") {
        return this.$t("products.nexusHRA");
      }
      if (indication == "nexus-hybrid") {
        return this.$t("products.nexusHybrid");
      }
      if (indication == "nexus-micro-unit-bridge") {
        return this.$t("products.nexusMicroUnitBridge");
      }
      if (indication == "nexus-reinforced") {
        return this.$t("products.nexusReinforced");
      }
      if (indication == "nexus-removable") {
        return this.$t("products.nexusRemovable");
      }
      if (indication == "nexus-single-implant-crown") {
        return this.$t("products.nexusSingleImplantCrown");
      }
      if (indication == "nexus-wraparoun") {
        return this.$t("products.nexusWraparound");
      }
      if (indication == "nexus-wraparound-immediate") {
        return this.$t("products.titaniumReinforcedImmediate");
      }
      return indication;
    },
    setTextColor(status) {
      var textColor = "";
      if (
        status == "Ordered" ||
        status == "Complete" ||
        status == "Dispatched"
      ) {
        textColor = "green--text";
      }
      if (
        status == "Pending" ||
        status == "Missing Information" ||
        status == "Needs Approval"
      ) {
        textColor = "yellow--text";
      }
      if (status == "Incomplete") {
        textColor = "red--text";
      }
      return textColor;
    },
    i18n(status) {
      if (!status) {
        return "undefined";
      }
      if (status == "Incomplete") {
        return "incomplete";
      }
      if (status == "Ordered") {
        return "ordered";
      }
      if (status == "Pending") {
        return "pending";
      }
      if (status == "Missing Information") {
        return "missingInfo";
      }
      if (status == "Review Try In") {
        return "reviewTryIn";
      }
      if (status == "Dispatch") {
        return "dispatch";
      }
      if (status == "Complete") {
        return "complete";
      }
    }
  }
};
</script>

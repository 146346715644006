<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" class="text-center">
        <ToothPicker
          :convention="internationalToothConvention"
          :selected="teeth"
          :customColors="customColors"
          @toothClicked="selectTooth"
        ></ToothPicker>
        <br />
        <v-btn-toggle v-model="internationalToothConvention" dense tile>
          <v-btn tile small value="fdi">FDI</v-btn>
          <v-btn tile small value="palmer">Pal.</v-btn>
          <v-btn tile small value="universal">Uni.</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" md="6">
        <v-data-table
          :headers="implantsHeaders"
          :items="value"
          hide-default-header
          hide-default-footer
          :no-data-text="$t('nexusios.orderNow.noImplants')"
          class="table"
        >
          <template v-slot:item.tooth="{ item }">
            {{ getToothNumber(item.tooth) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon class="red--text" @click="deleteItem(item)">
              mdi-minus-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="indicationType === 'nexus-wraparound-immediate'">
      <v-col>
        <v-alert
          dense
          border="left"
          colored-border
          type="warning"
          elevation="2"
          icon="mdi-information"
          class="pb-0"
        >
          <p style="font-size:11px">
            This product is not available in the US and Europe.
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="indicationType === 'pre-op'">
      <v-col>
        <v-alert
          dense
          border="left"
          colored-border
          type="warning"
          elevation="2"
          icon="mdi-information"
          class="pb-0"
        >
          <p style="font-size:11px">
            Nexus Provisionals can only be designed and manufactured on a range
            of Multi-Unit Abutments.<br />
            For further compatibility information
            <a
              href="https://www.nexusios.com/provisional-compatibility"
              target="_blank"
              >click here</a
            >.
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="indicationType !== 'pre-op'">
      <v-col>
        <v-alert
          dense
          border="left"
          colored-border
          type="warning"
          elevation="2"
          icon="mdi-information"
          class="pb-0"
          v-if="
            !$t('nexusios.orderNow.implantSelectionCaveat') ||
              $t('nexusios.orderNow.implantSelectionCaveat').length > 1
          "
        >
          <p style="font-size:11px">
            {{ $t("nexusios.orderNow.implantSelectionCaveat") }}
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog v-model="implantDialog" persistent max-width="400">
      <v-card class="dialog-card">
        <v-card-title class="headline">
          {{ $t("nexusios.orderNow.implantSelection") }}
          <!-- Implant Selection -->
        </v-card-title>
        <v-card-text>
          <v-btn x-large class="my-4" v-if="selectedTooth">
            {{ getToothNumber(selectedTooth) }}
          </v-btn>
          <v-autocomplete
            v-model="implantPlatform"
            :items="implantPlatforms"
            :label="$t('nexusios.orderNow.implantPlatform')"
            solo
            light
          ></v-autocomplete>
          <v-select
            v-model="scanBodyType"
            v-if="isSingleOrBridge"
            :items="scanBodyTypes"
            :label="$t('nexusios.orderNow.scanBodyType')"
            solo
            light
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile class="close-button" @click="closeDialog">
            {{ $t("common.close") }}
            <!-- Close -->
          </v-btn>
          <v-btn
            tile
            class="confirm-button"
            @click="confirmImplant"
            :disabled="!implantPlatform"
          >
            {{ $t("common.confirm") }}
            <!-- Confirm -->
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ToothPicker from "../ToothPicker";
import internationalToothNumbers from "@/shared/international_tooth_numbers";

export default {
  name: "ImplantSelection",
  props: ["value", "indicationType", "practiceId"],

  components: {
    ToothPicker
  },

  data() {
    return {
      implantDialog: false,
      selectedTooth: null,
      implantPlatform: null,
      scanBodyType: null,
      rawImplantPlatforms: [],
      implantsHeaders: [
        { text: "Tooth", value: "tooth" },
        { text: "Implant Platform", value: "name" },
        { text: "Actions", value: "action", sortable: false }
      ],
      scanBodyTypes: [
        { text: "Osteon", value: "osteon" },
        { text: "OEM (Manufacturer)", value: "oem" },
        { text: "Other", value: "other" }
      ],
      internationalToothConvention: "fdi"
    };
  },

  computed: {
    implantPlatforms() {
      let implantsArray = this.rawImplantPlatforms
        .filter(platform => this.allowedImplants.includes(platform.id))
        .map(platform => {
          return { value: platform.id, text: platform.name };
        });
      implantsArray.forEach((item, index) => {
        if (item.value == 262) {
          implantsArray.splice(0, 0, implantsArray.splice(index, 1)[0]);
        }
      });
      return implantsArray;
    },

    teeth() {
      return this.value.map(implant => implant.tooth);
    },

    allowedImplants() {
      let toTibase = false;
      let multiUnitAbutment = false;
      switch (this.indicationType) {
        case "nexus-micro-unit-bridge":
          return this.rawImplantPlatforms
            .filter(platform => !platform.to_tibase)
            .map(platform => platform.id);
        case "pre-op":
        case "design-only-immediate":
        case "nexus-wraparound-immediate":
        case "nexus-hybrid":
        case "nexus-bridge":
        case "bridge-on-ti-base":
        case "nexus-wraparound":
        case "nexus-removable":
          toTibase = false;
          multiUnitAbutment = true;
          break;
        case "bridge-on-ti-base-immediate":
          toTibase = true;
          multiUnitAbutment = true;
          break;
        case "nexus-hra":
        case "overdenture-on-attachments":
        case "nexus-single-implant-crown":
        case "custom-abutment-bridge":
          toTibase = false;
          multiUnitAbutment = false;
          break;
      }
      return this.rawImplantPlatforms
        .filter(platform => {
          return (
            platform.to_tibase === toTibase &&
            platform.multi_unit_abutment === multiUnitAbutment
          );
        })
        .map(platform => platform.id);
    },

    isSingleOrBridge() {
      return (
        this.indicationType === "nexus-single-implant-crown" ||
        this.indicationType === "nexus-micro-unit-bridge" ||
        this.indicationType === "custom-abutment-bridge"
      );
    },

    customColors() {
      const customColors = {};
      this.teeth.forEach(teeth => {
        if (!teeth.platform_id) {
          customColors[teeth.tooth] = { start: "#FFFF00", end: "#FFF000" };
        }
      });
      return customColors;
    },

    toothNamingConvention() {
      if (this.internationalToothConvention) {
        return this.internationalToothConvention;
      }
      return "fdi";
    }
  },

  methods: {
    selectTooth(tooth) {
      this.implantDialog = true;
      this.selectedTooth = tooth;
    },

    closeDialog() {
      this.implantDialog = false;
      this.selectedTooth = null;
    },

    deleteItem(item) {
      const index = this.value.indexOf(item);
      this.value.splice(index, 1);
      this.$emit("input", this.value);
    },

    getImplantPlatformName(id) {
      return this.rawImplantPlatforms.find(platform => {
        return platform.id === id;
      }).name;
    },

    confirmImplant() {
      this.implantDialog = false;
      this.value.push({
        tooth: this.selectedTooth,
        name: this.getImplantPlatformName(this.implantPlatform),
        implant_platform: this.implantPlatform,
        scan_body_type: this.scanBodyType
      });
      this.$emit("input", this.value);
    },

    getToothNumber(toothNumber) {
      return internationalToothNumbers[toothNumber][
        this.toothNamingConvention
      ].padStart(2, "0");
    }
  },

  async mounted() {
    this.rawImplantPlatforms = await this.$httpClient.fetchImplantPlatforms(
      this.practiceId
    );
  }
};
</script>

<style scoped>
.v-btn-toggle > .v-btn {
  border-radius: 0 !important;
}
</style>

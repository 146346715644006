<template>
  <v-item-group
    v-model="extention"
    active-class="active-button"
    @change="extentionSelected"
  >
    <div class="d-flex flex-wrap">
      <v-item v-slot:default="{ toggle }" value="follow-scan">
        <v-btn class="ma-1" @click="toggle" :disabled="disabled">
          {{ $t("productOptions.distalExtensions.followScan") }}
        </v-btn>
      </v-item>
    </div>
    <div class="d-flex flex-wrap" v-if="left_or_right == 'left'">
      <v-item
        v-slot:default="{ toggle }"
        value="second-molar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-molar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
      <v-item
        v-slot:default="{ toggle }"
        value="first-molar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-molar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
      <v-item
        v-slot:default="{ toggle }"
        value="second-premolar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-premolar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
      <v-item
        v-slot:default="{ toggle }"
        value="first-premolar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-premolar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
    </div>
    <div class="d-flex flex-wrap" v-if="left_or_right == 'right'">
      <v-item
        v-slot:default="{ toggle }"
        value="first-premolar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-premolar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
      <v-item
        v-slot:default="{ toggle }"
        value="second-premolar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-premolar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
      <v-item
        v-slot:default="{ toggle }"
        value="first-molar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-molar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
      <v-item
        v-slot:default="{ toggle }"
        value="second-molar"
        :disabled="disabled"
      >
        <v-img
          src="../../../assets/distal-extensions/nio-molar.png"
          width="10%"
          @click="toggle"
          :disabled="disabled"
        ></v-img>
      </v-item>
    </div>
  </v-item-group>
</template>

<script>
export default {
  name: "DistalExtension",

  props: ["value", "disabled", "left_or_right"],

  data() {
    return {
      extention: this.value
    };
  },

  methods: {
    extentionSelected() {
      this.$emit("input", this.extention);
    }
  }
};
</script>

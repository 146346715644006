<template>
  <div class="flex">
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <div v-if="!loading">
      <v-container fluid class="ma-0 pa-0" justify="center">
        <v-row wrap>
          <v-col cols="12" order-md="2" order="1">
            <v-container fill-height align-center text-center>
              <v-col md="12">
                <h1>
                  <v-btn :to="{ name: 'instructions' }" class="mx-1 my-3" fab>
                    <v-icon x-large>mdi-chevron-left</v-icon>
                  </v-btn>
                  {{ $t("nexusios.instructions.trios.trios") }}
                </h1>
              </v-col>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid class="py-5 black">
        <v-container fluid class="ma-0 pa-0">
          <v-row wrap>
            <v-col cols="12" order-md="2" order="1">
              <v-container fill-height align-center text-left>
                <v-col md="6">
                  <h1>
                    {{
                      $t(
                        "nexusios.instructions.trios.triosScanningInstructions"
                      )
                    }}
                    <!-- Trios Scanning Instructions -->
                  </h1>
                  <p></p>
                </v-col>
                <v-col md="6">
                  <v-img src="../assets/instruction-images/scanner-trios.png">
                  </v-img>
                </v-col>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <v-expansion-panels tile accordion>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <h2>
                {{ $t("nexusios.instructions.steps.1Preparations") }}
                <!-- 1. Preparations -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-1a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.1.1a") }}
                        <!-- 1a. Select New Patient -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-1b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.1.1b") }}
                        <!-- 1b. Fill in Details -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-1c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.1.1c") }}
                        <!-- 1c. Select "In House" -->
                      </h2>
                      <p></p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <h2>
                {{ $t("nexusios.instructions.steps.2TissueScans") }}
                <!-- 2. Tissue Scans -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2a") }}
                        <!-- 2a. Select "Scan Only" -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.2.2aText") }}
                        <!-- On all bars and abutments. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2b") }}
                        <!-- 2b. Start Upper/Lower Scan -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.2.2bText") }}
                        <!-- Begin your scan by selecting the button shown here in -->
                        <!-- red. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2c") }}
                        <!-- 2c. Scan Upper/Lower -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2d.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2d") }}
                        <!-- 2d. Inspect & Continue -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.2.2dText") }}
                        <!-- On all bars and abutments. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2e.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2e") }}
                        <!-- 2e. Start Opposing Scan -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.2.2eText") }}
                        <!-- Begin your scan by selecting the button shown here in -->
                        <!-- red. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2f.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2f") }}
                        <!-- 2f. Scan Opposing -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2g.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2g") }}
                        <!-- 2g. Inspect & Continue -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.2.2gText") }}
                        <!-- Inspect your finished scan, and continue to the next -->
                        <!-- step. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2h.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2h") }}
                        <!-- 2h. Select Occlusion -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2i.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2i") }}
                        <!-- 2i. Scan Occlusion -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.2.2iText") }}
                        <!-- Scan the occlusion so that your two previous scans -->
                        <!-- align. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-2j.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.2.2j") }}
                        <!-- 2j.Post Process. -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.2.2jText") }}
                        <!-- Select tools > Post processing. This will enable you to -->
                        <!-- export an STL when you finish scanning. -->
                      </p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <h2>
                {{ $t("nexusios.instructions.steps.3Scans") }}
                <!-- 3. Scans -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3a") }}
                        <!-- 3a. Select “Scan Only” -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.3.3aText") }}
                        <!-- On all bars and abutments. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3b") }}
                        <!-- 3b. Start Tissue Scan -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.3.3bText") }}
                        <!-- Begin your scan by selecting the button shown here in -->
                        <!-- red. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3c") }}
                        <!-- 3c. Scan Tissue -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3d.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3d") }}
                        <!-- 3d. Inspect & Continue -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3e.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3e") }}
                        <!-- 3e. Start Denture Scan (Extra-Oral) -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.3.3eText") }}
                        <!-- Attach 3 scan analogs to the denture. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3f.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3f") }}
                        <!-- 3f. Scan Denture (Extra-Oral) -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.3.3fText") }}
                        <!-- Scan the Denture Outside the mouth. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3g.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3g") }}
                        <!-- 3g. Inspect & Continue -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3h.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3h") }}
                        <!-- 3h. Select Occlusion Scan -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-3i.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.3.3i") }}
                        <!-- 3i.Do NOT Scan. Post Process -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.3.3iText") }}
                        <!-- Under tools, select Post Processing. This will enable -->
                        <!-- you to export an STL file later. -->
                      </p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center"
              ><h2>
                {{ $t("nexusios.instructions.steps.4ScanGaugeScanning") }}
                <!-- 4. Scan Gauge Scanning -->
              </h2></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row wrap>
                <v-col>
                  <v-container fill-height text-center class="align-start">
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4a.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4a") }}
                        <!-- 4a. Select “Scan Only” -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.4.4aText") }}
                        <!-- On all bars and abutments. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4b.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4b") }}
                        <!-- 4b. Start Tissue Scan -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.4.4bText") }}
                        <!-- Begin your scan by selecting the button shown here in -->
                        <!-- red. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4c.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4c") }}
                        <!-- 4c. Scan Tissue -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4d.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4d") }}
                        <!-- 4d. Inspect & Continue -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4e.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4e") }}
                        <!-- 4e. Start Denture Scan (Extra-Oral) -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.4.4eText") }}
                        <!-- Attach 3 scan analogs to the denture. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4f.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4f") }}
                        <!-- 4f. Scan Denture (Extra-Oral) -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.4.4fText") }}
                        <!-- Scan the Denture Outside the mouth. -->
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4g.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4g") }}
                        <!-- 4g. Inspect & Continue -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4h.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4h") }}
                        <!-- 4h. Select Occlusion Scan -->
                      </h2>
                      <p></p>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                      <v-img
                        src="../assets/instruction-images/trios/trios-4i.png"
                      ></v-img>
                      <h2 class="py-3">
                        {{ $t("nexusios.instructions.trios.4.4i") }}
                        <!-- 4i.Do NOT Scan. Post Process -->
                      </h2>
                      <p>
                        {{ $t("nexusios.instructions.trios.4.4iText") }}
                        <!-- Under tools, select Post Processing. This will enable -->
                        <!-- you to export an STL file later. -->
                      </p>
                    </v-col>
                  </v-container>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <InstructionsChecklist />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InstructionsChecklist from "./InstructionsChecklist.vue";

export default {
  name: "InstructionsTrios",
  components: {
    InstructionsChecklist
  },
  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapGetters(["approvedClient"])
  },

  methods: {
    ...mapActions(["fetchUser", "logout"])
  },

  async mounted() {
    this.loading = false;
  }
};
</script>

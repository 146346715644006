<template>
  <div class="fill-height flex">
    <div>
      <v-card-title
        ><h3>{{ $t("productCategories.singleBridge") }}</h3></v-card-title
      >
      <v-card-title
        ><h2>
          <strong
            ><b>{{ $t("nexusios.orderNow.selectIndicationType") }}</b></strong
          >
        </h2></v-card-title
      >
    </div>

    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <IndicationTypeSelector
      :product-category="productCategory"
      v-model="indicationType"
      @input="indicationTypeSelected"
    ></IndicationTypeSelector>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters, mapActions } from "vuex";
import IndicationTypeSelector from "../IndicationTypeSelector";

export default {
  name: "SingleBridgeOrder",

  components: {
    IndicationTypeSelector
  },

  data() {
    return {
      loading: false,
      productCategory: "single-bridge",
      indicationType: "",
      orderId: null,
      detailId: null,
      savedData: null
    };
  },

  computed: {
    ...mapGetters(["approvedClient", "practices", "user"])
  },

  methods: {
    ...mapActions(["fetchUser", "logout"]),

    prettyDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
    },

    indicationTypeSelected() {
      this.$router.push({
        name: "customise-order",
        params: { type: this.indicationType },
        query: {
          intended: "single-bridge-order",
          id: this.orderId,
          odid: this.detailId,
          product: this.productCategory
        }
      });
    }
  },

  async mounted() {
    this.loading = false;
    this.orderId = this.$route.query.id;
    this.detailId = this.$route.query.odid;
  }
};
</script>

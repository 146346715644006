<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center" class="tile">
      <v-col>
        <div>
          <h1 align="left" justify="center">
            {{ $t("common.orderNow") | uppercase }}
          </h1>
        </div>
        <new-patient-order
          :dialog="newPatientDialog"
          :patientName="patientName"
          :practiceName="practiceName"
          :approvedClient="approvedClient"
          :user="user"
          v-on:childShow="closePatientDialog"
        >
        </new-patient-order>
        <v-toolbar color="secondary" dark class="rounded-lg my-5">
          <v-img
            max-height="42"
            max-width="42"
            contain
            :src="require('@/assets/nexus-ios-app.png')"
          />
          <span class="ml-2" v-if="!smallScreen">
            {{ nexusAppMessage }}
          </span>
          <v-spacer />
          <a
            href="https://apps.apple.com/us/app/nexus-ios/id1670615101?itsct=apps_box_badge&amp;itscg=30200"
          >
            <v-img
              max-height="35"
              max-width="128"
              contain
              alt="Download on the App Store"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1678752000"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.nexusios.nexusapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <v-img
              max-height="64"
              max-width="128"
              contain
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
        </v-toolbar>
        <v-card>
          <v-row v-if="loading" class="ma-5">
            <v-col class="text-center">
              <v-progress-circular indeterminate size="256" color="primary">
                {{ $t("nexusios.patientOrder.loadingYourCases") }}
              </v-progress-circular>
            </v-col>
          </v-row>
          <patient-order-tabs
            v-if="!loading"
            :items="patients"
            :incompleteOrders="incompleteOrders"
            :needsAction="needsAction"
            :submittedOrders="submittedOrders"
            v-on:deleteOrder="deleteOrder"
            v-on:continueOrder="continueOrder"
          ></patient-order-tabs>

          <v-row>
            <v-col class="text-right">
              <v-btn
                v-if="!loading"
                class="ma-4"
                color="primary"
                @click="$router.push({ name: 'orders' })"
              >
                <span class="white--text">{{
                  $t("nexusios.patientOrder.viewCompletedOrders")
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <NewVersionSnackbar />
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters, mapActions } from "vuex";
import NewPatientOrder from "./NewPatientOrder";
import PatientOrderTabs from "./PatientOrderTabs.vue";
import NewVersionSnackbar from "@/components/shared/NewVersionSnackbar";

export default {
  name: "PatientOrder",

  components: {
    "new-patient-order": NewPatientOrder,
    "patient-order-tabs": PatientOrderTabs,
    NewVersionSnackbar,
  },

  computed: {
    ...mapGetters(["approvedClient", "kits", "practices", "user"]),
    nexusAppMessage() {
      return this.nexusAppMessages[this.nexusAppMessageIndex];
    },
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
  },

  props: {
    orderId: String,
  },

  data() {
    return {
      loading: true,
      newPatientDialog: false,
      patientName: "",
      practiceName: "",
      headers: [
        { text: "Order Id", value: "id" },
        { text: "Date of Order", value: "order_date" },
        { text: "Practice", value: "practice_name" },
        { text: "Patient Name", value: "patient_name" },
        { text: "Intended Restoration", value: "intended_description" },
        { text: "Indication(s)", value: "indication_description" },
        { text: "Pre Op", value: "preop_status" },
        { text: "Immediate", value: "immediate_status" },
        { text: "Definitive", value: "definitive_status" },
        { text: "Actions", value: "actions" },
      ],
      patients: [],
      incompleteOrders: [],
      needsAction: [],
      submittedOrders: [],
      nexusAppMessageIndex: 0,
      nexusAppMessages: [
        "Manage all your Nexus IOS cases in one convenient app",
        "Receive real-time notifications straight to your phone",
        "Try the new Nexus IOS App today!",
      ],
    };
  },

  methods: {
    ...mapActions(["fetchUser", "logout"]),

    prettyDateTime(dateTime) {
      if (dateTime) {
        return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATE_SHORT);
      }
    },

    getItemId(item) {
      return item.intended == "single-bridge-order" ||
        item.intended == "full-arch-order"
        ? item.detail_id
        : item.product_category == "pre-op"
        ? item.detail_id
        : item.product_category == "immediate"
        ? item.immediate_id
        : item.definitive_id;
    },

    async deleteOrder(item) {
      if (confirm(`Are you sure you want to delete this record?`)) {
        this.loading = true;
        let detailId =
          item.preop_status == "Incomplete"
            ? item.detail_id
            : item.immediate_status == "Incomplete"
            ? item.immediate_id
            : item.definitive_status == "Incomplete" && item.definitive_id
            ? item.definitive_id
            : item.detail_id;
        this.$httpClient
          .deletePatientOrder(item.id, detailId)
          .then((data) => {
            let patientIndex = this.patients.findIndex(
              (patient) => patient.id === item.id
            );
            if (data.do_delete) {
              this.patients = this.patients.filter(
                (patient) => patient.id !== item.id
              );
            } else {
              if (
                this.patients[patientIndex].details.some(
                  (el) => el.id === item.detail_id
                ) &&
                item.preop_status == "Incomplete"
              ) {
                this.patients[patientIndex].preop_status = null;
                this.patients[patientIndex].indication_type = null;
                this.patients[patientIndex].indication_description = "";
              }

              if (
                this.patients[patientIndex].details.some(
                  (el) => el.id === item.immediate_id
                ) &&
                item.immediate_status == "Incomplete"
              ) {
                this.patients[patientIndex].immediate_status = null;
                this.patients[patientIndex].indication_type = null;
                this.patients[patientIndex].indication_description = "";
              }
              if (
                this.patients[patientIndex].details.some(
                  (el) => el.id === item.definitive_id
                ) &&
                item.definitive_status == "Incomplete"
              ) {
                this.patients[patientIndex].definitive_status = null;
                this.patients[patientIndex].indication_type = null;
                this.patients[patientIndex].indication_description = "";
              }
              this.patients[patientIndex].details = this.patients[
                patientIndex
              ].details.filter((detail) => detail.id !== detailId);
            }
            this.groupOrders();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            alert("Could not delete this order.");
          });
      }
    },

    continueOrder(item) {
      if (item.indication_type) {
        this.indicationTypeSelected(item);
      } else {
        if (item.product_category == "pre-op") {
          this.$router.push({
            name: item.intended,
            query: {
              id: item.id,
              odid: this.getItemId(item),
              cat: item.product_category,
              imdid: item.immediate_id,
            },
          });
        } else {
          this.$router.push({
            name: item.intended,
            query: {
              id: item.id,
              odid: this.getItemId(item),
              cat: item.product_category,
            },
          });
        }
      }
    },

    newPatientOrder() {
      this.newPatientDialog = true;
    },

    closePatientDialog() {
      this.newPatientDialog = false;
    },

    indicationTypeSelected(item) {
      this.$router.push({
        name: "customise-order",
        params: { type: item.indication_type },
        // query: { intended: item.intended, id: item.id, odid: item.detail_id } });
        query: {
          intended: item.intended,
          id: item.id,
          odid: this.getItemId(item),
        },
      });
    },

    groupOrders() {
      this.incompleteOrders = this.patients.filter((item) => {
        return (
          item.preop_status === "Incomplete" ||
          item.immediate_status === "Incomplete" ||
          item.definitive_status === "Incomplete"
        );
      });
      this.submittedOrders = this.patients.filter((item) => {
        if (
          (item.definitive_status !== null &&
            (item.definitive_status === "Ordered" ||
              item.definitive_status === "Complete")) ||
          (item.definitive_status === null &&
            (item.immediate_status === "Ordered" ||
              item.immediate_status === "Complete")) ||
          (item.definitive_status === null &&
            item.immediate_status === null &&
            (item.preop_status === "Ordered" ||
              item.preop_status === "Complete"))
        ) {
          return true;
        }
      });
      this.needsAction = this.patients.filter((item) => {
        return (
          item.preop_status === "Needs Approval" ||
          item.preop_status === "Missing Information" ||
          item.immediate_status === "Needs Approval" ||
          item.immediate_status === "Missing Information" ||
          item.definitive_status === "Needs Approval" ||
          item.definitive_status === "Missing Information"
        );
      });
    },

    async getOrders(client_id) {
      this.loading = true;
      this.$httpClient
        .getAllPatientOrders(client_id)
        .then((data) => {
          this.patients = data.filter(
            (data) => data.definitive_status != "Dispatched"
          );
          this.groupOrders();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log("Retrieving Patient Orders failed. Error:", error);
        });
    },
  },

  async mounted() {
    this.loading = false;
    this.getOrders(this.approvedClient.id);
    setInterval(() => {
      if (this.nexusAppMessageIndex === this.nexusAppMessages.length - 1) {
        this.nexusAppMessageIndex = 0;
      } else {
        this.nexusAppMessageIndex++;
      }
    }, 3_000);
  },

  created() {
    if (!window.Tawk_API) {
      return;
    }
    window.Tawk_API.onLoad = () => {
      window.Tawk_API.showWidget();
      window.Tawk_API.setAttributes({
        name: this.user.name,
        email: this.user.email,
      });
    };
  },
};
</script>

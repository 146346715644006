var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex fill-height pa-5"},[(!_vm.launch)?_c('h1',[_vm._v(" "+_vm._s(_vm._f("capitalizeAll")(_vm.$t("products.chairsideSupport")))+" ")]):_vm._e(),(_vm.launch)?_c('h1',[_c('v-btn',{attrs:{"fab":""},on:{"click":function($event){_vm.launch = false}}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-left")])],1),_vm._v(" "+_vm._s(_vm.$t("nexusios.chairsideSupport.launchSession.launchSession"))+" ")],1):_vm._e(),_c('v-flex',{staticClass:"pt-12"},[(!_vm.launch)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center white--text ma-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",class:{ 'on-hover': hover },staticStyle:{"background-color":"rgba(200,200,200,0)"},attrs:{"elevation":hover ? 12 : 2},on:{"click":_vm.book}},[_c('v-img',{attrs:{"src":require("../assets/graphics/nios-booking.png")}}),_c('h2',{staticClass:"mt-12"},[_vm._v(" "+_vm._s(_vm.$t("nexusios.chairsideSupport.bookSession.bookSession"))+" ")])],1)]}}],null,false,1539887257)})],1),(false)?_c('v-col',{staticClass:"text-center white--text ma-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",class:{ 'on-hover': hover },staticStyle:{"background-color":"rgba(200,200,200,0)"},attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){_vm.launch = true}}},[_c('v-img',{attrs:{"src":require("../assets/graphics/nios-jitsi-computer.png")}}),_c('h2',{staticClass:"mt-12"},[_vm._v(" "+_vm._s(_vm.$t("nexusios.chairsideSupport.launchSession.launchSession"))+" ")])],1)]}}],null,false,2283516581)})],1):_vm._e()],1):_vm._e(),(_vm.launch)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center white--text ma-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",class:{ 'on-hover': hover },staticStyle:{"background-color":"rgba(200,200,200,0)"},attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.launchSession('desktop')}}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":require('../assets/chairside-support/nios-chairside-desktop.png'),"height":"18em","max-height":"48em","contain":""}}),_c('h2',{staticClass:"mt-12"},[_vm._v(" "+_vm._s(_vm.$t("nexusios.chairsideSupport.launchSession.desktop"))+" ")])],1)]}}],null,false,3208720219)})],1),_c('v-col',{staticClass:"text-center white--text ma-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",class:{ 'on-hover': hover },staticStyle:{"background-color":"rgba(200,200,200,0)"},attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.launchSession('phone')}}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":require('../assets/chairside-support/nios-chairside-phone.png'),"height":"18em","max-height":"48em","contain":""}}),_c('h2',{staticClass:"mt-12"},[_vm._v(" "+_vm._s(_vm.$t("nexusios.chairsideSupport.launchSession.desktopPhone"))+" ")])],1)]}}],null,false,148566439)})],1),_c('v-col',{staticClass:"text-center white--text ma-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-4",class:{ 'on-hover': hover },staticStyle:{"background-color":"rgba(200,200,200,0)"},attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.launchSession('glasses')}}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":require('../assets/chairside-support/nios-chairside-glasses.png'),"height":"18em","max-height":"48em","contain":""}}),_c('h2',{staticClass:"mt-12"},[_vm._v(" "+_vm._s(_vm.$t("nexusios.chairsideSupport.launchSession.desktopAr"))+" ")])],1)]}}],null,false,3074422920)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
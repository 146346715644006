<template>
  <v-btn-toggle
    v-model="design"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="designSelected"
  >
    <v-btn
      class="ma-1"
      value="modified-ridge-lap"
      :disabled="disabled"
      width="200"
      height="100"
    >
      <div align="center" justify="center" width="100px">
        <v-img
          src="../../../assets/design-pontic/pontic-modified-ridge-lap.png"
          width="50"
        >
        </v-img>
        <div>{{ $t("productOptions.ponticDesign.modifiedRidgeLap") }}</div>
      </div>
    </v-btn>
    <v-btn
      class="ma-1"
      value="stein"
      :disabled="disabled"
      width="100"
      height="100"
    >
      <div align="center" justify="center">
        <v-img
          src="../../../assets/design-pontic/pontic-stein.png"
          width="50"
        ></v-img>
        {{ $t("productOptions.ponticDesign.stein") }}
      </div>
    </v-btn>
    <v-btn
      class="ma-1"
      value="sanitary"
      :disabled="disabled"
      width="100"
      height="100"
    >
      <div align="center" justify="center">
        <v-img
          src="../../../assets/design-pontic/pontic-sanitary.png"
          width="50"
        ></v-img>
        {{ $t("productOptions.ponticDesign.sanitary") }}
      </div>
    </v-btn>
    <v-btn
      class="ma-1"
      value="ovate"
      :disabled="disabled"
      width="100"
      height="100"
    >
      <div align="center" justify="center">
        <v-img
          src="../../../assets/design-pontic/pontic-ovate.png"
          width="50"
        ></v-img>
        {{ $t("productOptions.ponticDesign.ovate") }}
      </div>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "PonticDesignSelections",

  props: ["value", "disabled"],

  data() {
    return {
      design: this.value
    };
  },

  methods: {
    designSelected() {
      this.$emit("input", this.design);
    }
  }
};
</script>

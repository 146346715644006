<template>
  <v-btn-toggle
    v-model="barMaterial"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="barMaterialSelected"
  >
    <v-btn
      class="ma-1 titanium"
      value="titanium"
      :disabled="disabled"
      v-if="options.includes('titanium')"
    >
      {{ $t("material.titanium") }}
    </v-btn>
    <v-btn
      class="ma-1 zirconia-abutment"
      value="zirconia-abutment"
      :disabled="disabled"
      v-if="options.includes('zirconia-abutment')"
    >
      {{ $t("material.zirconiaAbutment") }}
    </v-btn>
    <v-btn
      class="ma-1 peek"
      value="peek"
      :disabled="disabled"
      v-if="options.includes('peek')"
    >
      {{ $t("material.peek") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "BarMaterialSelections",

  props: ["value", "options", "disabled"],

  data() {
    return {
      barMaterial: this.value
    };
  },

  methods: {
    barMaterialSelected() {
      this.$emit("input", this.barMaterial);
    }
  },

  mounted() {
    // disable preselecting when only 1 option
    // if (this.options.length === 1) {
    //   this.material = this.options[0];
    //   this.$emit("input", this.material);
    // }
  }
};
</script>

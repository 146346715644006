<template>
  <div class="flex">
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-container fluid class="ma-0 pa-0" justify="center" v-if="!loading">
      <v-row wrap>
        <v-col cols="12" order-md="2" order="1">
          <v-container fill-height align-center text-center>
            <v-col md="12">
              <h1>
                <v-btn :to="{ name: 'instructions' }" class="mx-1 my-3" fab>
                  <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
                {{ $t("nexusios.instructions.general.generaltips") }}
                <!-- General Tips -->
              </h1>
            </v-col>
          </v-container>
        </v-col>
      </v-row>

      <v-container fluid class="py-5 black">
        <v-container fluid class="ma-0 pa-0">
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <h1>
                  {{ $t("nexusios.instructions.general.1.scanGaugePlacement") }}
                  <!-- 1. Scan Gauge Placement -->
                </h1>
              </v-container>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <v-col class="col-12 col-sm-6 text-left">
                  <img
                    src="../assets/instruction-images/nio-placement-4.png"
                    style="height: inherit; width: inherit;"
                    class="dropshadow"
                  />
                  <h3>
                    {{
                      $t(
                        "nexusios.instructions.general.1.correctPlacement.heading"
                      )
                    }}
                  </h3>
                  <p>
                    <v-icon large class="ma-0 pa-2 white--text"
                      >mdi-check</v-icon
                    >
                    {{
                      $t("nexusios.instructions.general.1.correctPlacement.1")
                    }}
                    <!-- Scan Gauges Run parallel with each other. -->
                    <br />
                    <v-icon large class="ma-0 pa-2 white--text"
                      >mdi-check</v-icon
                    >
                    {{
                      $t("nexusios.instructions.general.1.correctPlacement.2")
                    }}
                    <!-- There are no large gaps. -->
                    <br />
                    <v-icon large class="ma-0 pa-2 white--text"
                      >mdi-check</v-icon
                    >
                    {{
                      $t("nexusios.instructions.general.1.correctPlacement.3")
                    }}
                    <!-- The Scan Gauges are close, but do not touch. -->
                    <br />
                    <v-icon large class="ma-0 pa-2 white--text">
                      mdi-check
                    </v-icon>
                    {{
                      $t("nexusios.instructions.general.1.correctPlacement.4")
                    }}
                    <!-- A scan can be obtained in a single sweep. -->
                    <br />
                  </p>
                </v-col>
                <v-col class="col-12 col-sm-6 text-left">
                  <img
                    src="../assets/instruction-images/nio-placement-4-wrong.png"
                    style="height: inherit; width: inherit;"
                    class="dropshadow"
                  />
                  <h3>
                    {{
                      $t(
                        "nexusios.instructions.general.1.incorrectPlacement.heading"
                      )
                    }}
                    <!-- Incorrect Placement -->
                  </h3>
                  <p>
                    <v-icon large class="ma-0 pa-2 white--text"
                      >mdi-close</v-icon
                    >
                    {{
                      $t("nexusios.instructions.general.1.incorrectPlacement.1")
                    }}
                    <!-- There are large gaps between the scan gauges. -->
                    <br />
                    <v-icon large class="ma-0 pa-2 white--text"
                      >mdi-close</v-icon
                    >
                    {{
                      $t("nexusios.instructions.general.1.incorrectPlacement.2")
                    }}
                    <!-- The Scan Gauges do not form a cohesive surface. -->
                    <br />
                  </p>
                </v-col>
              </v-container>
            </v-col>
          </v-row>
          <hr />
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <h1>
                  {{ $t("nexusios.instructions.general.2.beforeYouBegin") }}
                  <!-- 2. Before You Begin -->
                </h1>
              </v-container>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <v-col class="col-12 col-sm-6 text-left">
                  <img
                    src="../assets/instruction-images/nio-moisture-blue.png"
                    style="max-height: 300px;"
                    class="dropshadow"
                  />
                  <h2>
                    {{
                      $t(
                        "nexusios.instructions.general.2.removeMoistureHeading"
                      )
                    }}
                    <!-- Remove Moisture -->
                  </h2>
                  <p>
                    {{
                      $t("nexusios.instructions.general.2.removeMoistureText")
                    }}
                    <!-- Remove any moisture from the scanning are before beginning
                      your scan. Reflections, saliva and blood may impact your
                      scan data. -->
                  </p>
                </v-col>
                <v-col class="col-12 col-sm-6 text-left">
                  <img
                    src="../assets/instruction-images/nio-paper.png"
                    style="max-height: 300px;"
                  />
                  <h2>
                    {{
                      $t(
                        "nexusios.instructions.general.2.separateScanGaugesHeading"
                      )
                    }}
                    <!-- Separate Scan Gauges -->
                  </h2>
                  <p>
                    {{
                      $t(
                        "nexusios.instructions.general.2.separateScanGaugesText"
                      )
                    }}
                    <!-- Your Scan Gauges should be very close to each other, but
                      they should not touch. Use articulating paper to ensure that
                      your scan gauges are not touching. -->
                  </p>
                </v-col>
              </v-container>
            </v-col>
          </v-row>
          <hr />
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <h1>
                  {{ $t("nexusios.instructions.general.3.scanGaugePlacement") }}
                  <!-- 3. Scan Gauge Placement -->
                </h1>
              </v-container>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <h2>
                  {{
                    $t(
                      "nexusios.instructions.general.3.correctPlacementExamples"
                    )
                  }}
                  <!-- Correct Placement Examples -->
                </h2>
              </v-container>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <v-col cols="12" sm="4" class="border">
                  <img
                    src="../assets/instruction-images/nio-scanning-4-right.gif"
                    style="height: inherit; width: inherit;"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="border">
                  <img
                    src="../assets/instruction-images/nio-scanning-5-right.gif"
                    style="height: inherit; width: inherit;"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="border">
                  <img
                    src="../assets/instruction-images/nio-scanning-6-right.gif"
                    style="height: inherit; width: inherit;"
                  />
                </v-col>
              </v-container>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <h2>
                  {{
                    $t(
                      "nexusios.instructions.general.3.incorrectPlacementExamples"
                    )
                  }}
                  <!-- Incorrect Placement Examples -->
                </h2>
              </v-container>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col>
              <v-container fill-height text-center class="align-start">
                <v-col cols="12" sm="4" class="border">
                  <img
                    src="../assets/instruction-images/nio-scanning-4-wrong.gif"
                    style="height: inherit; width: inherit;"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="border">
                  <img
                    src="../assets/instruction-images/nio-scanning-5-wrong.gif"
                    style="height: inherit; width: inherit;"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="border">
                  <img
                    src="../assets/instruction-images/nio-scanning-6-wrong.gif"
                    style="height: inherit; width: inherit;"
                  />
                </v-col>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "InstructionsGeneral",

  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapGetters(["approvedClient"])
  },

  methods: {
    ...mapActions(["fetchUser", "logout"])
  },

  async mounted() {
    this.loading = false;
  }
};
</script>

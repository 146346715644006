<template>
  <div>
    <v-card v-if="selectedOrder">
      <OrderDetails @back="selectedOrder = false" :orderId="caseNumber" />
    </v-card>
    <div v-else>
      <v-card v-if="!loading">
        <v-toolbar color="secondary">
          <v-toolbar-title>
            {{ $t("nexusios.account.orderHistory.orderHistory") }}
          </v-toolbar-title>
        </v-toolbar>
        <!-- use orders_done for items on GO LIVE previously orders-->
        <v-data-table
          class="my-5"
          :headers="headers"
          :items="item_orders"
          :items-per-page="5"
        >
          <template v-slot:item.oep_case_id="{ item }">
            {{ getCaseMKey(item.oep_case_id) }}
            ({{ item.oep_case_id || $t("common.pending") }})
          </template>
          <template v-slot:item.indication_type="{ item }">
            {{ item.payload.indication_type | capitalizeAll }}
          </template>
          <template v-slot:item.patient_name="{ item }">
            {{ item.payload.patient_name }}
          </template>
          <template v-slot:item.practice_id="{ item }">
            {{ getPracticeName(item.practice_id) }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ prettyDateTime(item.created_at) }}
          </template>
          <template v-slot:item.status="{ item }">
            {{ getStatus(item.oep_case_id) | capitalizeAll }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-row>
                  <v-col class="text-center">
                    <v-btn
                      small
                      color="primary"
                      v-if="isEnglish && getPortalLink(item.oep_case_id)"
                      @click="openPortal(getPortalLink(item.oep_case_id))"
                    >
                      <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                      {{ $t("common.open") }}
                    </v-btn>
                    <v-btn
                      small
                      color="primary"
                      @click="openApprovalFiles(item)"
                      :disabled="!item.oep_case_id"
                      v-if="
                        !isEnglish &&
                          getStatus(item.oep_case_id).toUpperCase() !=
                            'CANCELLED'
                      "
                      v-on="on"
                    >
                      <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                      {{ $t("common.open") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $t("nexusios.patientOrder.approvalFiles") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
      <approval-files
        :dialog="approvalFileDialog"
        :case-number="caseNumber"
        :case-m-key="caseMKey"
        :patient-name="patientName"
        :practice-name="practiceName"
        :messaging="canMessage"
        :files="files"
        :notes="notes"
        v-on:childShow="closeApprovalFileDialog"
      >
      </approval-files>
    </div>
  </div>
</template>

<script>
import OrderDetails from "./OrderDetails.vue";
import { mapGetters, mapActions } from "vuex";
import { DateTime } from "luxon";
import ApprovalFiles from "./ApprovalFiles";

export default {
  name: "OrderHistory",

  components: {
    "approval-files": ApprovalFiles,
    OrderDetails
  },

  computed: {
    ...mapGetters([
      "orders",
      "orders_done",
      "practices",
      "user",
      "caseDetails",
      "currentLocale"
    ]),

    isEnglish() {
      return this.currentLocale.locale === "en";
    },

    headers() {
      return [
        { text: this.$t("common.caseId"), value: "oep_case_id" },
        { text: this.$t("unsorted.indicationType"), value: "indication_type" },
        { text: this.$t("common.patient"), value: "patient_name" },
        { text: this.$t("common.practice"), value: "practice_id" },
        { text: this.$t("common.dateOrdered"), value: "created_at" },
        { text: this.$t("common.status"), value: "status" },
        { text: "", value: "actions" }
      ];
    },

    item_orders() {
      return this.orders_done;
    }
  },

  data() {
    return {
      selectedOrder: false,
      showOrder: false,
      loading: true,
      approvalFileDialog: false,
      caseNumber: String,
      caseMKey: String,
      patientName: String,
      practiceName: String,
      canMessage: false,
      loadingDialog: false,
      files: {},
      notes: [],
      statusData: []
    };
  },

  methods: {
    ...mapActions(["fetchUser", "fetchCases"]),

    async fetchStatus() {
      if (!this.orders_done) {
        this.loading = false;
        this.statusData;
        return;
      }
      await this.$httpClient
        .fetchCasesStatus(this.orders_done)
        .then(response => {
          this.loading = false;
          this.statusData = response;
        })
        .catch(error => {
          console.log("Retrieving case status failed. Error:", error);
          this.loading = false;
        });
    },

    openPortal(link) {
      window.open(link);
    },

    getPortalLink(oep_id) {
      if (this.statusData.length > 0) {
        const filter_case = this.statusData.filter(
          item => item.case_id == oep_id
        );
        if (!filter_case) {
          return null;
        }
        return `${filter_case[0]["handover_uri"]}${filter_case[0]["portal_key"]}`;
      }
      return null;
    },

    getStatus(oep_id) {
      if (this.statusData.length > 0) {
        var filter_case;
        filter_case = this.statusData.filter(item => item.case_id == oep_id);
        return filter_case[0]["case_status"]
          ? filter_case[0]["case_status"]
          : filter_case[0]["case_primary_status"];
      }
      return "";
    },

    getCaseMKey(oep_id) {
      if (this.statusData.length > 0) {
        var filter_case;
        filter_case = this.statusData.filter(item => item.case_id == oep_id);
        return filter_case[0]["case_mkey"];
      }
      return "";
    },

    getCanMessage(oep_id) {
      if (this.statusData.length === 0) {
        return false;
      }
      return this.statusData.find(item => item.case_id == oep_id).can_message;
    },

    selectOrder(orderDetails) {
      this.selectedOrder = true;
      this.caseNumber = orderDetails.oep_case_id;
    },

    getPracticeOEPID(id) {
      const practice = this.practices.find(practice => practice.id === id);
      if (!practice) {
        return;
      }
      return practice.oep_id;
    },

    getPracticeName(id) {
      return this.practices.filter(practice => practice.id === id)[0]
        .business_name;
    },

    prettyDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
    },

    openApprovalFiles: async function(item) {
      this.caseNumber = item.oep_case_id;
      this.caseMKey = this.getCaseMKey(item.oep_case_id);
      this.patientName = item.payload.patient_name;
      this.practiceName = this.getPracticeName(item.practice_id);
      this.files = await this.$httpClient.getCaseFilesApproval(this.caseNumber);
      this.notes = this.files.client_notes;
      this.canMessage = this.getCanMessage(item.oep_case_id);
      this.approvalFileDialog = true;
      this.loadingDialog = false;
      this.selectedOrder = false;
      this.showOrder = false;

      document.title = `${this.caseMKey} (${this.caseNumber}) Approval - Order History - Nexus iOS`;
      history.pushState({}, null, this.$route.path + "#");
    },

    closeApprovalFileDialog() {
      this.approvalFileDialog = false;

      document.title = "Order History - Nexus iOS";
      history.pushState({}, null, this.$route.path);
    }
  },
  mounted() {
    this.fetchStatus();
    this.fetchPortalKeys();
  }
};
</script>

<template>
  <div>
    <div class="my-5">
      Should not be kept in the mouth for longer than the duration of the
      appointment.
    </div>
    <v-btn-toggle
      v-model="required"
      class="d-flex flex-wrap"
      active-class="active-button"
      borderless
      background-color="rgba(0,0,0,0)"
      @change="requiredSelected"
    >
      <v-btn class="ma-1" value="tryInYes" :disabled="disabled">
        {{ $t("productOptions.3DPrintedTryInSelections.tryInYes") }}
      </v-btn>
      <v-btn class="ma-1" value="tryInDesignOnly" :disabled="disabled">
        {{ $t("productOptions.3DPrintedTryInSelections.tryInDesignOnly") }}
      </v-btn>
      <v-btn class="ma-1" value="tryInNo" :disabled="disabled">
        {{ $t("productOptions.3DPrintedTryInSelections.tryInNo") }}
      </v-btn>
    </v-btn-toggle>
    <div class="my-3 red--text" v-if="value === 'tryInYes'">
      This will incur an additional cost.
    </div>
  </div>
</template>

<script>
export default {
  name: "TryIn",

  props: ["value", "disabled"],

  data() {
    return {
      required: this.value
    };
  },

  methods: {
    requiredSelected() {
      this.$emit("input", this.required);
      this.$emit("change");
    }
  }
};
</script>

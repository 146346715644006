<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-on="{ ...tooltip, ...menu }">
            <v-icon>mdi-earth</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t("nexusios.languageSwitcher.switchLanguage") }}
        </span>
      </v-tooltip>
    </template>
    <v-list color="secondary">
      <v-list-item
        v-for="language in languages"
        :key="language.id"
        @click="setUserLangTo(language.id)"
      >
        <v-list-item-avatar tile size="24">
          <v-img :src="require(`./flags/${language.flagSrc}`)"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ $t(language.title) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import getBrowserLocale from "./getBrowserLocale.js";
import { mapGetters, mapActions } from "vuex";

export default {
  mounted() {
    this.preferredLanguage = this.getStartingLocale();
    this.loadLocale(this.currentLanguage);
    let current = this.languages.find(
      locale => locale.id === this.currentLanguage
    );
    this.setCurrentLocale(current);
  },
  data() {
    return {
      preferredLanguage: false,
      defaultLanguage: "en",
      loadedLanguages: ["en"]
    };
  },
  computed: {
    ...mapGetters(["user"]),

    languages() {
      return [
        {
          id: "en",
          locale: "en",
          title: "nexusios.languageSwitcher.english",
          flagSrc: "us.png",
          wordBreak: true
        },
        {
          id: "ja",
          locale: "ja",
          title: "nexusios.languageSwitcher.japanese",
          flagSrc: "jp.png",
          wordBreak: false
        },
        {
          id: "fr",
          locale: "fr",
          title: "nexusios.languageSwitcher.french",
          flagSrc: "fr.png",
          wordBreak: false
        }
      ];
    },

    currentClient() {
      return this.user?.client;
    },

    userLanguage() {
      // if logged in returns the users language
      if (this.user?.client?.language) {
        return this.currentClient.language;
      } else {
        return false;
      }
    },
    currentLanguageIcon() {
      // filters the current language and returns the flag.
      return this.languages.filter(lang => lang.id === this.currentLanguage)[0]
        .flagSrc;
    },
    currentLanguage() {
      // sets the current language in order of preference
      // users details, browsers preferred language, default language.
      if (this.userLanguage) {
        return this.userLanguage;
      } else if (this.preferredLanguage) {
        return this.preferredLanguage;
      } else {
        return this.defaultLanguage;
      }
    }
  },
  watch: {
    currentLanguage() {
      // Updates the language when currentLanguage changes
      this.loadLocale(this.currentLanguage);
      let current = this.languages.find(
        locale => locale.id === this.currentLanguage
      );
      this.setCurrentLocale(current);
    }
  },
  methods: {
    ...mapActions(["changeUserLocale", "setCurrentLocale"]),

    getStartingLocale() {
      // Uses the browsers preferred page info to se a preferred language.
      // if supported the language will be set, otherwise it will default to english.
      const browserLocale = getBrowserLocale({ countryCodeOnly: true });
      const preferred = this.languages.filter(lang =>
        lang.id === browserLocale ? true : false
      );

      if (preferred.length > 0) {
        return preferred[0].locale;
      } else {
        return "en";
      }
    },
    setUserLangTo(lang) {
      // Only used by the user through the language picker.

      // if logged in change the user's chosen locale.
      if (this.user?.client?.id) {
        this.changeUserLocale(lang);
      }
      this.preferredLanguage = lang;
      this.loadLocale(lang);
    },
    loadLocale(lang) {
      // checks to see if the locale is loaded. Loads it if required.

      // If the same language
      if (this.$i18n.locale === lang) {
        return Promise.resolve(this.setI18nLanguage(lang));
      }

      // If the language was already loaded
      if (this.loadedLanguages.includes(lang)) {
        return Promise.resolve(this.setI18nLanguage(lang));
      }

      // If the language hasn't been loaded yet
      this.$httpClient
        .fetchLanguage(lang)
        .then(messages => {
          this.$i18n.setLocaleMessage(lang, messages);
          this.loadedLanguages.push(lang);
        })
        .then(() => {
          import(
            /* webpackChunkName: "lang-[request]" */ `vuetify/es5/locale/${lang}`
          ).then(messages => {
            const vuetifyMessages = { $vuetify: { ...messages.default } };
            this.$i18n.mergeLocaleMessage(lang, vuetifyMessages);

            // due to async: language might have been changed since the function was invoked, must only be set if is the same.
            if (this.currentLanguage === lang) {
              this.setI18nLanguage(lang);
            }
          });
        });
    },
    setI18nLanguage(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <v-container
    fluid
    ref="fixed"
    class="pa-8 pa-lg-12"
    style="background: linear-gradient(-120deg, #2e2e2e, #000 100%);"
  >
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-form ref="supportForm" v-if="!loading">
      <v-alert type="error" v-if="error">
        An unexpected error has occurred, please contact us for assistance.
      </v-alert>
      <v-row class="ma-5">
        <v-col md="8">
          <h1 class="my-3">Book Chairside Support</h1>
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <v-img
            max-height="600"
            :src="require('../assets/intraoral-scanning.jpg')"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="8">
              <v-select
                solo
                light
                v-model="supportRequest.practice_id"
                label="Practice"
                :items="practiceNames"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                color="primary"
                x-large
                :to="{
                  name: 'edit-practice',
                  params: { id: 'new' }
                }"
                >New
              </v-btn>
            </v-col>
          </v-row>
          <v-card v-if="selectedPractice">
            <v-card-title>Contact Details</v-card-title>
            <v-card-text v-if="!practiceMissingDetails">
              {{ selectedPractice.contact_person }}<br />
              {{ selectedPractice.email }}<br />
              {{ selectedPractice.phone }}
            </v-card-text>
            <v-card-text v-if="practiceMissingDetails">
              <v-alert type="warning">
                We have no contact details on file for this practice.
              </v-alert>
              Please ensure you update contact details before creating your
              booking.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :to="{
                  name: 'edit-practice',
                  params: { id: selectedPractice.id }
                }"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
          <br />
          <v-text-field
            solo
            light
            v-model="supportRequest.patient_name"
            label="Patient name"
            :rules="[rules.required]"
          >
          </v-text-field>
          <v-textarea
            solo
            light
            v-model="supportRequest.message"
            label="Message"
          >
          </v-textarea>
          <v-select
            solo
            light
            v-model="supportRequest.appointment_type"
            :items="appointmentType"
            label="Appointment Type"
            :rules="[rules.required]"
          >
          </v-select>
          <v-datetime-picker
            v-model="supportRequest.desired_appointment"
            :label="`Desired Appointment (${timeZone} Time)`"
            :datePickerProps="{
              min: this.supportRequest.desired_appointment.toISOString()
            }"
            :timePickerProps="{ allowedMinutes: [0, 30] }"
            :textFieldProps="{ solo: true, light: true }"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
          <v-container>
            <v-row v-if="differentZones">
              <v-col col="2">
                <strong>Desired Appointment:</strong>
              </v-col>
            </v-row>
            <v-row v-if="differentZones">
              <v-col>
                Your Time:
              </v-col>
              <v-col>
                {{ this.theirAppointmentTime }}
              </v-col>
            </v-row>
            <v-row v-if="differentZones">
              <v-col>
                Our Time:
              </v-col>
              <v-col>
                {{ this.ourAppointmentTime }}
              </v-col>
            </v-row>
            <v-row v-if="!differentZones">
              <v-col>
                <strong>Desired Appointment:</strong>
              </v-col>
              <v-col>
                {{ this.theirAppointmentTime }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center" cols="12">
                <v-icon>mdi-information</v-icon>
                We will confirm your requested booking time with you via email.
              </v-col>
            </v-row>
          </v-container>
          <v-container class="text-right">
            <v-btn x-large color="success" @click="submit">
              Book Now
            </v-btn>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { validationRequired, validationEmail } from "../shared/ValidationRules";
import { mapGetters, mapActions } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "BookSupport",

  data: () => ({
    loading: true,
    error: false,
    supportRequest: {
      desired_appointment: DateTime.local()
        .plus({ days: 1 })
        .minus({ minutes: DateTime.local().minute })
        .toJSDate(),
      screen_sharing_platform: "no_preference"
    },

    rules: {
      required: validationRequired,
      email: validationEmail
    },

    // screenSharingPlatforms: [
    //   { text: "TeamViewer", value: "teamviewer" },
    //   { text: "Google Meet", value: "google_meet" },
    //   { text: "AnyDesk", value: "any_desk" },
    //   { text: "No Preference", value: "no_preference" }
    // ],

    appointmentType: [
      { text: "Immediate Case Support", value: "immediate" },
      { text: "Final Restoration Support", value: "final_restoration" },
      { text: "First Time User Support", value: "first_time" },
      { text: "Other", value: "other" }
    ],

    bookingTimeZone: process.env.VUE_APP_BASE_TIMEZONE
  }),

  computed: {
    ...mapGetters(["practices"]),

    practiceNames() {
      return this.practices.map(practice => {
        return { text: practice.business_name, value: practice.id };
      });
    },

    selectedPractice() {
      if (this.supportRequest.practice_id) {
        return this.practices.filter(practice => {
          return practice.id === this.supportRequest.practice_id;
        })[0];
      }
      return false;
    },

    practiceMissingDetails() {
      if (
        (this.selectedPractice.phone &&
          this.selectedPractice.phone.length > 0) ||
        (this.selectedPractice.email && this.selectedPractice.email.length > 0)
      ) {
        return false;
      }
      return true;
    },

    differentZones() {
      return (
        this.bookingTimeZone !== this.timeZone &&
        this.theirAppointmentTime !== this.ourAppointmentTime
      );
    },

    timeZone() {
      return DateTime.local().zoneName;
    },

    theirAppointmentTime() {
      return DateTime.fromJSDate(
        this.supportRequest.desired_appointment
      ).toLocaleString(DateTime.DATETIME_HUGE);
    },

    ourAppointmentTime() {
      return DateTime.fromJSDate(this.supportRequest.desired_appointment)
        .setZone(this.bookingTimeZone)
        .toLocaleString(DateTime.DATETIME_HUGE);
    }
  },

  methods: {
    ...mapActions(["fetchUser"]),

    async submit() {
      if (this.$refs.supportForm.validate() && !this.practiceMissingDetails) {
        this.loading = true;
        this.$httpClient
          .createSupport(this.supportRequest)
          .then(() => {
            this.$router.push({
              name: "support-confirmed",
              params: { platform: this.supportRequest.screen_sharing_platform }
            });
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
          });
      }
    }
  },

  mounted() {
    this.loading = false;
  }
};
</script>

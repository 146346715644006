<template>
  <v-container>
    <v-container class="text-center">
      <h1 class="pa-5">
        {{ $t("nexusios.chairsideSupport.supportConfirm.thankYouForBooking") }}
        <!-- Thank You for Booking -->
      </h1>
      <p>
        {{ $t("nexusios.chairsideSupport.supportConfirm.weWillConfirm") }}
        <!-- We will confirm your requested booking time with you via email. -->
      </p>
    </v-container>
    <v-container v-if="platform === 'google_meet'">
      <v-row>
        <v-col cols="12" md="4" class="text-right">
          <img
            src="../assets/graphics/meet.png"
            class="white"
            style="width: 128px;"
          />
        </v-col>
        <v-col cols="12" md="8">
          <h4 class="pb-10">
            {{
              $t("nexusios.chairsideSupport.supportConfirm.meetViaGoogleMeet")
            }}
            <!-- You have requested to meet with us via Google Meet. -->
          </h4>
          {{
            $t("nexusios.chairsideSupport.supportConfirm.googleMeetRequires")
          }}
          <!-- Google Meet requires no additional software to be installed on your -->
          <!-- machine. -->
          <a href="https://meet.google.com" target="_blank">
            {{ $t("nexusios.chairsideSupport.supportConfirm.clickHere") }}
            <!-- Click here -->
          </a>
          {{
            $t("nexusios.chairsideSupport.supportConfirm.followToFreeDownload")
          }}
          <!-- for more information. -->
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="platform === 'teamviewer'">
      <v-row>
        <v-col cols="12" md="4" class="text-right">
          <img
            src="../assets/graphics/teamviewer.png"
            class="white"
            style="width: 128px;"
          />
        </v-col>
        <v-col cols="12" md="8">
          <h4 class="pb-10">
            {{
              $t("nexusios.chairsideSupport.supportConfirm.teamViewerRequest")
            }}
            <!-- You have requested to meet with us via TeamViewer. -->
          </h4>
          {{ $t("nexusios.chairsideSupport.supportConfirm.toObtainSoftware") }}
          <!-- To obtain a latest copy of this software, please -->
          <a href="https://www.teamviewer.com" target="_blank">
            {{ $t("nexusios.chairsideSupport.supportConfirm.clickHere") }}
            <!-- click here -->
          </a>
          {{ $t("nexusios.chairsideSupport.supportConfirm.followToDownload") }}
          <!-- and follow the links to "Download". -->
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="platform === 'any_desk'">
      <v-row>
        <v-col cols="12" md="4" class="text-right">
          <img
            src="../assets/graphics/anydesk.png"
            class="white"
            style="width: 128px;"
          />
        </v-col>
        <v-col cols="12" md="8">
          <h4 class="pb-10">
            {{ $t("nexusios.chairsideSupport.supportConfirm.anyDeskRequest") }}
            <!-- You have requested to meet with us via AnyDesk. -->
          </h4>
          {{ $t("nexusios.chairsideSupport.supportConfirm.toObtainSoftware") }}
          <!-- To obtain a latest copy of this software, please -->
          <a href="https://anydesk.com" target="_blank">
            {{ $t("nexusios.chairsideSupport.supportConfirm.clickHere") }}
            <!-- click here -->
          </a>
          {{
            $t("nexusios.chairsideSupport.supportConfirm.followToFreeDownload")
          }}
          <!-- and follow the links to "Free Download". -->
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "SupportConfirm",

  props: ["platform"]
};
</script>

<template>
  <v-col sm="12" md="6" lg="4" class="ma-0 pa-2">
    <v-card
      v-if="
        item.preop_status != 'Deleted' ||
          (item.definitive_status != 'Deleted' && item.preop_status)
      "
      tile
      class="ma-0 pa-8 d-flex flex-column"
      color="black"
      height="100%"
    >
      <v-card-title class="pa-0">
        <h4>{{ item.patient_name }}</h4>
        <v-spacer></v-spacer>
        <h4 class="float-right ma-0 pa-0">
          {{ prettyDateTime(item.order_date) }}
        </h4>
      </v-card-title>
      <v-card-title class="pa-0">
        <v-card-subtitle class="pa-0">{{
          getCorrectIntended(item.intended_description)
        }}</v-card-subtitle>
        <v-spacer></v-spacer>
        <v-card-subtitle class="ma-0 pa-0 float-right">
          {{ setCaseId.oep_case_mkey }} ({{ setCaseId.oep_case_id }})
        </v-card-subtitle>
      </v-card-title>
      <details-card
        class="mt-2 mb-4"
        :item="item"
        :preop="preop"
        :immediate="immediate"
        :definitive="definitive"
        :isContinue="isContinue"
        :isDelete="isDelete"
        v-on:deleteOrder="deleteOrder()"
      ></details-card>
      <v-spacer></v-spacer>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="continueOrder"
          v-if="preop.stage_status != 'Pending' && isContinue"
        >
          <v-icon small class="mr-2">mdi-cart</v-icon>
          {{ immediateContinueBtn() }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { DateTime } from "luxon";
import DetailsCard from "./DetailsCard";

export default {
  name: "PatientCard",

  components: {
    "details-card": DetailsCard
  },

  props: ["item", "isContinue", "isDelete"],

  data() {
    return {
      show: false
    };
  },

  computed: {
    preop() {
      for (this.detail of this.item.details) {
        if (this.detail.id == this.item.detail_id) return this.detail;
      }
      return {};
    },
    immediate() {
      for (this.detail of this.item.details) {
        if (this.detail.id == this.item.immediate_id) return this.detail;
      }
      return {};
    },
    definitive() {
      for (this.detail of this.item.details) {
        if (this.detail.id == this.item.definitive_id) return this.detail;
      }
      return {};
    },
    setCaseId() {
      let currentOEPCaseId = this.item.details[0].oep_case_id;
      let currentOEPCaseMkey = this.item.details[0].oep_case_mkey;
      for (this.detail of this.item.details) {
        if (
          (this.detail.intended == "immediate-restoration-order") &
          (this.detail.stage_status !== null)
        ) {
          currentOEPCaseId = this.detail.oep_case_id;
          currentOEPCaseMkey = this.detail.oep_case_mkey;
        }
      }
      currentOEPCaseId =
        currentOEPCaseId == null
          ? this.$t("nexusios.patientOrder.status.pending")
          : currentOEPCaseId;
      return {
        oep_case_id: currentOEPCaseId,
        oep_case_mkey: currentOEPCaseMkey
      };
    }
  },
  methods: {
    deleteOrder() {
      this.$emit("deleteOrder");
    },
    continueOrder() {
      this.$emit("continueOrder");
    },
    immediateContinueBtn() {
      return this.item.preop_status == "Incomplete"
        ? this.$t("nexusios.patientOrder.continuePreOp")
        : this.item.immediate_status == "Incomplete"
        ? this.$t("nexusios.patientOrder.continueImmediate")
        : this.item.definitive_status == "Incomplete" &&
          this.item.intended == "immediate-restoration-order"
        ? this.$t("nexusios.patientOrder.continueDefinitive")
        : this.item.definitive_status == "Incomplete" &&
          this.item.indication_type == "nexus-single-implant-crown"
        ? this.$t("nexusios.patientOrder.continueSingle")
        : this.item.definitive_status == "Incomplete" &&
          (this.item.indication_type == "nexus-micro-unit-bridge" ||
            this.item.indication_type == "custom-abutment-bridge")
        ? this.$t("nexusios.patientOrder.continueBridge")
        : this.item.definitive_status == "Incomplete" &&
          this.item.intended == "single-bridge-order"
        ? this.$t("nexusios.patientOrder.continueSingleBridge")
        : this.item.definitive_status == "Incomplete" &&
          this.item.intended == "full-arch-order"
        ? this.$t("nexusios.patientOrder.continueFullArch")
        : this.item.immediate_status == null &&
          this.item.intended == "immediate-restoration-order"
        ? this.$t("nexusios.patientOrder.orderImmediate")
        : this.item.definitive_status == null &&
          this.item.intended == "immediate-restoration-order"
        ? this.$t("nexusios.patientOrder.orderDefinitive")
        : this.$t("common.continue");
    },
    prettyDateTime(dateTime) {
      if (dateTime) {
        return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATE_SHORT);
      }
    },
    getCorrectIntended(intended) {
      if (!intended) {
        return "";
      }
      if (intended == "Preop & Immediacy") {
        return this.$t("nexusios.patientOrder.product.immediateRestoration");
      }
      if (intended == "Single Unit & Bridge Solutions") {
        return this.$t("nexusios.patientOrder.product.singleBridge");
      }
      if (intended == "Full Arch Definitive") {
        return this.$t("nexusios.patientOrder.product.fullArch");
      }
      return intended;
    }
  }
};
</script>

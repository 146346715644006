<template>
  <div class="flex">
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-container class="pa-12" v-if="!loading">
      <v-row>
        <v-col cols="12" md="6" class="text-center text-md-left">
          <h1 class="py-3">Unlock the power of Nexus IOS</h1>
          <p>
            Join the future of digital implant dentistry with one of the world’s
            most advanced digital solutions.
          </p>
        </v-col>
        <v-col cols="12" md="6" class="text-center text-md-left">
          <v-alert type="error" v-if="error">
            An unexpected error has occurred, please contact us for assistance.
          </v-alert>
          <v-form ref="registerForm">
            <v-card>
              <v-toolbar>About You</v-toolbar>
              <br />
              <v-container>
                <v-text-field
                  v-model="clientParams.name"
                  label="Full Name"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  v-model="clientParams.email"
                  label="Email Address"
                  outlined
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
                <v-text-field
                  v-model="clientParams.phone"
                  label="Phone Number"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
                <v-select
                  v-model="clientParams.country"
                  label="Country"
                  outlined
                  :items="countries"
                  :rules="[rules.required]"
                ></v-select>
              </v-container>
            </v-card>
            <br />
            <v-card>
              <v-toolbar>Practice Details</v-toolbar>
              <br />
              <v-container>
                <v-text-field
                  v-model="practiceParams.business_name"
                  label="Practice Name"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  v-model="practiceParams.contact_person"
                  label="Contact person"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="practiceParams.email"
                  label="Email Address"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="practiceParams.phone"
                  label="Phone Number"
                  outlined
                ></v-text-field>
              </v-container>
            </v-card>
            <br />
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn large class="m" color="primary" @click="submit">
                  Register
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { validationRequired, validationEmail } from "../shared/ValidationRules";
import { mapGetters, mapActions } from "vuex";
import { getNames } from "country-list";

export default {
  name: "Register",

  data: () => ({
    loading: true,
    error: false,

    rules: {
      required: validationRequired,
      email: validationEmail
    },

    clientParams: {},
    practiceParams: {}
  }),

  computed: {
    ...mapGetters(["user", "client", "approvedClient"]),

    countries() {
      return getNames().sort();
    }
  },

  methods: {
    ...mapActions(["fetchUser"]),

    verifyCanRegister() {
      if (!this.user) {
        document.location = "/auth/auth0";
      } else if (this.approvedClient) {
        this.$router.push({ name: "order" });
      } else if (this.client) {
        this.$router.push({ name: "register-confirmed" });
      }
      this.loading = false;
    },

    preFillForm() {
      this.clientParams.name = this.user.full_name;
      this.clientParams.email = this.user.email;
    },

    async submit() {
      if (this.$refs.registerForm.validate()) {
        this.loading = true;
        // TODO: Capture the selected language when i18n is supported.
        this.clientParams["language"] = "en";
        this.$httpClient
          .register(this.clientParams, this.practiceParams)
          .then(async () => {
            await this.fetchUser().then(() => {
              this.$router.push({ name: "register-confirmed" });
            });
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
          });
      }
    }
  },

  async mounted() {
    this.verifyCanRegister();
    this.preFillForm();
  }
};
</script>

<template>
  <v-btn-toggle
    v-model="baseMaterial"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="baseMaterialSelected"
  >
    <v-btn class="ma-1" value="acrylic" :disabled="disabled">
      {{ $t("material.acrylic") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "BaseMaterial",

  props: ["value", "disabled"],

  data() {
    return {
      baseMaterial: this.value
    };
  },

  methods: {
    baseMaterialSelected() {
      this.$emit("input", this.baseMaterial);
    }
  }
};
</script>

<template>
  <v-container class="pa-8">
    <v-stepper v-model="step" vertical dark non-linear>
      <!-- Attachments -->
      <v-stepper-step
        v-if="options.includes('attachments')"
        editable
        :complete="isEntered('attachments')"
        edit-icon="mdi-check"
        :step="getStepNumber('attachments')"
      >
        {{ $t("common.attachments") }}
        <!-- Attachments -->
        <small class="pt-3" v-if="value.attachments">
          {{
            $t(`nexusios.orderNow.orderOptions.${value.attachments}`)
              | capitalizeAll
          }}
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('attachments')"
        :step="getStepNumber('attachments')"
      >
        <Attachments
          v-model="value.attachments"
          :disabled="attachmentsDisabled"
          @input="nextStep"
        ></Attachments>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Base Material -->
      <v-stepper-step
        v-if="options.includes('base-material')"
        editable
        :complete="isEntered('base-material')"
        edit-icon="mdi-check"
        :step="getStepNumber('base-material')"
      >
        {{ $t("productOptions.baseMaterial") }}
        <!-- Base Material -->
        <small class="pt-3" v-if="value.baseMaterial">
          {{
            $t(`nexusios.orderNow.orderOptions.${value.baseMaterial}`)
              | capitalizeAll
          }}
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('base-material')"
        :step="getStepNumber('base-material')"
      >
        <BaseMaterial
          v-model="value.baseMaterial"
          :disabled="baseMaterialDisabled"
          @input="nextStep"
        ></BaseMaterial>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Bar Material -->
      <v-stepper-step
        v-if="options.includes('bar-material')"
        editable
        :complete="isEntered('bar-material')"
        edit-icon="mdi-check"
        :step="getStepNumber('bar-material')"
        :rules="[barMaterialValidator]"
      >
        <span v-if="!isSingleOrBridge">
          {{ $t("productOptions.barMaterial") }}
          <!-- Bar Material -->
        </span>
        <span v-if="isSingleOrBridge">
          {{ $t("nexusios.orderNow.abutmentMaterial") }}
        </span>
        <small class="pt-3">
          <span v-if="value.barMaterial">
            {{
              $t(`nexusios.orderNow.orderOptions.${value.barMaterial}`)
                | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('bar-material')"
        :step="getStepNumber('bar-material')"
      >
        <BarMaterialSelections
          v-model="value.barMaterial"
          :options="barMaterialOptions"
          :disabled="barMaterialDisabled"
          @input="nextStep"
          :barMaterial="value.barMaterial"
        ></BarMaterialSelections>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Bar Tinting -->
      <v-stepper-step
        v-if="options.includes('bar-tinting')"
        editable
        :complete="isEntered('bar-tinting')"
        edit-icon="mdi-check"
        :step="getStepNumber('bar-tinting')"
      >
        <span v-if="!isSingleOrBridge">
          {{ $t("productOptions.barTinting") }}
          <!-- Bar Tinting -->
        </span>
        <span v-if="isSingleOrBridge">
          {{ $t("productOptions.abutmentTinting") }}
        </span>
        <small class="pt-3">
          <span v-if="value.barTinting">
            {{
              $t(`nexusios.orderNow.orderOptions.${value.barTinting}`)
                | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('bar-tinting')"
        :step="getStepNumber('bar-tinting')"
      >
        <BarTintingSelections
          v-model="value.barTinting"
          :barMaterial="value.barMaterial"
          :options="barTintingOptions"
          :disabled="barTintingDisabled"
          @input="nextStep"
        ></BarTintingSelections>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Titanium Telescopic Framework (prev sleeve) -->
      <v-stepper-step
        v-if="options.includes('titanium-sleeve-superstructure')"
        editable
        :complete="isEntered('titanium-sleeve-superstructure')"
        edit-icon="mdi-check"
        :step="getStepNumber('titanium-sleeve-superstructure')"
      >
        {{ $t("warning.telescopicFrameworkRequired") }}
        <!-- Telescopic Framework Required -->
        <small class="pt-3">
          <span v-if="value.titaniumSleeveSuperstructure">
            {{
              $t(
                `nexusios.orderNow.orderOptions.${value.titaniumSleeveSuperstructure}`
              ) | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('titanium-sleeve-superstructure')"
        :step="getStepNumber('titanium-sleeve-superstructure')"
      >
        <TitaniumSleeveSuperstructure
          v-model="value.titaniumSleeveSuperstructure"
          :disabled="titaniumSleeveSuperstructureDisabled"
          @input="nextStep"
        ></TitaniumSleeveSuperstructure>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Tooth/Overlay material -->
      <v-stepper-step
        v-if="options.includes('overlay-material')"
        editable
        :complete="isEntered('overlay-material')"
        edit-icon="mdi-check"
        :step="getStepNumber('overlay-material')"
        :rules="[overlayMaterialValidator]"
      >
        {{ $t("productOptions.toothMaterial") }}
        <!-- Overlay Material -->
        <small class="pt-3">
          <span v-if="value.overlayMaterial">
            {{
              $t(`nexusios.orderNow.orderOptions.${value.overlayMaterial}`)
                | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('overlay-material')"
        :step="getStepNumber('overlay-material')"
      >
        <OverlayMaterialSelections
          v-model="value.overlayMaterial"
          :barMaterial="value.barMaterial"
          :disabled="overlayMaterialDisabled"
          :options="overlayMaterialOptions"
          @input="nextStep"
        ></OverlayMaterialSelections>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Arches Restored -->
      <v-stepper-step
        v-if="options.includes('arches-restored')"
        editable
        :complete="isEntered('arches-restored')"
        edit-icon="mdi-check"
        :step="getStepNumber('arches-restored')"
      >
        {{ $t("productOptions.archesToBeRestored") }}
        <!-- Arch(es) to be Restored -->
        <small class="pt-3">
          <span v-if="value.archesRestored">
            {{
              $t(`nexusios.orderNow.orderOptions.${value.archesRestored}`)
                | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('arches-restored')"
        :step="getStepNumber('arches-restored')"
      >
        <ArchesRestored
          v-model="value.archesRestored"
          @input="nextStepDisabled(virtualSmileDesignDisabled())"
        ></ArchesRestored>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn
            color="primary"
            @click="nextStepDisabled(virtualSmileDesignDisabled())"
          >
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- virtual-smile-design -->
      <v-stepper-step
        v-if="options.includes('virtual-smile-design')"
        editable
        :complete="isEntered('virtual-smile-design')"
        edit-icon="mdi-check"
        :step="getStepNumber('virtual-smile-design')"
      >
        {{ $t("products.virtualSmileDesign") }}
        <!-- Virtual Smile Design -->
        <small v-if="value.virtualSmileDesign" class="pt-3">
          {{
            $t(`nexusios.orderNow.orderOptions.${value.virtualSmileDesign}`)
              | capitalizeAll
          }}
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('virtual-smile-design')"
        :step="getStepNumber('virtual-smile-design')"
      >
        <VirtualSmileDesign
          v-model="value.virtualSmileDesign"
          :disabled="virtualSmileDesignDisabled()"
          @input="nextStep"
        ></VirtualSmileDesign>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- surgical-scan-template -->
      <v-stepper-step
        v-if="options.includes('surgical-scan-template')"
        editable
        :complete="isEntered('surgical-scan-template')"
        edit-icon="mdi-check"
        :step="getStepNumber('surgical-scan-template')"
      >
        {{ $t("productOptions.surgicalScanTemplateSelections.label") }}
        <!-- Surgical Scan Template -->
        <small class="pt-3">
          <span v-if="value.surgicalScanTemplate">
            {{
              $t(
                `nexusios.orderNow.orderOptions.${value.surgicalScanTemplate}`
              ) | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('surgical-scan-template')"
        :step="getStepNumber('surgical-scan-template')"
      >
        <SurgicalScanTemplateSelections
          v-model="value.surgicalScanTemplate"
          :disabled="surgicalScanTemplateDisabled()"
          @input="nextStep"
        ></SurgicalScanTemplateSelections>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- restorative-protocol -->
      <v-stepper-step
        v-if="options.includes('restorative-protocol')"
        editable
        :complete="isEntered('restorative-protocol')"
        edit-icon="mdi-check"
        :step="getStepNumber('restorative-protocol')"
      >
        {{ $t("productOptions.intededRestorativeProtocol") }}
        <!-- Intended Restorative Protocol -->
        <small class="pt-3">
          <span v-if="value.restorativeProtocol">
            {{ value.restorativeProtocol | capitalizeAll }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('restorative-protocol')"
        :step="getStepNumber('restorative-protocol')"
      >
        <RestorativeProtocol
          v-model="value.restorativeProtocol"
          :disabled="restorativeProtocolDisabled()"
          @input="nextStep"
        ></RestorativeProtocol>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- components-required -->
      <v-stepper-step
        v-if="options.includes('components-required')"
        editable
        :complete="isEntered('components-required')"
        edit-icon="mdi-check"
        :step="getStepNumber('components-required')"
      >
        Do you have required components or would you like to add the necessary
        case components to your order?*
        <small class="pt-3">
          <span v-if="value.componentsRequired">
            {{ value.componentsRequired | capitalizeAll }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('components-required')"
        :step="getStepNumber('components-required')"
      >
        <RequiredComponents
          v-model="value.componentsRequired"
          :disabled="componentsRequiredDisabled()"
        />
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Interim Denture -->
      <v-stepper-step
        v-if="options.includes('interim-denture')"
        editable
        :complete="isEntered('interim-denture')"
        edit-icon="mdi-check"
        :rules="[interimDentureValidator]"
        :step="getStepNumber('interim-denture')"
      >
        {{ $t("productOptions.interimDenture") }}
        <small class="pt-3">
          <span v-if="value.interimDenture">
            {{
              $t(`nexusios.orderNow.orderOptions.${value.interimDenture}`)
                | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('interim-denture')"
        :step="getStepNumber('interim-denture')"
      >
        <InterimDenture v-model="value.interimDenture" @input="nextStep" />
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Tooth shade -->
      <v-stepper-step
        v-if="options.includes('tooth-shade')"
        editable
        :complete="isEntered('tooth-shade')"
        edit-icon="mdi-check"
        :rules="[toothShadeValidator]"
        :step="getStepNumber('tooth-shade')"
      >
        {{ $t("productOptions.toothShade") }}
        <!-- Tooth Shade -->
        <small class="pt-3" v-if="value.toothShade">
          {{
            $t(`nexusios.orderNow.orderOptions.${value.toothShade}`)
              | capitalizeAll
          }}
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('tooth-shade')"
        :step="getStepNumber('tooth-shade')"
      >
        <ToothShade
          v-model="value.toothShade"
          :disabled="toothShadeDisabled"
          @input="toothShadeNextStep"
        ></ToothShade>
        <v-text-field
          class="mx-1 my-2 elevation-0"
          :label="$t('nexusios.orderNow.specifyColourShade')"
          solo
          light
          v-model="value.toothShadeCustom"
          v-if="value.toothShade == 'other'"
        ></v-text-field>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Pink gingival colour -->
      <v-stepper-step
        v-if="options.includes('pink-gingival-colour')"
        editable
        :complete="isEntered('pink-gingival-colour')"
        edit-icon="mdi-check"
        :step="getStepNumber('pink-gingival-colour')"
      >
        {{ $t("productOptions.pinkGingivalColour.pinkGingivalColour") }}
        <!-- Pink Gingival Colour -->
        <small class="pt-3" v-if="value.pinkGingivalColour">{{
          $t(`nexusios.orderNow.orderOptions.${value.pinkGingivalColour}`)
            | capitalizeAll
        }}</small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('pink-gingival-colour')"
        :step="getStepNumber('pink-gingival-colour')"
      >
        <PinkGingivalColour
          v-model="value.pinkGingivalColour"
          :disabled="pinkGingivalColourDisabled"
          :indication-type="indicationType"
          @input="nextStep"
        ></PinkGingivalColour>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Pontic design -->
      <v-stepper-step
        v-if="options.includes('pontic-design')"
        editable
        :complete="isEntered('pontic-design')"
        edit-icon="mdi-check"
        :step="getStepNumber('pontic-design')"
      >
        {{ $t("productOptions.ponticDesign.ponticDesign") }}
        <!-- Pontic Design -->
        <small class="pt-3" v-if="value.ponticDesign">{{
          $t(`nexusios.orderNow.orderOptions.${value.ponticDesign}`)
            | capitalizeAll
        }}</small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('pontic-design')"
        :step="getStepNumber('pontic-design')"
      >
        <PonticDesignSelections
          v-model="value.ponticDesign"
          :disabled="ponticDesignDisabled"
          @input="nextStep"
        ></PonticDesignSelections>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- Distal extensions -->
      <v-stepper-step
        v-if="options.includes('distal-extensions')"
        editable
        :complete="isEntered('distal-extensions')"
        edit-icon="mdi-check"
        :step="getStepNumber('distal-extensions')"
      >
        {{ $t("productOptions.distalExtensions.distalExtensions") }}
        <!-- Distal Extensions -->
        <small class="pt-3">
          <span v-if="value.distalExtensionsPatientsLeft">
            <strong>
              {{ $t("common.left") }}
              :</strong
            >
            {{
              $t(
                `nexusios.orderNow.orderOptions.${value.distalExtensionsPatientsLeft}`
              ) | capitalizeAll
            }}
          </span>
          <span v-if="value.distalExtensionsPatientsRight">
            <strong>
              {{ $t("common.right") }}
              :</strong
            >
            {{
              $t(
                `nexusios.orderNow.orderOptions.${value.distalExtensionsPatientsRight}`
              ) | capitalizeAll
            }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('distal-extensions')"
        :step="getStepNumber('distal-extensions')"
      >
        <v-row
          ><v-col>
            <p class="my-2">
              {{ $t("common.patientsLeft") }}
              <!-- Patient's Left -->
            </p>
            <DistalExtension
              v-model="value.distalExtensionsPatientsLeft"
              left_or_right="left"
              :disabled="distalExtensionsDisabled"
              @input="distalExtensionsNextStep"
            ></DistalExtension>
          </v-col>
          <v-col>
            <p class="my-2">
              {{ $t("common.patientsRight") }}
              <!-- Patient's Right -->
            </p>
            <DistalExtension
              v-model="value.distalExtensionsPatientsRight"
              left_or_right="right"
              :disabled="distalExtensionsDisabled"
              @input="distalExtensionsNextStep"
            ></DistalExtension>
          </v-col>
        </v-row>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Tissue relationship distance -->
      <v-stepper-step
        v-if="options.includes('tissue-relationship-distance')"
        editable
        :complete="isEntered('tissue-relationship-distance')"
        edit-icon="mdi-check"
        :step="getStepNumber('tissue-relationship-distance')"
      >
        {{
          $t(
            "productOptions.tissueRelationshipDistance.tissueRelationshipDistance"
          )
        }}
        <!-- Tissue Relationship Distance -->
        <small class="pt-3" v-if="value.tissueRelationshipDistance">
          {{
            $t(
              `nexusios.orderNow.orderOptions.${value.tissueRelationshipDistance}`
            ) | capitalizeAll
          }}
          <span v-if="value.tissueRelationshipDistance == 'specify'">
            -
            {{
              $t(
                `nexusios.orderNow.orderOptions.${value.tissueRelationshipDistanceSpecify}`
              )
            }}mm
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('tissue-relationship-distance')"
        :step="getStepNumber('tissue-relationship-distance')"
      >
        <TissueRelationship
          v-model="value.tissueRelationshipDistance"
          :disabled="tissueRelationshipDistanceDisabled"
          @input="tissueRelationshipDistanceNextStep"
        ></TissueRelationship>
        <v-text-field
          class="mx-1 my-2 elevation-0"
          label="Distance ( + Offset) ( - Compression)"
          suffix="mm"
          solo
          light
          v-model="value.tissueRelationshipDistanceSpecify"
          v-if="value.tissueRelationshipDistance == 'specify'"
        ></v-text-field>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Printed try in required -->
      <v-stepper-step
        v-if="options.includes('printed-try-in-required')"
        editable
        :complete="isEntered('printed-try-in-required')"
        edit-icon="mdi-check"
        :rules="[printedTryInRequiredValidator]"
        :step="getStepNumber('printed-try-in-required')"
      >
        {{ $t("productOptions.3DPrintedTryIn") }}
        <!-- 3D Printed Try In -->
        <small class="pt-3" v-if="value.printedTryInRequired">
          {{
            $t(`nexusios.orderNow.orderOptions.${value.printedTryInRequired}`)
          }}
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('printed-try-in-required')"
        :step="getStepNumber('printed-try-in-required')"
      >
        <TryIn
          v-model="value.printedTryInRequired"
          @input="nextStep"
          @change="setDesignOnly"
        ></TryIn>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- rent-scan-gauge-kit -->
      <v-stepper-step
        v-if="options.includes('rent-scan-gauge-kit')"
        editable
        :complete="isEntered('rent-scan-gauge-kit')"
        edit-icon="mdi-check"
        :step="getStepNumber('rent-scan-gauge-kit')"
      >
        {{ $t("nexusios.orderNow.wouldYouLikeRentScanGauge") }}
        <!-- Would you like to rent a Scan Gauge Kit for this surgery? -->
        <small class="pt-3" v-if="value.rentScanGaugeKit">
          {{
            $t(`nexusios.orderNow.orderOptions.${value.rentScanGaugeKit}`)
              | capitalizeAll
          }}
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('rent-scan-gauge-kit')"
        :step="getStepNumber('rent-scan-gauge-kit')"
      >
        <RentScanGaugeKit
          v-model="value.rentScanGaugeKit"
          @input="nextStep"
        ></RentScanGaugeKit>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Prefered Turn-around Time -->
      <v-stepper-step
        v-if="options.includes('preferred-turnaround-time')"
        editable
        :complete="isEntered('preferred-turnaround-time')"
        edit-icon="mdi-check"
        :step="getStepNumber('preferred-turnaround-time')"
      >
        Preferred Turnaround Time
        <small class="pt-3" v-if="value.preferredTurnaroundTime">
          {{ value.preferredTurnaroundTime }}
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('preferred-turnaround-time')"
        :step="getStepNumber('preferred-turnaround-time')"
      >
        <v-btn-toggle
          v-model="value.preferredTurnaroundTime"
          class="d-flex flex-wrap"
          active-class="active-button"
          borderless
          background-color="rgba(0,0,0,0)"
        >
          <v-btn class="ma-1" value="Same Day">
            Same Day
          </v-btn>
          <v-btn class="ma-1" value="Next Day">
            Next Day
          </v-btn>
        </v-btn-toggle>

        <p class="mt-5">
          <small>
            Immediate orders must have an Approved Virtual Smile Design (VSD)
            prior to ordering the restoration. For more information and ordering
            cutoff times
            <a
              href="https://www.nexusios.com/provisional-cutoff-times"
              target="_blank"
            >
              click here.
            </a>
          </small>
        </p>

        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Connection Type -->
      <v-stepper-step
        v-if="options.includes('connection-type')"
        editable
        :complete="isEntered('connection-type')"
        edit-icon="mdi-check"
        :step="getStepNumber('connection-type')"
      >
        Connection Type
        <small class="pt-3" v-if="value.connectionType">
          {{ value.connectionType }}
        </small>
      </v-stepper-step>
      <v-stepper-content
        v-if="options.includes('connection-type')"
        :step="getStepNumber('connection-type')"
      >
        <v-btn-toggle
          v-model="value.connectionType"
          class="d-flex flex-wrap"
          active-class="active-button"
          borderless
          background-color="rgba(0,0,0,0)"
        >
          <v-btn class="ma-1" value="Ti Base to MUA">
            Ti Base to MUA
          </v-btn>
          <v-btn class="ma-1" value="Direct to MUA">
            Direct to MUA
          </v-btn>
        </v-btn-toggle>

        <p class="mt-5">
          <small>
            If printed by a 3rd party, the clinician is responsible for accurate
            production and providing all necessary safety instructions to the
            patient.
          </small>
        </p>

        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- History of Bruxism -->
      <v-stepper-step
        v-if="options.includes('bruxism')"
        editable
        :complete="isEntered('bruxism')"
        edit-icon="mdi-check"
        :step="getStepNumber('bruxism')"
      >
        Does the patient have any history of bruxism?
        <!-- History of Bruxism -->
        <small class="pt-3" v-if="value.bruxism">
          {{
            $t(`nexusios.orderNow.orderOptions.${value.bruxism}`)
              | capitalizeAll
          }}
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('bruxism')"
        :step="getStepNumber('bruxism')"
      >
        <HistoryOfBruxism v-model="value.bruxism"></HistoryOfBruxism>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- -------------------------------------------------------- -->
      <!-- Screw Retained Provisional -->
      <v-stepper-step
        v-if="options.includes('screw-retained-provisional')"
        editable
        :complete="isEntered('screw-retained-provisional')"
        edit-icon="mdi-check"
        :step="getStepNumber('screw-retained-provisional')"
      >
        Do you want an interim screw-retained provisional before the final
        prosthesis?
        <!-- Screw Retained Provisional -->
        <small class="pt-3" v-if="value.screwRetainedProvisional">
          {{
            $t(
              `nexusios.orderNow.orderOptions.${value.screwRetainedProvisional}`
            ) | capitalizeAll
          }}
        </small>
      </v-stepper-step>

      <v-stepper-content
        v-if="options.includes('screw-retained-provisional')"
        :step="getStepNumber('screw-retained-provisional')"
      >
        <ScrewRetainedProvisional
          v-model="value.screwRetainedProvisional"
          @input="nextStep"
        ></ScrewRetainedProvisional>
        <div class="text-right pt-5" v-if="hasNextStep()">
          <v-btn color="primary" @click="nextStep">
            {{ $t("common.continue") }}
            <!-- Continue -->
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import BarMaterialSelections from "./BarMaterialSelections";
import Attachments from "../ProductOptions/Attachments";
import BaseMaterial from "../ProductOptions/BaseMaterial";
import BarTintingSelections from "./BarTintingSelections";
import TitaniumSleeveSuperstructure from "../ProductOptions/TitaniumSleeveSuperstructure";
import OverlayMaterialSelections from "./OverlayMaterialSelections";
import ToothShade from "../ProductOptions/ToothShade";
import PinkGingivalColour from "../ProductOptions/PinkGingivalColour";
import PonticDesignSelections from "./PonticDesignSelections";
import DistalExtension from "../ProductOptions/DistalExtension";
import TissueRelationship from "../ProductOptions/TissueRelationship";
import TryIn from "../ProductOptions/TryIn";
import VirtualSmileDesign from "../ProductOptions/VirtualSmileDesign";
import ArchesRestored from "../ProductOptions/ArchesRestored";
import SurgicalScanTemplateSelections from "./SurgicalScanTemplateSelections";
import RequiredComponents from "../ProductOptions/RequiredComponents";
import RestorativeProtocol from "../ProductOptions/RestorativeProtocol";
import RentScanGaugeKit from "../ProductOptions/RentScanGaugeKit";
import InterimDenture from "../ProductOptions/InterimDenture";
import HistoryOfBruxism from "../ProductOptions/HistoryOfBruxism";
import ScrewRetainedProvisional from "../ProductOptions/ScrewRetainedProvisional";

export default {
  name: "ProductOptionSelection",

  props: ["value", "indicationType"],

  components: {
    BarMaterialSelections,
    Attachments,
    BaseMaterial,
    BarTintingSelections,
    TitaniumSleeveSuperstructure,
    OverlayMaterialSelections,
    ToothShade,
    PinkGingivalColour,
    PonticDesignSelections,
    DistalExtension,
    TissueRelationship,
    TryIn,
    VirtualSmileDesign,
    ArchesRestored,
    SurgicalScanTemplateSelections,
    RequiredComponents,
    RestorativeProtocol,
    RentScanGaugeKit,
    InterimDenture,
    HistoryOfBruxism,
    ScrewRetainedProvisional
  },

  data() {
    return {
      step: 1
    };
  },

  computed: {
    barDesign() {
      return (
        this.options.includes("bar-material") ||
        this.options.includes("bar-tinting")
      );
    },

    overlayDesign() {
      return (
        this.options.includes("overlay-material") ||
        this.options.includes("tooth-shade") ||
        this.options.includes("pink-gingival-colour") ||
        this.options.includes("distal-extensions") ||
        this.options.includes("tissue-relationship-distance") ||
        this.options.includes("titanium-sleeve-superstructure")
      );
    },

    additionalOptions() {
      return this.options.includes("printed-try-in-required");
    },

    options() {
      switch (this.indicationType) {
        case "design-only-immediate":
          return ["preferred-turnaround-time", "connection-type"];
        case "nexus-hybrid":
          return [
            "bar-material",
            "bar-tinting",
            "overlay-material",
            "tooth-shade",
            "pink-gingival-colour",
            "distal-extensions",
            "tissue-relationship-distance",
            "printed-try-in-required",
            "bruxism",
            "screw-retained-provisional"
          ];
        case "nexus-bridge":
          return [
            "bar-material",
            "bar-tinting",
            "overlay-material",
            "tooth-shade",
            "pink-gingival-colour",
            "distal-extensions",
            "tissue-relationship-distance",
            "printed-try-in-required",
            "bruxism",
            "screw-retained-provisional"
          ];
        case "bridge-on-ti-base":
          return [
            "tooth-shade",
            "pink-gingival-colour",
            "distal-extensions",
            "printed-try-in-required"
          ];
        case "nexus-wraparound":
          return [
            "tooth-shade",
            "pink-gingival-colour",
            "distal-extensions",
            "printed-try-in-required",
            "bruxism",
            "screw-retained-provisional"
          ];
        case "nexus-removable":
          return [
            "overlay-material",
            "tooth-shade",
            "pink-gingival-colour",
            "distal-extensions",
            "tissue-relationship-distance",
            "printed-try-in-required",
            "bruxism"
          ];
        case "nexus-hra":
          return [
            "overlay-material",
            "tooth-shade",
            "pink-gingival-colour",
            "distal-extensions",
            "tissue-relationship-distance",
            "printed-try-in-required",
            "bruxism"
          ];
        case "overdenture-on-attachments":
          return [
            "attachments",
            "base-material",
            "tooth-shade",
            "pink-gingival-colour",
            "printed-try-in-required",
            "bruxism"
          ];
        case "nexus-reinforced": // Peek Reinforced Immediate
          return [
            "tooth-shade",
            "pink-gingival-colour",
            "distal-extensions",
            "bruxism"
          ];
        case "nexus-wraparound-immediate": // Titanium Reinforced Immediate
          return ["tooth-shade", "pink-gingival-colour", "distal-extensions"];
        case "bridge-on-ti-base-immediate": // Bridge on Ti Base
          return ["tooth-shade", "pink-gingival-colour"];
        case "pre-op":
          return [
            "arches-restored",
            "surgical-scan-template",
            "restorative-protocol",
            "interim-denture"
          ];
        case "nexus-single-implant-crown":
          return [
            "bar-material",
            "bar-tinting",
            "overlay-material",
            "tooth-shade"
          ];
        case "nexus-micro-unit-bridge":
          return [
            "bar-material",
            "bar-tinting",
            "overlay-material",
            "tooth-shade",
            "pink-gingival-colour",
            "pontic-design",
            "tissue-relationship-distance",
            "printed-try-in-required"
          ];
        case "custom-abutment-bridge":
          return [
            "bar-material",
            "bar-tinting",
            "overlay-material",
            "tooth-shade",
            "pink-gingival-colour",
            "pontic-design",
            "tissue-relationship-distance",
            "printed-try-in-required"
          ];
        default:
          return [];
      }
    },

    barMaterialOptions() {
      switch (this.indicationType) {
        case "nexus-hybrid":
          return ["titanium"];
        case "nexus-bridge":
          return ["titanium"];
        case "bridge-on-ti-base":
          return ["titanium"];
        case "nexus-wraparound":
          return ["titanium"];
        case "nexus-removable":
          return ["titanium"];
        case "nexus-hra":
          return ["titanium"];
        case "overdenture-on-attachments":
          return ["titanium"];
        case "nexus-reinforced":
          return ["peek"];
        case "nexus-wraparound-immediate":
          return ["titanium"];
        case "nexus-single-implant-crown":
          return ["titanium", "zirconia-abutment"];
        case "nexus-micro-unit-bridge":
          return ["titanium"];
        case "custom-abutment-bridge":
          return ["titanium"];
        case "pre-op":
          return [];
        default:
          return [];
      }
    },

    barTintingOptions() {
      switch (this.indicationType) {
        case "nexus-hybrid":
          return ["gold"];
        case "nexus-bridge":
          return ["gold"];
        case "bridge-on-ti-base":
          return ["gold", "pink"];
        case "nexus-wraparound":
          return ["gold"];
        case "nexus-removable":
          return ["gold"];
        case "nexus-hra":
          return ["gold"];
        case "overdenture-on-attachments":
          return ["gold"];
        case "nexus-reinforced":
          return ["gold"];
        case "bridge-on-ti-base-immediate":
          return ["gold", "pink"];
        case "nexus-wraparound-immediate":
          return ["gold", "pink"];
        case "nexus-single-implant-crown":
          return ["gold", "pink"];
        case "nexus-micro-unit-bridge":
          return ["gold"];
        case "custom-abutment-bridge":
          return ["gold", "pink"];
        case "pre-op":
          return [];
        default:
          return [];
      }
    },

    overlayMaterialOptions() {
      switch (this.indicationType) {
        case "nexus-hybrid":
          return ["acrylic", "zirconia-monolithic", "zirconia-layered"];
        case "nexus-bridge":
          return ["acrylic", "zirconia-monolithic", "zirconia-layered"];
        case "bridge-on-ti-base":
          return ["acrylic"];
        case "nexus-wraparound":
          return ["acrylic"];
        case "nexus-removable":
          return ["acrylic", "zirconia-monolithic"];
        case "nexus-hra":
          return ["acrylic", "zirconia-monolithic"];
        case "nexus-reinforced":
          return ["acrylic"];
        case "nexus-wraparound-immediate":
          return ["acrylic"];
        case "bridge-on-ti-base-immediate":
          return ["acrylic"];
        case "nexus-single-implant-crown":
          return ["zirconia-monolithic", "zirconia-layered", "acrylic"];
        case "nexus-micro-unit-bridge":
          return ["zirconia-monolithic", "zirconia-layered", "acrylic"];
        case "custom-abutment-bridge":
          return ["zirconia-monolithic", "zirconia-layered", "acrylic"];
        case "pre-op":
          return [];
        default:
          return [];
      }
    },

    attachmentsDisabled() {
      return false;
    },

    baseMaterialDisabled() {
      return false;
    },

    barMaterialDisabled() {
      return false;
    },

    barTintingDisabled() {
      return false;
    },

    titaniumSleeveSuperstructureDisabled() {
      return false;
    },

    overlayMaterialDisabled() {
      return false;
    },

    toothShadeDisabled() {
      return false;
    },

    pinkGingivalColourDisabled() {
      return false;
    },

    pinkGingivalPorcelainDisabled() {
      return this.pinkGingivalColourDisabled;
    },

    ponticDesignDisabled() {
      return this.pinkGingivalPorcelainDisabled;
    },

    distalExtensionsDisabled() {
      return false;
    },

    tissueRelationshipDistanceDisabled() {
      return false;
    },

    isSingleOrBridge() {
      return (
        this.indicationType === "nexus-single-implant-crown" ||
        this.indicationType === "nexus-micro-unit-bridge" ||
        this.indicationType === "custom-abutment-bridge"
      );
    }
  },

  methods: {
    virtualSmileDesignDisabled() {
      let disabled = Boolean(
        Boolean(this.value.archesRestored == null) ||
          (this.value.archesRestored && this.value.archesRestored == "lower")
      );
      this.value.archesRestored && this.value.archesRestored == "lower"
        ? (this.value.virtualSmileDesign = "simple-design")
        : "";
      return disabled;
    },

    interimDentureDisabled() {
      return true;
      // let disabled = Boolean(
      //   Boolean(this.value.restorativeProtocol == null) ||
      //     (this.value.restorativeProtocol &&
      //       this.value.restorativeProtocol === "conversion-denture")
      // );

      // return disabled;
    },

    surgicalScanTemplateDisabled() {
      return (
        this.options.includes("arches-restored") &&
        this.value.archesRestored == null
      );
    },

    componentsRequiredDisabled() {
      if (
        this.value.restorativeProtocol &&
        this.value.restorativeProtocol.startsWith("design-only")
      ) {
        return false;
      }
      return true;
    },

    restorativeProtocolDisabled() {
      return (
        this.options.includes("restorative-protocol") &&
        this.value.archesRestored == null
      );
    },

    hasNextStep() {
      return this.options.length > this.step;
    },

    nextStep() {
      if (this.hasNextStep()) this.step += 1;
    },

    // steps over the next step if it is disabled
    // doesn't work if there are multiple steps to step over
    nextStepDisabled(nextStepDisabled) {
      let stepCount = 1;
      if (nextStepDisabled) {
        stepCount = 2;
      }
      if (this.hasNextStep()) {
        this.step += stepCount;
      }
    },

    getStepNumber(option) {
      return this.options.indexOf(option) + 1;
    },

    toothShadeNextStep() {
      if (
        this.value.toothShade !== undefined &&
        this.value.toothShade !== "other"
      ) {
        this.nextStep();
      }
    },

    distalExtensionsNextStep() {
      if (
        this.value.distalExtensionsPatientsLeft !== undefined &&
        this.value.distalExtensionsPatientsRight !== undefined
      ) {
        this.nextStep();
      }
    },

    tissueRelationshipDistanceNextStep() {
      if (
        this.value.tissueRelationshipDistance !== undefined &&
        this.value.tissueRelationshipDistance !== "specify"
      ) {
        this.nextStep();
      }
    },

    attachmentsValidator() {
      if (this.step > this.getStepNumber("attachments")) {
        return this.value.attachments !== undefined;
      } else {
        return true;
      }
    },

    baseMaterialValidator() {
      if (this.step > this.getStepNumber("base-material")) {
        return this.value.baseMaterial !== undefined;
      } else {
        return true;
      }
    },

    barMaterialValidator() {
      if (this.step > this.getStepNumber("bar-material")) {
        return this.value.barMaterial !== undefined;
      } else {
        return false;
      }
    },

    barTintingValidator() {
      if (this.step > this.getStepNumber("bar-tinting")) {
        return this.value.barTinting !== undefined;
      } else {
        return true;
      }
    },

    titaniumSleeveSuperstructureValidator() {
      if (this.step > this.getStepNumber("titanium-sleeve-superstructure")) {
        return this.value.titaniumSleeveSuperstructure !== undefined;
      } else {
        return true;
      }
    },

    overlayMaterialValidator() {
      if (this.step > this.getStepNumber("overlay-material")) {
        return this.value.overlayMaterial !== undefined;
      } else {
        return false;
      }
    },

    toothShadeValidator() {
      if (this.value.toothShade) {
        return true;
      } else {
        return false;
      }
    },

    pinkGingivalColourValidator() {
      if (this.step > this.getStepNumber("pink-gingival-colour")) {
        return this.value.pinkGingivalColour !== undefined;
      } else {
        return true;
      }
    },

    ponticDesignValidator() {
      if (this.step > this.getStepNumber("pontic-design")) {
        return this.value.ponticDesign !== undefined;
      } else {
        return true;
      }
    },

    distalExtensionsValidator() {
      if (this.step > this.getStepNumber("distal-extensions")) {
        return (
          this.value.distalExtensionsPatientsLeft !== undefined &&
          this.value.distalExtensionsPatientsRight !== undefined
        );
      } else {
        return true;
      }
    },

    tissueRelationshipDistanceValidator() {
      if (this.step > this.getStepNumber("tissue-relationship-distance")) {
        return this.value.tissueRelationshipDistance !== undefined;
      } else {
        return true;
      }
    },

    printedTryInRequiredValidator() {
      if (this.value.printedTryInRequired) {
        return true;
      } else {
        return false;
      }
    },

    archesRestoredValidator() {
      if (this.step > this.getStepNumber("arches-restored")) {
        return this.value.archesRestored !== undefined;
      } else {
        return true;
      }
    },

    virtualSmileDesignValidator() {
      if (this.step > this.getStepNumber("virtual-smile-design")) {
        return this.value.virtualSmileDesign !== undefined;
      } else {
        return true;
      }
    },

    surgicalScanTemplateValidator() {
      if (this.step > this.getStepNumber("surgical-scan-template")) {
        return this.value.surgicalScanTemplate !== undefined;
      } else {
        return true;
      }
    },

    restorativeProtocolValidator() {
      if (this.step > this.getStepNumber("restorative-protocol")) {
        return this.value.restorativeProtocol !== undefined;
      } else {
        return true;
      }
    },

    rentScanGaugeKitValidator() {
      if (this.step > this.getStepNumber("rent-scan-gauge-kit")) {
        return this.value.rentScanGaugeKit !== undefined;
      } else {
        return true;
      }
    },

    interimDentureValidator() {
      if (this.step > this.getStepNumber("interim-denture")) {
        return this.value.interimDenture !== undefined;
      } else {
        return true;
      }
    },

    isEntered(fieldName) {
      if (fieldName === "bruxism") {
        return this.value.bruxism ? true : false;
      }
      if (fieldName === "screw-retained-provisional") {
        return this.value.screwRetainedProvisional ? true : false;
      }
      if (fieldName === "prefered-turnaround-time") {
        return this.value.preferredTurnaroundTime ? true : false;
      }
      if (fieldName == "attachments") {
        return this.value.attachments ? true : false;
      }
      if (fieldName == "base-material") {
        return this.value.baseMaterial ? true : false;
      }
      if (fieldName == "bar-material") {
        return this.value.barMaterial ? true : false;
      }
      if (fieldName == "bar-tinting") {
        return this.value.barTinting ? true : false;
      }
      if (fieldName == "titanium-sleeve-superstructure") {
        return this.value.titaniumSleeveSuperstructure ? true : false;
      }
      if (fieldName == "overlay-material") {
        return this.value.overlayMaterial ? true : false;
      }
      if (fieldName == "arches-restored") {
        return this.value.archesRestored ? true : false;
      }
      if (fieldName == "virtual-smile-design") {
        return this.value.virtualSmileDesign ? true : false;
      }
      if (fieldName == "surgical-scan-template") {
        return this.value.surgicalScanTemplate ? true : false;
      }
      if (fieldName == "restorative-protocol") {
        return this.value.restorativeProtocol ? true : false;
      }
      if (fieldName == "interim-denture") {
        return this.value.interimDenture ? true : false;
      }
      if (fieldName == "tooth-shade") {
        return this.value.toothShade ? true : false;
      }
      if (fieldName == "pink-gingival-colour") {
        return this.value.pinkGingivalColour ? true : false;
      }
      if (fieldName == "pontic-design") {
        return this.value.ponticDesign ? true : false;
      }
      if (fieldName == "distal-extensions") {
        return this.value.distalExtensionsPatientsLeft
          ? this.value.distalExtensionsPatientsRight
            ? true
            : false
          : false;
      }
      if (fieldName == "tissue-relationship-distance") {
        return this.value.tissueRelationshipDistance ? true : false;
      }
      if (fieldName == "printed-try-in-required") {
        return this.value.printedTryInRequired ? true : false;
      }
      if (fieldName == "rent-scan-gauge-kit") {
        return this.value.rentScanGaugeKit ? true : false;
      }
      return false;
    },
    setDesignOnly() {
      let designOnly = "no";
      if (this.value.printedTryInRequired === "tryInDesignOnly") {
        designOnly = "yes";
      }
      this.value.designOnly = designOnly;
    }
  },

  watch: {
    value: {
      selected: function() {
        this.update();
      },

      handler: val => {
        if (val.barMaterial === "cobalt-chrome") {
          val.barTinting = "none";
        }

        if (val.restorativeProtocol === "conversion-denture") {
          val.interimDenture = "no";
        }
      },
      deep: true
    }
  }
};
</script>

<style>
@media only screen and (max-width: 959px) {
  .v-stepper__label {
    display: flex !important;
    margin-left: 15px;
  }
}
@media only screen and (min-width: 959px) {
  .v-stepper__label {
    display: flex !important;
    margin-left: 7px;
  }
}
</style>

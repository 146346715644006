<template>
  <v-btn-toggle
    v-model="archesRestored"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="archesRestoredSelected"
  >
    <v-btn class="ma-1" value="upper">
      {{ $t("productOptions.archesRestored.upper") | uppercase }}
    </v-btn>
    <v-btn class="ma-1" value="lower">
      {{ $t("productOptions.archesRestored.lower") | uppercase }}
    </v-btn>
    <v-btn class="ma-1" value="full-full">
      {{ $t("productOptions.archesRestored.fullFull") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "ArchesRestored",

  props: ["value"],

  data() {
    return {
      archesRestored: this.value
    };
  },

  methods: {
    archesRestoredSelected() {
      this.$emit("input", this.archesRestored);
    }
  }
};
</script>

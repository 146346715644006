import Vue from "vue";
import VueRouter from "vue-router";
import VueAnalytics from "vue-analytics";
import Instructions from "../pages/Instructions.vue";
import InstructionsGeneral from "../pages/InstructionsGeneral.vue";
import InstructionsItero from "../pages/InstructionsItero.vue";
import InstructionsMedit from "../pages/InstructionsMedit.vue";
import InstructionsTrios from "../pages/InstructionsTrios.vue";
import InstructionsViewer from "../pages/InstructionsViewer.vue";
import Contact from "../pages/Contact.vue";
import Account from "../views/Account.vue";
import Details from "../components/Account/Details.vue";
import Practices from "../components/Account/Practices.vue";
import OrderHistory from "../components/Account/OrderHistory.vue";
import OrderDetails from "../components/Account/OrderDetails.vue";
import ScanGaugeKits from "../components/Account/ScanGaugeKits.vue";
import ScanGaugeKitDetail from "../pages/ScanGaugeKitDetail.vue";
import Support from "../pages/Support.vue";
import SupportSession from "../pages/SupportSession.vue";
import BookSupport from "../pages/BookSupport.vue";
import SupportConfirm from "../pages/SupportConfirm.vue";
import Register from "../pages/Register.vue";
import RegisterConfirmed from "../pages/RegisterConfirmed.vue";
import RegisterVerify from "../pages/RegisterVerify.vue";
import PatientOrder from "../components/Order/PatientOrder/PatientOrder.vue";
import SingleBridgeOrder from "../components/Order/PatientOrder/SingleBridgeOrder.vue";
import CustomisingOrder from "../components/Order/PatientOrder/CustomisingOrder.vue";
import FullArchUpgradeOrder from "../components/Order/PatientOrder/FullArchUpgradeOrder.vue";
import ImmediateRestorationOrder from "../components/Order/PatientOrder/ImmediateRestorationOrder.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: PatientOrder,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      title: "Contact"
    }
  },
  {
    path: "/instructions",
    name: "instructions",
    component: Instructions,
    meta: {
      title: "Instructions"
    }
  },
  {
    path: "/instructions/view/:type",
    name: "instructions-viewer",
    component: InstructionsViewer,
    params: true,
    meta: {
      title: "Instructions Viewer"
    }
  },
  {
    path: "/instructions/general",
    name: "instructions-general",
    component: InstructionsGeneral,
    meta: {
      title: "Instructions General"
    }
  },
  {
    path: "/instructions/itero",
    name: "instructions-itero",
    component: InstructionsItero,
    meta: {
      title: "Instructions Itero"
    }
  },
  {
    path: "/instructions/medit",
    name: "instructions-medit",
    component: InstructionsMedit,
    meta: {
      title: "Instructions Medit"
    }
  },
  {
    path: "/instructions/trios",
    name: "instructions-trios",
    component: InstructionsTrios,
    meta: {
      title: "Instructions Trios"
    }
  },
  {
    path: "/single-bridge-order",
    name: "single-bridge-order",
    component: SingleBridgeOrder,
    meta: {
      title: "Single/Bridge Order"
    }
  },

  {
    path: "/customise-order/:type",
    name: "customise-order",
    component: CustomisingOrder,
    params: true,
    meta: {
      title: "Customising Order"
    }
  },

  {
    path: "/full-arch-order",
    name: "full-arch-order",
    component: FullArchUpgradeOrder,
    meta: {
      title: "Full Arch Order"
    }
  },

  {
    path: "/immediate-restoration-order",
    name: "immediate-restoration-order",
    component: ImmediateRestorationOrder,
    meta: {
      title: "Immediate Restoration Order"
    }
  },

  {
    path: "/order",
    name: "order",
    component: PatientOrder,
    meta: {
      title: "Order"
    }
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: {
      title: "Account"
    },
    children: [
      {
        path: "details",
        name: "details",
        component: Details,
        meta: {
          title: "Details"
        }
      },
      {
        path: "practices",
        name: "practices",
        component: Practices,
        meta: {
          title: "Practices"
        }
      },
      {
        path: "practices/:id",
        name: "edit-practice",
        component: Practices,
        props: true,
        meta: {
          title: "Edit Practice"
        }
      },
      {
        path: "orders",
        name: "orders",
        component: OrderHistory,
        meta: {
          title: "Order History"
        }
      },
      {
        path: "scan-gauge-kits",
        name: "scan-gauge-kits",
        component: ScanGaugeKits,
        meta: {
          title: "Scan Gauge Kits"
        }
      },
      {
        path: "order-details",
        name: "order-details",
        component: OrderDetails,
        meta: {
          title: "Order Details"
        }
      }
    ]
  },
  {
    path: "/scan-gauge-kit-detail",
    name: "scan-gauge-kit-detail",
    component: ScanGaugeKitDetail,
    meta: {
      title: "Scan Gauge Kit Detail"
    }
  },
  {
    path: "/support",
    name: "support",
    component: Support,
    meta: {
      title: "Support"
    }
  },
  {
    path: "/support/session/:configuration",
    name: "support-session",
    component: SupportSession,
    props: true,
    meta: {
      title: "Support Session"
    }
  },
  {
    path: "/support/book",
    name: "book-support",
    component: BookSupport,
    meta: {
      title: "Book Support"
    }
  },
  {
    path: "/support-confirmed/:platform",
    name: "support-confirmed",
    component: SupportConfirm,
    props: true,
    meta: {
      title: "Support Confirmed"
    }
  },
  {
    path: "/register-confirmed",
    name: "register-confirmed",
    component: RegisterConfirmed,
    meta: {
      title: "Register Confirmed"
    }
  },
  {
    path: "/register-verify",
    name: "register-verify",
    component: RegisterVerify,
    meta: {
      title: "Register Verify"
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      title: "Register"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

Vue.use(VueAnalytics, {
  id: "UA-175684831-2",
  router
});

export default router;

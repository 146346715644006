<template>
  <v-btn-toggle
    v-model="required"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="requiredSelected"
  >
    <v-btn class="ma-1" value="not-required" :disabled="disabled">
      {{ $t("common.yes") }}
    </v-btn>
    <v-btn class="ma-1" value="required" :disabled="disabled">
      {{ $t("common.no") }}
    </v-btn>
    <span>
      {{ $t("productOptions.surgicalScanTemplateSelections.disclaimer") }}
    </span>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "SurgicalScanTemplate",

  props: ["value", "disabled"],

  data() {
    return {
      required: this.value
    };
  },

  methods: {
    requiredSelected() {
      this.$emit("input", this.required);
    }
  }
};
</script>

<template>
  <v-card>
    <v-toolbar>
      <v-btn fab @click="$emit('back')">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      {{ $t("common.orderDetails") }}
    </v-toolbar>
    <v-data-table :headers="headers" :items="caseDetails" :items-per-page="5">
      <template v-slot:item.id="{ item }">
        {{ item.id }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import { DateTime } from "luxon";

export default {
  name: "OrderDetails",

  computed: {
    ...mapGetters(["caseDetails"])
  },

  props: {
    orderId: String
  },

  data() {
    return {
      loading: true,
      headers: [{ text: "Product", value: "job_type" }]
    };
  },

  methods: {
    ...mapActions(["fetchCases"])
  },

  created() {},

  mounted() {
    this.fetchCases(this.orderId).then(() => {
      this.loading = false;
    });
  }
};
</script>

<template>
  <v-form ref="clientDetails">
    <v-alert type="error" v-if="error">
      {{ $t("errors.unexpectedErrorContactUs") }}
      <!-- An unexpected error has occurred, please contact us for assistance. -->
    </v-alert>
    <v-alert type="success" v-if="success">
      {{ $t("nexusios.account.details.detailsUpdated") }}
      <!-- Personal details have been updated. -->
    </v-alert>
    <v-container class="pa-12 text-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="10"
        indeterminate
      ></v-progress-circular>
    </v-container>
    <v-card v-if="!loading">
      <v-toolbar color="secondary">
        <v-toolbar-title>
          Details
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="mt-5">
        <v-text-field
          v-model="clientParams.name"
          :label="$t('details.fullName')"
          solo
          light
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="clientParams.email"
          disabled
          :label="$t('details.emailAddress')"
          solo
          light
          :rules="[rules.required, rules.email]"
        ></v-text-field>
        <v-text-field
          v-model="clientParams.phone"
          :label="$t('details.phoneNumber')"
          solo
          light
          :rules="[rules.required]"
        ></v-text-field>
        <v-select
          v-model="clientParams.country"
          :label="$t('details.country')"
          solo
          light
          :items="countries"
          :rules="[rules.required]"
        ></v-select>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submit">
          {{ $t("nexusios.account.details.updateDetails") }}
          <!-- Update Details -->
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  validationRequired,
  validationEmail
} from "../../shared/ValidationRules";
import { mapGetters, mapActions } from "vuex";
import { getNames } from "country-list";

export default {
  name: "Details",

  data() {
    return {
      loading: true,
      success: false,
      error: false,

      rules: {
        required: validationRequired,
        email: validationEmail
      },

      clientParams: {}
    };
  },

  computed: {
    ...mapGetters(["approvedClient"]),

    countries() {
      return getNames().sort();
    }
  },

  methods: {
    ...mapActions(["fetchUser"]),

    async submit() {
      if (this.$refs.clientDetails.validate()) {
        this.loading = true;
        // TODO: Capture the selected language when i18n is supported.
        this.clientParams["language"] = "en";
        this.$httpClient
          .updateClient(this.clientParams)
          .then(async () => {
            this.loading = false;
            this.success = true;
          })
          .catch(() => {
            this.loading = false;
            this.error = true;
          });
      }
    }
  },

  mounted() {
    this.clientParams = this.approvedClient;
    this.loading = false;
  }
};
</script>

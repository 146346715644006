<template>
  <v-card
    :color="dragOver ? 'primary' : 'white'"
    :light="!dragOver"
    :dark="dragOver"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragOver = true"
    @dragenter.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    :class="{ pulsing: dragOver }"
  >
    <v-card-text @click="addFile()">
      <v-row>
        <v-col class="text-center">
          <v-icon size="60" class="mt-5">
            mdi-cloud-upload
          </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <h3>Drop your file(s) or folder here, or click to select them.</h3>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col class="text-center my-5" v-if="files.length > 0">
          <strong>
            {{ files.length }} file<span v-if="files.length > 1">s</span>
            selected
          </strong>
          - <a @click="clear()">clear all</a>
        </v-col>
      </v-row>
      <v-card
        max-height="200"
        class="overflow-y-auto"
        outlined
        v-if="files.length > 0"
      >
        <v-simple-table>
          <tbody>
            <tr v-for="(file, index) in files" :key="index">
              <td>
                <v-icon class="mr-2">mdi-file</v-icon>
                {{ file.name }}
              </td>
              <td class="text-right">
                <v-btn small fab icon @click="removeFile(file)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UploadInput",
  props: ["value"],
  data() {
    return {
      selectedFiles: [],
      dragOver: false
    };
  },
  computed: {
    files: {
      get() {
        return this.selectedFiles;
      },
      set(value) {
        this.$emit("input", value);
        this.selectedFiles = value;
      }
    }
  },
  methods: {
    onDrop(event) {
      event.dataTransfer.items.forEach(item => {
        const wkItem = item.webkitGetAsEntry();
        if (wkItem.isFile) {
          wkItem.file(fileItem => {
            this.files.push(fileItem);
          });
        } else if (wkItem.isDirectory) {
          this.traverseDirectory(wkItem);
        }
      });
      this.dragOver = false;
    },
    traverseDirectory(wkItem) {
      const dirReader = wkItem.createReader();
      dirReader.readEntries(entries => {
        entries.forEach(entry => {
          if (entry.isFile) {
            entry.file(fileItem => {
              this.files.push(fileItem);
            });
          } else if (entry.isDirectory) {
            this.traverseDirectory(entry);
          }
        });
      });
    },
    addFile() {
      const input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.addEventListener("change", event => {
        event.target.files.forEach(entry => {
          this.files.push(entry);
        });
      });
      input.click();
    },
    removeFile(file) {
      const index = this.files.indexOf(file);
      if (index < 0) {
        return;
      }
      this.files.splice(index, 1);
    },
    clear() {
      this.files = [];
    }
  },
  mounted() {
    if (this.value) {
      this.selectedFiles = this.value;
    }
  }
};
</script>

<style>
.pulsing {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
</style>

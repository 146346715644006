<template>
  <div>
    <v-dialog v-model="dialog" fullscreen>
      <v-card v-if="!loadingDialog">
        <v-toolbar color="secondary">
          <v-toolbar-title>
            {{ $t("nexusios.patientOrder.approvalFiles") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title class="caption" cols="12">
          <v-row>
            <v-col cols="12" md="4" class="text-center">
              {{ $t("common.caseId") }} - {{ caseMKey }} ({{ caseNumber }})
            </v-col>
            <v-col cols="12" md="4" class="text-center">
              <v-icon small class="mr-2">mdi-face</v-icon>
              {{ $t("common.patient") }} - {{ patientName }}
            </v-col>
            <v-col cols="12" md="4" class="text-center">
              <v-icon small class="mr-2">mdi-hospital-box</v-icon>
              {{ $t("common.practice") }} - {{ practiceName }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          :color="snackbarColor"
          centered
          top
        >
          {{ snackbarMessage }}
        </v-snackbar>
        <v-card-title class="caption" cols="12" v-if="messaging">
          <v-card-text class="text-wrap">
            <v-alert
              outlined
              color="red"
              class="caption"
              v-if="files.warranty_void"
            >
              {{
                $t("nexusios.account.approvalFiles.warrantyMessageDearClient")
              }}
              <br /><br />
              {{ $t("nexusios.account.approvalFiles.warrantyMessageLine1") }}
              <br /><br />
              <v-alert
                text
                color="red"
                class="caption"
                icon="mdi-alert-outline"
              >
                <b>
                  {{ $t("nexusios.account.approvalFiles.warrantyVoidWarning") }}
                </b>
              </v-alert>
              {{ $t("nexusios.account.approvalFiles.warrantyMessageLine2") }}
            </v-alert>
          </v-card-text>
          <v-card-text class="caption">
            <p class="text-wrap">
              {{ $t("nexusios.account.approvalFiles.blurbPleaseReview") }}
            </p>
          </v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <span class="mx-5">{{ $t("common.either") }}</span>
              <v-btn color="success" @click="acceptDesign = true"
                ><v-icon class="mr-2">mdi-thumb-up-outline</v-icon>
                {{ $t("nexusios.account.approvalFiles.buttonAcceptDesign") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <span class="mx-5">{{ $t("common.or") }}</span>
              <v-btn
                color="warning"
                @click="
                  approvalStatus = 'acceptWithChanges';
                  acceptWithChanges = true;
                "
                ><v-icon class="mr-2">mdi-thumb-up-outline</v-icon>
                {{ $t("nexusios.account.approvalFiles.buttonAcceptChanges") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <span class="mx-5">{{ $t("common.or") }}</span>
              <v-btn
                color="error"
                @click="
                  $refs.caseNoteForm.$el.scrollIntoView();
                  $refs.messageBox.focus();
                  alert = !alert;
                  approvalStatus == ''
                    ? (approvalStatus = 'requestChanges')
                    : (approvalStatus = '');
                "
              >
                <v-icon class="mr-2">mdi-swap-horizontal</v-icon>
                {{ $t("nexusios.account.approvalFiles.buttonRequestChanges") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text class="caption">
            {{ $t("nexusios.account.approvalFiles.blurbPleaseKeep") }}
          </v-card-text>
        </v-card-title>
        <v-card-title v-else-if="files.email_count == 0"
          ><v-card-text class="font-weight-bold py-2 text-center">
            {{
              $t("nexusios.account.approvalFiles.blurbCurrentlyBeingDesigned")
            }} </v-card-text
          ><v-card-text class="py-2 text-center">
            {{ $t("nexusios.account.approvalFiles.blurbYouMaySend") }}
          </v-card-text></v-card-title
        >
        <v-container>
          <v-row>
            <v-col class="col-12 col-md-6">
              <v-card-title>{{ $t("common.designFiles") }} </v-card-title>
              <v-data-table
                :headers="fileHeader"
                :items="files.checked_files"
                :items-per-page="5"
              >
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item.time="{ item }">
                  {{ prettyDateTime(item.updated) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    small
                    fab
                    text
                    color="white"
                    class="ma-1 pa-0 mr-3"
                    @click="fetchImageWithAxios(item)"
                  >
                    <div>
                      <v-icon small>mdi-eye</v-icon>
                      <h6>{{ $t("common.view") }}</h6>
                    </div>
                  </v-btn>
                  <v-btn
                    small
                    fab
                    text
                    color="white"
                    class="ma-1 pa-1"
                    @click="downloadWithAxios(item)"
                  >
                    <div>
                      <v-icon small>mdi-file-download</v-icon>
                      <h6>{{ $t("common.download") }}</h6>
                    </div>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>

            <v-col class="col-12 col-md-6">
              <v-card-title>{{ $t("common.caseNotes") }} </v-card-title>
              <v-timeline dense clipped v-if="notes">
                <v-timeline-item v-for="item in notes" :key="item.id">
                  <v-card class="elevation-2" color="secondary">
                    <v-card-text>
                      {{ prettyDateTime(item.created_at) }} - {{ item.author }}
                    </v-card-text>
                    <v-card-text>
                      <strong>{{ item.note }}</strong>
                      <a :href="item.url" v-if="item.attachment" target="_blank"
                        ><br />{{ item.attachment }}</a
                      >
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
              <v-form ref="caseNoteForm" v-if="messaging">
                <v-alert
                  :value="alert"
                  text
                  color="error"
                  transition="scale-transition"
                >
                  {{
                    $t("nexusios.account.approvalFiles.blurbRequestWithChanges")
                  }}
                </v-alert>
                <v-textarea
                  ref="messageBox"
                  v-model="inputNote"
                  :label="$t('common.messageToNexus')"
                  outlined
                  dense
                  :rules="[rules.required]"
                ></v-textarea>
                <v-file-input
                  v-model="noteAttachment"
                  truncate-length="15"
                  accept="image/*, .doc, .docz, .pdf, .zip, .stl, .txt, .html, .htm"
                  :label="$t('common.attachments')"
                ></v-file-input>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="error" @click="cancelDialogBtn"
            >{{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            v-if="messaging"
            large
            color="primary"
            @click="submit"
            :disabled="!inputNote"
          >
            {{ $t("common.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inner" width="600">
      <v-card>
        <v-card-title
          >{{ $t("common.filename") }} - {{ imageName }}</v-card-title
        >
        <v-img
          :src="imageURL"
          contain
          eager
          :cover="true"
          @error="errorHandler"
        ></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="inner = false">
            {{ $t("common.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="innerHtml" width="80%">
      <v-card height="80vh">
        <v-card-title
          >{{ $t("common.filename") }} - {{ imageName }}</v-card-title
        >
        <iframe
          style="width:100%; height:80%"
          :src="htmlURL"
          type="text/html"
        ></iframe>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="innerHtml = false">
            {{ $t("common.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="acceptDesign" width="520">
      <v-toolbar
        ><v-icon class="mr-2">mdi-thumb-up</v-icon
        ><strong>{{
          $t("nexusios.account.approvalFiles.buttonAcceptDesign")
        }}</strong
        ><v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            approvalStatus = '';
            acceptDesign = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn></v-toolbar
      >
      <v-card>
        <v-card-text class="py-2">
          {{ $t("nexusios.account.approvalFiles.blurbAcceptDesign") }}
          <br /><br />
          <v-alert
            outlined
            text
            color="red"
            icon="mdi-alert-outline"
            border="left"
            class="caption"
            v-if="files.warranty_void"
          >
            {{ $t("nexusios.account.approvalFiles.warrantyVoidWarning2") }}
          </v-alert>
          {{ $t("nexusios.account.approvalFiles.blurbAcceptConfirm") }}
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="
              approvalStatus = '';
              acceptDesign = false;
            "
          >
            {{ $t("common.close") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="
              approvalStatus = 'accept';
              submit();
              acceptDesign = false;
            "
          >
            {{ $t("nexusios.account.approvalFiles.buttonAcceptDesign") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="acceptWithChanges" width="800">
      <v-toolbar
        ><v-icon class="mr-2">mdi-thumb-up</v-icon
        ><strong>{{
          $t("nexusios.account.approvalFiles.buttonAcceptDesign")
        }}</strong
        ><v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            approvalStatus = '';
            acceptWithChanges = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn></v-toolbar
      >
      <v-card>
        <v-card-text class="py-2">
          {{ $t("nexusios.account.approvalFiles.blurbAcceptWithChanges") }}
          <v-textarea
            v-model="changesNote"
            :placeholder="$t('nexusios.account.approvalFiles.detailsOfChanges')"
            outlined
            dense
          ></v-textarea>
          <v-alert
            outlined
            text
            color="red"
            icon="mdi-alert-outline"
            border="left"
            class="caption"
            v-if="files.warranty_void"
          >
            {{ $t("nexusios.account.approvalFiles.warrantyVoidWarning2") }}
          </v-alert>
          {{
            $t("nexusios.account.approvalFiles.blurbAcceptWithChangesConfirm")
          }}
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="
              approvalStatus = '';
              acceptWithChanges = false;
            "
          >
            {{ $t("common.close") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="
              submit();
              acceptWithChanges = false;
            "
          >
            {{ $t("nexusios.account.approvalFiles.buttonAcceptChanges") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { DateTime } from "luxon";
import { validationRequired } from "../../shared/ValidationRules";

export default {
  name: "ApprovalFiles",

  props: [
    "caseNumber",
    "caseMKey",
    "dialog",
    "patientName",
    "messaging",
    "practiceName",
    "loadingDialog",
    "files",
    "notes"
  ],

  data() {
    return {
      alert: false,
      snackbar: false,
      snackbarColor: "success",
      timeout: "3000",
      caseNotes: {},
      fileHeader: [
        { text: this.$t("common.filename"), value: "name" },
        { text: this.$t("common.time"), value: "time" },
        {
          text: this.$t("common.actions"),
          value: "actions",
          width: "140",
          cellClass: "pa-0"
        }
      ],
      imageName: String,
      imageURL: null,
      htmlURL: null,
      inner: false,
      innerHtml: false,
      acceptDesign: false,
      acceptWithChanges: false,
      localTimeZone: process.env.VUE_APP_BASE_TIMEZONE,
      rules: {
        required: validationRequired
      },
      noteAttachment: null,
      inputNote: "",
      changesNote: "",
      approvalStatus: "",
      snackbarMessage: String
    };
  },

  methods: {
    cancelDialogBtn() {
      this.noteAttachment = null;
      this.inputNote = "";
      this.changesNote = "";
      this.approvalStatus = "";
      this.alert = false;
      this.$emit("childShow");
    },

    errorHandler() {
      this.imageURL = "../../assets/temporary/file-error.png";
      this.$forceUpdate();
    },

    prettyDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_SHORT);
    },

    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },

    downloadWithAxios(item) {
      let url = item.url;
      let title = item.name;
      axios({
        method: "get",
        url,
        responseType: "blob"
      })
        .then(response => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("download file error occured."));
    },

    async fetchImageWithAxios(item) {
      let url = item.url;
      this.imageName = item.name;
      await axios({
        method: "get",
        url,
        responseType: "blob"
      })
        .then(response => {
          // types: application/sla image/jpg image/png text/html
          if (response.data.type.indexOf("application") !== -1) {
            this.inner = false;
            this.forceFileDownload(response, this.imageName);
          } else {
            if (response.data.type == "text/html") {
              this.setHtmlDialog(response.data);
            } else {
              this.setImageDialog(response.data);
            }
          }
        })
        .catch(() => console.log("fetching image error occured."));
    },

    setImageDialog(responseBlob) {
      const blob = new Blob([responseBlob]);
      if (this.imageURL) URL.revokeObjectURL(this.imageURL);
      this.imageURL = URL.createObjectURL(blob);
      this.inner = true;
    },

    setHtmlDialog(responseBlob) {
      const blob = new Blob([responseBlob], { type: "text/html" });
      if (this.htmlURL) URL.revokeObjectURL(this.htmlURL);
      this.htmlURL = URL.createObjectURL(blob);
      this.innerHtml = true;
    },

    async submit() {
      let formData = new FormData();
      var sendMessage =
        this.approvalStatus == "acceptWithChanges"
          ? this.changesNote
          : this.inputNote;
      if (sendMessage || this.approvalStatus == "accept") {
        formData.append("attachment", this.noteAttachment);
        formData.append("note", sendMessage);
        formData.append("approvalStatus", this.approvalStatus);
        this.timeout = "3000";
        await this.$httpClient
          .postCaseFilesApproval(this.caseNumber, formData)
          .then(() => {
            this.snackbar = true;
            this.snackbarColor = "success";
            this.snackbarMessage = this.$t(
              "nexusios.patientOrder.caseApprovalSucceeded"
            );
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarColor = "error";
            this.snackbarMessage = this.$t(
              "nexusios.patientOrder.caseApprovalFailed"
            );
          });

        setTimeout(() => {
          this.cancelDialogBtn();
        }, 3000);
      }
    }
  }
};
</script>

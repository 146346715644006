<template>
  <v-row class="ma-2 ma-md-8 align-top fill-height">
    <v-col cols="3">
      <v-card color="secondary" style="min-width: 56px">
        <v-list color="secondary">
          <v-list-item
            v-for="item in list_items"
            :key="item.route_name"
            link
            :to="{ name: item.route_name }"
            exact
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="d-none d-sm-block">
              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="9">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Account",

  data: () => ({
    indication_type_dialog: true,
    list_items: [
      {
        route_name: "details",
        text: "common.details",
        icon: "mdi-account"
      },
      {
        route_name: "practices",
        text: "common.practices",
        icon: "mdi-hospital-box"
      },
      {
        route_name: "scan-gauge-kits",
        text: "nexusios.userButton.scanGaugeKits",
        icon: "mdi-blur-linear"
      },
      {
        route_name: "orders",
        text: "nexusios.account.orderHistory.orderHistory",
        icon: "mdi-history"
      }
    ]
  })
};
</script>

<template>
  <v-btn-toggle
    v-model="relationship"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="relationshipSelected"
  >
    <v-btn class="ma-1" value="as-close-as-possible" :disabled="disabled">
      {{ $t("productOptions.tissueRelationshipDistance.asCloseAsPossible") }}
    </v-btn>
    <v-btn class="ma-1" value="specify" :disabled="disabled">
      {{ $t("productOptions.tissueRelationshipDistance.specify") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "TissueRelationship",

  props: ["value", "disabled"],

  data() {
    return {
      relationship: this.value
    };
  },

  methods: {
    relationshipSelected() {
      this.$emit("input", this.relationship);
    }
  }
};
</script>

<template>
  <div class="fill-height flex">
    <v-card-title>
      <v-row>
        <v-col cols="12" md="6">
          <h3>{{ customiseTitle }}</h3>
        </v-col>
        <v-col cols="12" md="6" class="text-right ma-0 pa-0">
          <h6>
            {{ practiceName }}
            <v-icon>mdi-hospital-box</v-icon>
          </h6>
          <h6>
            {{ patientName }}
            <v-icon>mdi-account-circle</v-icon>
          </h6>
        </v-col>
      </v-row>
    </v-card-title>
    <v-stepper v-model="step" class="fill-height" v-if="!loading">
      <v-stepper-items class="fill-height">
        <!-- Customise Your Nexus (Step 2) -->
        <v-stepper-content
          step="2"
          class="fill-height pa-6 background-radial-gradient"
        >
          <!-- title row -->
          <v-row>
            <v-col cols="12" md="6">
              <h1 v-if="implantsEnabled">
                {{ $t("nexusios.orderNow.customiseYourNexus") }}
              </h1>
            </v-col>

            <v-col cols="12" md="6" class="text-right">
              <h3>
                {{ prettyIndicationType }}
                <v-btn
                  icon
                  @click="changeIndicationDialog = true"
                  :disabled="indicationType == 'pre-op'"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </h3>
            </v-col>
          </v-row>
          <ChangeIndicationPrompt
            :changeIndicationDialog="changeIndicationDialog"
            v-on:choice="changeIndicationSelected"
          >
          </ChangeIndicationPrompt>

          <!-- "body" row -->
          <v-row>
            <!-- *************************** -->
            <!-- Left hand side image region -->
            <v-col cols="12" md="6" sticky-container>
              <template v-if="implantsEnabled">
                <OrderPreviewImage
                  v-sticky
                  v-if="isTab('upper')"
                  v-model="upperOptions"
                  set="upper"
                  :indication-type="indicationType"
                ></OrderPreviewImage>

                <OrderPreviewImage
                  v-sticky
                  v-if="isTab('lower')"
                  v-model="lowerOptions"
                  set="lower"
                  :indication-type="indicationType"
                ></OrderPreviewImage>

                <!-- Mini view of both when viewing the "Upload and Finish" tab -->
                <v-row v-if="isTab('upload-finish')">
                  <v-col cols="12" md="6" v-if="upperEnabled">
                    <v-card>
                      <v-card-title>
                        {{ $t("common.upper") }}
                      </v-card-title>
                      <OrderPreviewImage
                        v-model="upperOptions"
                        set="upper"
                        :indication-type="indicationType"
                      ></OrderPreviewImage>
                      <v-data-table
                        :headers="summaryDesignHeaders"
                        :items="summaryUpperBar"
                        hide-default-header
                        hide-default-footer
                        class="table"
                      >
                        <template v-slot:item="{ item }">
                          <tr v-if="item.show">
                            <td>{{ item.headingText }}</td>
                            <td>{{ item.valueText }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6" v-if="lowerEnabled">
                    <v-card>
                      <v-card-title>
                        {{ $t("common.lower") }}
                        <!-- Lower -->
                      </v-card-title>
                      <OrderPreviewImage
                        v-model="lowerOptions"
                        set="lower"
                        :indication-type="indicationType"
                      ></OrderPreviewImage>
                      <v-data-table
                        :headers="summaryDesignHeaders"
                        :items="summaryLowerBar"
                        hide-default-header
                        hide-default-footer
                        class="table"
                      >
                        <template v-slot:item="{ item }">
                          <tr v-if="item.show">
                            <td>{{ item.headingText }}</td>
                            <td>{{ item.valueText }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-if="preopEnabled">
                <PreopPreviewImage v-model="preopOptions"></PreopPreviewImage>
              </template>
            </v-col>

            <!-- *************************************** -->
            <!-- Right hand side control (tabbed) region -->
            <v-col cols="12" md="6">
              <v-card class="elevation-0 card">
                <!-- tab headings -->
                <v-tabs
                  grow
                  v-model="tab"
                  background-color="rgba(0,0,0,0)"
                  active-class="active-tab-new"
                  show-arrows
                >
                  <!-- <template v-if="this.indicationType !== 'pre-op'"> -->
                  <!-- Implant Selector -->
                  <v-tab
                    class="inactive-tab-new"
                    v-if="implantsEnabled"
                    href="#implants"
                    ref="implants"
                  >
                    {{ $t("common.implants") }}
                    <!-- Implants -->
                  </v-tab>
                  <v-tab-item v-if="implantsEnabled" value="implants">
                    <v-container class="pa-8">
                      <h2 class="mb-4">
                        <span v-if="indicationType === 'pre-op'"
                          >Intended Multi-Unit Abutment Selection</span
                        >
                        <span v-if="indicationType !== 'pre-op'">
                          {{ $t("nexusios.orderNow.implantSelection") }}
                          <!-- Implant Selection -->
                        </span>
                      </h2>
                      <ImplantPlatformPicker
                        v-model="implants"
                        :indication-type="indicationType"
                        :practice-id="practiceId"
                      ></ImplantPlatformPicker>
                      <v-container class="d-flex justify-end">
                        <v-btn tile class="next-button" @click="nextTab">
                          {{ $t("common.next") }}
                          <!-- Next -->
                        </v-btn>
                      </v-container>
                    </v-container>
                  </v-tab-item>

                  <!-- Product options - Upper teeth -->
                  <v-tab
                    class="inactive-tab-new"
                    v-if="upperEnabled"
                    href="#upper"
                    ref="upper"
                    @click="nextTab"
                  >
                    {{ $t("common.upper") }}
                  </v-tab>
                  <v-tab-item v-if="upperEnabled" value="upper">
                    <ProductOptionSelections
                      v-model="upperOptions"
                      :indication-type="indicationType"
                      :key="productOptionsKey"
                    ></ProductOptionSelections>
                    <v-container class="d-flex justify-end">
                      <v-btn tile class="close-button" @click="previousTab">
                        {{ $t("common.back") }}
                        <!-- Back -->
                      </v-btn>
                      <v-btn tile class="next-button" @click="nextTab">
                        {{ $t("common.next") }}
                        <!-- Next -->
                      </v-btn>
                    </v-container>
                  </v-tab-item>

                  <!-- Product options - Lower teeth -->
                  <v-tab
                    class="inactive-tab-new"
                    v-if="lowerEnabled"
                    href="#lower"
                    ref="lower"
                    @click="nextTab"
                  >
                    {{ $t("common.lower") }}
                    <!-- Lower -->
                  </v-tab>
                  <v-tab-item v-if="lowerEnabled" value="lower">
                    <ProductOptionSelections
                      v-model="lowerOptions"
                      :indication-type="indicationType"
                      :key="productOptionsKey"
                    ></ProductOptionSelections>
                    <v-container class="d-flex justify-end">
                      <v-btn tile class="close-button" @click="previousTab">
                        {{ $t("common.back") }}
                        <!-- Back -->
                      </v-btn>
                      <v-btn tile class="next-button" @click="nextTab">
                        {{ $t("common.next") }}
                        <!-- Next -->
                      </v-btn>
                    </v-container>
                  </v-tab-item>
                  <!-- </template> -->

                  <!-- <template v-if="indicationType === 'pre-op'"> -->
                  <!-- Product options - Pre-op -->
                  <v-tab
                    class="inactive-tab-new"
                    v-if="preopEnabled"
                    href="#pre-op"
                    ref="pre-op"
                  >
                    {{ $t("productCategories.preOp") }}
                  </v-tab>
                  <v-tab-item v-if="preopEnabled" value="pre-op">
                    <ProductOptionSelections
                      v-model="preopOptions"
                      :indication-type="indicationType"
                      :key="productOptionsKey"
                    ></ProductOptionSelections>
                    <v-container class="d-flex justify-end">
                      <v-btn tile class="next-button" @click="nextTab">
                        {{ $t("common.next") }}
                      </v-btn>
                    </v-container>
                  </v-tab-item>
                  <!-- </template> -->

                  <!-- Final order details, file upload -->
                  <v-tab
                    class="inactive-tab-new"
                    v-if="implants.length > 0 || indicationType === 'pre-op'"
                    href="#upload-finish"
                    ref="upload-finish"
                    @click="nextTab"
                  >
                    {{ $t("common.uploadAndFinish") }}
                  </v-tab>
                  <v-tab-item value="upload-finish">
                    <v-container class="pa-8">
                      <v-form ref="caseOrderForm">
                        <h2
                          class="my-4"
                          v-if="implantsEnabled && indicationType !== 'pre-op'"
                        >
                          {{ $t("common.dateRequired") }}
                        </h2>
                        <h2 class="my-4" v-if="preopEnabled">
                          {{ $t("details.surgeryDate") }}
                        </h2>
                        <p v-if="preopEnabled">
                          Please add the indicative time of surgery completion
                        </p>
                        <v-row>
                          <v-col cols="6">
                            <v-date-picker
                              class="pt-3"
                              v-model="dateRequired"
                              :allowed-dates="allowedDates"
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            v-if="indicationType === 'pre-op'"
                            class="mt-5"
                          >
                            <p>
                              <v-icon class="mr-2">mdi-information</v-icon>
                              Timezone caveat for Immediates
                            </p>
                            <p>
                              Please note, Same Day service requires all scan
                              data to be received by:
                            </p>
                            <v-row>
                              <v-col cols="6">
                                <ul>
                                  <li>
                                    USA &amp; Canada
                                    <ul>
                                      <li>3:00pm PST</li>
                                      <li>4:00pm MST</li>
                                      <li>5:00pm CST</li>
                                      <li>6:00pm EST</li>
                                    </ul>
                                  </li>
                                </ul>
                              </v-col>
                              <v-col cols="6">
                                <ul>
                                  <li>
                                    Australia &amp; New Zealand
                                    <ul>
                                      <li>12:00pm AWST</li>
                                      <li>1:30pm ACST</li>
                                      <li>3:00pm AEST/AEDT</li>
                                      <li>5:00pm NZST</li>
                                    </ul>
                                  </li>
                                </ul>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" v-if="indicationType === 'pre-op'">
                            <h2 class="my-4">Time of Surgery Completion</h2>
                            <v-text-field
                              solo
                              light
                              type="time"
                              v-model="timeRequired"
                            />
                          </v-col>
                        </v-row>
                        <span v-if="isScanGaugeRequired">
                          <h2 class="my-4">
                            {{ $t("nexusios.orderNow.scanGaugeSerial#") }}
                          </h2>
                          <v-select
                            v-model="scanGaugeKitId"
                            :items="kitSerialNumbers"
                            :label="$t('nexusios.orderNow.scanGaugeSerial#')"
                            solo
                            light
                            :rules="[rules.required]"
                          ></v-select>
                        </span>
                        <h2 class="my-4">
                          {{ $t("nexusios.orderNow.sendScansVia") }}
                        </h2>
                        <v-select
                          v-model="sendScanVia"
                          :items="sendScanViaItems"
                          :label="$t('nexusios.patientOrder.select')"
                          solo
                          light
                        ></v-select>
                        <div v-if="sendScanVia === 'upload'">
                          <h2 class="my-4">
                            {{ $t("common.uploadScans") }}
                          </h2>
                          <UploadInput v-model="scanFileItems" class="mb-5" />
                        </div>

                        <h2 class="my-4">
                          {{ $t("nexusios.orderNow.uploadPatientPhotos") }}
                        </h2>
                        <UploadInput v-model="imageFileItems" class="mb-5" />

                        <h2 class="my-4">
                          {{ $t("nexusios.orderNow.specialRequest") }}
                        </h2>
                        <v-textarea
                          solo
                          light
                          v-model="specialRequest"
                        ></v-textarea>

                        <v-container class="d-flex justify-end">
                          <v-btn tile class="close-button" @click="previousTab">
                            {{ $t("common.back") }}
                          </v-btn>
                          <v-btn
                            tile
                            class="next-button"
                            @click="openSummaryDialog()"
                          >
                            {{ $t("common.summary") }}
                          </v-btn>
                        </v-container>
                      </v-form>
                    </v-container>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-col>
          </v-row>

          <!-- Order Summary -->
          <v-dialog v-model="summaryDialog" persistent>
            <v-card>
              <v-toolbar color="secondary">
                <v-toolbar-title>
                  {{ $t("common.orderSummary") }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn tile color="warning" @click="cancelOrder" class="mr-2">
                  {{ $t("common.cancel") }}
                  <!-- Cancel -->
                </v-btn>
                <v-btn
                  tile
                  color="primary"
                  @click="$router.push('/order')"
                  class="mr-2"
                >
                  {{ $t("common.saveAndQuit") }}
                </v-btn>
                <v-btn
                  tile
                  color="success"
                  @click="submitDialog = true"
                  :disabled="!isComplete"
                >
                  {{ $t("common.confirm") }}
                  <!-- Confirm -->
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row class="my-5">
                  <v-col cols="12" class="text-center">
                    <h4 v-if="errorMessage" class="red--text pr-5">
                      {{ errorMessage }}
                    </h4>
                    <h4
                      v-else-if="imageFileItems.length == 0"
                      class="yellow--text pr-5"
                    >
                      {{ $t("warning.noImageUploaded") }}
                    </h4>
                  </v-col>
                  <!-- Implants (list of teeth/implants) -->
                  <v-col v-if="implantsEnabled">
                    <h3>
                      {{ $t("common.implants") }}
                      <!-- Implants -->
                    </h3>
                    <v-data-table
                      :headers="summaryImplantsHeaders"
                      :items="summary.implants"
                      hide-default-footer
                      class="table"
                    ></v-data-table>
                  </v-col>

                  <!-- List of options selected -->
                  <v-col>
                    <!-- Heading for the summary (not needed for preop) -->
                    <v-data-table
                      v-if="!preopEnabled"
                      :headers="summaryDesignHeaders"
                      :items="summary.design"
                      hide-default-header
                      hide-default-footer
                      class="table"
                    >
                      <template v-slot:item="{ item }">
                        <tr v-if="item.show">
                          <td>{{ item.headingText }}</td>
                          <td>{{ item.valueText }}</td>
                        </tr>
                      </template>
                    </v-data-table>

                    <h3 v-if="preopEnabled">
                      {{ $t("productCategories.preOp") }}
                      <!-- Pre-op -->
                    </h3>
                    <v-data-table
                      v-if="preopEnabled"
                      :headers="summaryDesignHeaders"
                      :items="summaryPreop"
                      hide-default-header
                      hide-default-footer
                      class="table"
                    >
                      <template v-slot:item="{ item }">
                        <tr v-if="item.show">
                          <td>{{ item.headingText }}</td>
                          <td>{{ item.valueText }}</td>
                        </tr>
                      </template>
                    </v-data-table>

                    <h3 v-if="upperEnabled">
                      {{ $t("common.upper") }}
                    </h3>
                    <v-data-table
                      v-if="upperEnabled"
                      :headers="summaryDesignHeaders"
                      :items="summaryUpperBar"
                      hide-default-header
                      hide-default-footer
                      class="table"
                    >
                      <template v-slot:item="{ item }">
                        <tr v-if="item.show">
                          <td>{{ item.headingText }}</td>
                          <td>{{ item.valueText }}</td>
                        </tr>
                      </template>
                    </v-data-table>

                    <h3 v-if="lowerEnabled">
                      {{ $t("common.lower") }}
                    </h3>
                    <v-data-table
                      v-if="lowerEnabled"
                      :headers="summaryDesignHeaders"
                      :items="summaryLowerBar"
                      hide-default-header
                      hide-default-footer
                      class="table"
                    >
                      <template v-slot:item="{ item }">
                        <tr v-if="item.show">
                          <td>{{ item.headingText }}</td>
                          <td>{{ item.valueText }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col>
                    <h3>
                      {{ $t("nexusios.orderNow.scanFiles") }}
                      <!-- Scan Files -->
                      <v-chip class="ml-2" color="primary">{{
                        scanFileItems.length
                      }}</v-chip>
                    </h3>
                    <h3 class="mt-5">
                      {{ $t("nexusios.orderNow.imageFiles") }}
                      <!-- Image Files -->
                      <v-chip class="ml-2" color="primary">{{
                        imageFileItems.length
                      }}</v-chip>
                    </h3>
                    <h3 class="mt-5">
                      {{ $t("nexusios.orderNow.specialRequest") }}
                      <!-- Special Request -->
                    </h3>
                    <p>{{ summary.specialRequest }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- Order Summary Terms & Conditions-->
          <v-dialog v-model="submitDialog" persistent max-width="600">
            <v-card>
              <v-toolbar color="secondary">
                <v-toolbar-title>
                  {{ $t("common.submit") }}
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <p class="mt-5">
                  {{ $t("nexusios.orderNow.pleaseReadSummary") }}
                  <!-- Please read your summary carefully to confirm your selections.
                  Click submit to finalize your order. -->
                </p>
                <p>
                  {{ $t("nexusios.orderNow.notes") }}
                  <!-- Note: If your order did not include all the required information,
                  we will begin your case design using default options. Be sure to carefully
                  review the design during approval. -->
                </p>
                <p>
                  <a
                    href="https://storage.googleapis.com/nexus-ios/public-files/ART-004-2%20Nexus%20IOS%20Warranty%20Program%20v2.0%2020221201.pdf"
                    target="_blank"
                  >
                    {{ $t("nexusios.warranty.fullTermsAndConditions") }}
                    <!-- Full Terms & Conditions -->
                  </a>
                </p>

                <v-checkbox
                  v-model="agreeTermsConditions"
                  :label="$t('unsorted.iAcceptTheTermsAndConditions')"
                ></v-checkbox>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn tile color="error" @click="submitDialog = false">
                  {{ $t("common.cancel") }}
                  <!-- Cancel -->
                </v-btn>
                <v-btn
                  tile
                  color="success"
                  @click="order"
                  :disabled="!agreeTermsConditions"
                >
                  {{ $t("nexusios.orderNow.submitAndUpload") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Order Summary Saving Progress Bar-->
          <v-dialog
            persistent
            v-model="progressDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card tile v-if="!uploadComplete">
              <v-card-text>
                <h1 class="text-center py-12">
                  {{ $t("nexusios.orderNow.yourCaseIsBeingUploaded") }}
                  <!-- Your Case is Being Uploaded -->
                </h1>
                <Uploader
                  v-model="uploadFiles"
                  class="py-5"
                  :start="startUpload"
                  :upload-url="uploadUrl"
                  @complete="uploadComplete = true"
                />
                <h3 class="text-center py-12">
                  <v-icon class="mr-2">mdi-alert</v-icon>
                  {{ $t("nexusios.orderNow.leaveScreenOpen") }}
                  <!-- Please leave this screen open until your upload has been
                  completed. -->
                </h3>
              </v-card-text>
            </v-card>
            <v-card tile v-if="uploadComplete">
              <v-card-text class="text-center">
                <v-progress-circular
                  :rotate="-90"
                  :size="200"
                  :width="10"
                  :value="100"
                  color="#14a4ff"
                  class="ma-12"
                >
                  <h1 class="white--text">
                    {{ $t("common.done") }}
                    <!-- Done -->
                  </h1>
                </v-progress-circular>
                <h1 class="ma-12">
                  {{ $t("common.thankYou") }}
                  <!-- Thank You -->
                </h1>
                <h3 class="ma-12">
                  {{ $t("nexusios.orderNow.caseSubmitted") }}
                  <!-- Your case has been submitted and will be reviewed shortly. -->
                </h3>
                <v-btn color="success" x-large :to="{ name: 'order' }">
                  {{ $t("common.finish") }}
                  <!-- Finish -->
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationRequired } from "../../../shared/ValidationRules";

import OrderPreviewImage from "../OrderPreviewImage";
import PreopPreviewImage from "../PreopPreviewImage";
import ImplantPlatformPicker from "../ImplantPlatformPicker";
import ProductOptionSelections from "./ProductOptionSelections";
import ChangeIndicationPrompt from "./ChangeIndicationPrompt";

import UploadInput from "@/components/shared/UploadInput";
import Uploader from "@/components/shared/Uploader";

export default {
  name: "CustomisingOrder",

  components: {
    OrderPreviewImage,
    PreopPreviewImage,
    ImplantPlatformPicker,
    ProductOptionSelections,
    ChangeIndicationPrompt,
    UploadInput,
    Uploader
  },

  data() {
    return {
      startUpload: false,
      loading: true,
      orderId: null,
      orderIntended: null,
      orderProductCategory: null,
      savedData: null,
      tab: null,
      rules: {
        required: validationRequired
      },
      productOptionsKey: 0,
      upperOptions: {},
      lowerOptions: {},
      preopOptions: {},
      changeIndicationDialog: false,
      practiceId: null,
      practiceName: null,
      restorativeClinician: null,
      scanGaugeKitId: null,
      step: "0",
      productCategory: null,
      indicationType: this.setIndicationType(),
      implantDialog: false,
      selectedTooth: null,
      implantPlatforms: [],
      implantPlatform: null,
      kitId: "",
      patientName: "",
      dateRequired: new Date().toISOString().substr(0, 10),
      timeRequired: null,
      specialRequest: "",
      implants: [],
      sendScanVia: "upload",
      summaryDialog: false,
      submitDialog: false,
      summaryUpperBar: [],
      summaryLowerBar: [],
      summaryPreop: [],
      progressDialog: false,
      scanFileItems: [],
      imageFileItems: [],
      agreeTermsConditions: false,
      uploadComplete: false,
      upperTeeth: [
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27"
      ],
      lowerTeeth: [
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47"
      ],
      caseId: null,
      errorMessage: null,
      isComplete: false,
      snackbar: false,
      snackbarColor: "success",
      snackbarMessage: "",
      requiredFieldMessage: "",
      parentCaseId: null
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.meta.title) {
          document.title = `${this._.startCase(
            to.params.type
          )} - Customising Order - Nexus iOS`;
        } else {
          document.title = "Customising Order - Nexus iOS";
        }
      }
    },
    caseId(value) {
      if (!value) {
        return;
      }
      this.startUpload = true;
    }
  },
  computed: {
    ...mapGetters(["approvedClient", "kits", "practices"]),
    customiseTitle() {
      return this.setCustomiseTitle();
    },
    kitSerialNumbers() {
      let serialNumbers = [];
      if (this.kits) {
        serialNumbers = this.kits.map(kit => {
          return { text: kit.serial_number, value: kit.serial_number };
        });
      }
      serialNumbers.push({ text: "Other", value: "other" });
      return serialNumbers;
    },

    sendScanViaItems() {
      return [
        { text: this.$t("common.manualUpload"), value: "upload" },
        {
          text: this.$t("nexusios.orderNow.headers.3ShapeCommunicate"),
          value: "3shape"
        },
        {
          text: this.$t("nexusios.orderNow.headers.meditLink"),
          value: "medit"
        },
        {
          text: this.$t("nexusios.orderNow.headers.sironaConnect"),
          value: "sirona"
        },
        { text: this.$t("nexusios.orderNow.headers.csConnect"), value: "cs" },
        { text: this.$t("nexusios.orderNow.headers.iTero"), value: "itero" }
      ];
    },

    summaryImplantsHeaders() {
      return [
        { text: this.$t("common.tooth"), value: "tooth" },
        { text: this.$t("nexusios.orderNow.implantPlatform"), value: "name" }
      ];
    },
    summaryDesignHeaders() {
      return [
        { text: this.$t("common.name"), value: "name" },
        { text: this.$t("common.value"), value: "value" }
      ];
    },
    summaryUploadHeaders() {
      return [{ text: this.$t("common.name"), value: "name" }];
    },

    prettyIndicationType() {
      return this._.startCase(this.$t(`temp.${this.indicationType}`));
    },

    implantPlatformItems() {
      return this.implant_platforms.map(platform => {
        return { value: platform.id, text: platform.name };
      });
    },

    upperEnabled() {
      if (this.indicationType == "pre-op") {
        return false;
      }
      for (const item of this.implants) {
        if (this.upperTeeth.includes(item.tooth)) {
          return true;
        }
      }
      return false;
    },

    lowerEnabled() {
      if (this.indicationType == "pre-op") {
        return false;
      }
      for (const item of this.implants) {
        if (this.lowerTeeth.includes(item.tooth)) {
          return true;
        }
      }
      return false;
    },

    implantsEnabled() {
      return true;
    },

    preopEnabled() {
      return this.indicationType === "pre-op";
    },

    teeth() {
      return this.implants.map(item => item.tooth);
    },

    summary() {
      return {
        implants: this.implants,
        design: [
          {
            name: "Indication Type",
            value: this._.startCase(this.indicationType),
            show: true
          }
        ],
        scanFileItems: this.scanFileItems,
        imageFileItems: this.imageFileItems,
        specialRequest: this.specialRequest
      };
    },

    isScanGaugeRequired() {
      let notRequired = ["nexus-single-implant-crown"];
      if (notRequired.includes(this.indicationType)) {
        return false;
      }
      return true;
    },

    uploadFiles() {
      return this.scanFileItems.concat(this.imageFileItems);
    },

    uploadUrl() {
      return `/api/order/${this.caseId}/upload`;
    }
  },

  methods: {
    ...mapActions(["fetchUser"]),

    totalQueuedUploads() {
      return this.scanFileItems.length + this.imageFileItems.length;
    },

    allowedDates(val) {
      var dateLimit = new Date();
      var disabledDate = new Date().toISOString().substr(0, 10);
      dateLimit.setFullYear(dateLimit.getFullYear() + 2);
      return (
        val <= dateLimit.toISOString().substr(0, 10) && val >= disabledDate
      );
    },

    setIndicationType() {
      return this.$route.params.type;
    },

    setCustomiseTitle() {
      let intended_title = "";

      if (
        this.$route.query.intended == "single-bridge-order" &&
        this.$route.params.type == "nexus-single-implant-crown"
      ) {
        intended_title = this.$t("productCategories.single");
      } else intended_title = this.$t("productCategories.bridge");

      if (this.$route.query.intended != "single-bridge-order") {
        intended_title =
          this.$route.query.intended == "full-arch-order"
            ? this.$t("nexusios.patientOrder.fullArchUpgrade")
            : this.$t("nexusios.patientOrder.immediateFullArchRestoration");
      }
      return intended_title;
    },

    showTab(productCategory, tab) {
      let options = {
        "pre-op": {
          "pre-op": true,
          implants: false,
          lower: false,
          upper: false
        },
        fixed: {
          "pre-op": false,
          implants: true,
          lower: true,
          upper: true
        },
        removable: {
          "pre-op": false,
          implants: true,
          lower: true,
          upper: true
        },
        immediate: {
          "pre-op": false,
          implants: true,
          lower: true,
          upper: true
        },
        "single-bridge": {
          "pre-op": false,
          implants: true,
          lower: true,
          upper: true
        }
      };

      if (productCategory === null) {
        return false;
      }
      return options[productCategory][tab];
    },

    scansComplete() {
      this.scansUploaded = true;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    imagesComplete() {
      this.imagesUploaded = true;
      if (!this.$refs.scanDropzone) {
        this.scansUploaded = true;
      }
    },

    gotoTab(tabName) {
      this.tab = tabName;
    },

    isTab(tabName) {
      return tabName === this.tab;
    },

    startTab() {
      // productCategory, indicationType and tabName are the same for "pre-op"

      let tab;
      if (this.productCategory == "pre-op") {
        tab = "pre-op";
      } else {
        tab = "implants";
      }
      this.gotoTab(tab);
    },

    nextTab() {
      this.patchPatientOrder();
      this.setSummaryDetails();
      this.isComplete = this.isEntriesCompleted();
      switch (this.tab) {
        case "implants":
          if (this.indicationType === "pre-op") {
            this.gotoTab("pre-op");
            break;
          }
          if (this.upperEnabled) {
            this.gotoTab("upper");
            this.setDefaultBarMaterial("Upper");
            this.setDefaultToothMaterial("Upper");
          } else if (this.lowerEnabled) {
            this.gotoTab("lower");
            this.setDefaultBarMaterial("Lower");
            this.setDefaultToothMaterial("Lower");
          } else {
            this.gotoTab("upload-finish");
          }
          break;

        case "upper":
          if (this.lowerEnabled) {
            this.gotoTab("lower");
            this.setDefaultBarMaterial("Lower");
            this.setDefaultToothMaterial("Lower");
          } else {
            this.gotoTab("upload-finish");
          }
          break;

        case "lower":
          this.gotoTab("upload-finish");

          break;

        case "pre-op":
          this.gotoTab("upload-finish");
          break;
      }
      this.scrollToTop();
    },

    openSummaryDialog() {
      if (this.$refs.caseOrderForm.validate()) {
        this.isComplete = this.isEntriesCompleted();
        this.summaryDialog = true;
      }
      this.scrollToTop();
    },

    previousTab() {
      switch (this.tab) {
        case "upper":
          this.gotoTab("implants");
          break;

        case "lower":
          if (this.upperEnabled) {
            this.gotoTab("upper");
          } else {
            this.gotoTab("implants");
          }
          break;

        case "upload-finish":
          if (this.indicationType === "pre-op") {
            this.gotoTab("pre-op");
          } else if (this.lowerEnabled) {
            this.gotoTab("lower");
          } else {
            this.gotoTab("upper");
          }
          break;
      }
      this.scrollToTop();
    },

    selectTooth(value) {
      this.implantDialog = true;
      this.selectedTooth = value;
    },

    confirmImplant() {
      this.implantDialog = false;
      this.implants.push({
        tooth: this.selectedTooth,
        implantPlatform: this.implantPlatform
      });
    },

    confirmOrder() {
      this.summaryDialog = false;
    },

    cancelOrder() {
      this.summaryDialog = false;
    },

    changeIndicationSelected(value) {
      this.changeIndicationDialog = false;
      if (value == "yes") {
        const orderDetailId = this.savedData.order_detail[0].id;
        this.$router.push({
          name: this.orderIntended,
          query: {
            id: this.orderId,
            odid: orderDetailId,
            cat: this.orderProductCategory
          }
        });
      }
    },

    popSuccessMessage(message) {
      this.popToasterMessage("success", message);
    },

    popToasterMessage(messageType, message) {
      if (message) {
        (this.snackbar = true),
          (this.snackbarColor = messageType),
          (this.snackbarMessage = message);
      }
    },

    isEntriesCompleted() {
      this.errorMessage = null;
      if (!this.validateImplantPlatform()) {
        this.errorMessage = this.$t(
          "nexusios.patientOrder.errorMessages.implantDetail"
        );
        return false;
      }
      if (!this.validateUpperImplants() && this.upperEnabled) {
        this.errorMessage =
          this.$t("nexusios.patientOrder.errorMessages.upperImplant") +
          " " +
          this.requiredFieldMessage;
        return false;
      }
      if (!this.validateLowerImplants() && this.lowerEnabled) {
        this.errorMessage =
          this.$t("nexusios.patientOrder.errorMessages.lowerImplant") +
          " " +
          this.requiredFieldMessage;
        return false;
      }
      if (!this.validatePreOps()) {
        this.errorMessage =
          this.$t("nexusios.patientOrder.errorMessages.preOp") +
          " " +
          this.requiredFieldMessage;
        return false;
      }
      return true;
    },

    isEntryRequiredForIndicationType(key, indication) {
      const implantEntries = {
        toothShade: [
          "nexus-reinforced",
          "nexus-wraparound-immediate",
          "bridge-on-ti-base-immediate",
          "nexus-hybrid",
          "nexus-bridge",
          "nexus-wraparound",
          "nexus-removable",
          "nexus-hra",
          "nexus-single-implant-crown",
          "nexus-micro-unit-bridge",
          "custom-abutment-bridge"
        ],
        printedTryInRequired: [
          "nexus-hybrid",
          "nexus-bridge",
          "nexus-wraparound",
          "nexus-removable",
          "nexus-hra",
          "nexus-micro-unit-bridge",
          "custom-abutment-bridge"
        ],
        barMaterial: [
          "nexus-hybrid",
          "nexus-bridge",
          "nexus-single-implant-crown",
          "nexus-micro-unit-bridge",
          "custom-abutment-bridge"
        ],
        overlayMaterial: [
          "nexus-hybrid",
          "nexus-bridge",
          "nexus-removable",
          "nexus-hra",
          "nexus-single-implant-crown",
          "nexus-micro-unit-bridge",
          "custom-abutment-bridge"
        ]
      };

      return implantEntries[key].includes(indication);
    },

    validateImplantPlatform() {
      return this.indicationType === "pre-op"
        ? true
        : this.implants
        ? true
        : false;
    },

    validateUpperImplants() {
      this.requiredFieldMessage = "";
      if (this.indicationType !== "pre-op") {
        if (!this.upperOptions.toothShade) {
          if (
            this.isEntryRequiredForIndicationType(
              "toothShade",
              this.indicationType
            )
          ) {
            this.requiredFieldMessage = this.$t(
              "nexusios.patientOrder.requiredMessages.toothShade"
            );
            return false;
          }
        }
        if (!this.upperOptions.printedTryInRequired) {
          if (
            this.isEntryRequiredForIndicationType(
              "printedTryInRequired",
              this.indicationType
            )
          ) {
            this.requiredFieldMessage = this.$t(
              "nexusios.patientOrder.requiredMessages.printedTryIn"
            );
            return false;
          }
        }
        if (!this.upperOptions.barMaterial) {
          if (
            this.isEntryRequiredForIndicationType(
              "barMaterial",
              this.indicationType
            )
          ) {
            this.requiredFieldMessage = this.$t(
              "nexusios.patientOrder.requiredMessages.barMaterial"
            );
            return false;
          }
        }
        if (!this.upperOptions.overlayMaterial) {
          if (
            this.isEntryRequiredForIndicationType(
              "overlayMaterial",
              this.indicationType
            )
          ) {
            this.requiredFieldMessage = this.$t(
              "nexusios.patientOrder.requiredMessages.toothMaterial"
            );
            return false;
          }
        }
      }
      return true;
    },

    validateLowerImplants() {
      if (this.indicationType !== "pre-op") {
        if (!this.lowerOptions.toothShade) {
          if (
            this.isEntryRequiredForIndicationType(
              "toothShade",
              this.indicationType
            )
          ) {
            this.requiredFieldMessage = this.$t(
              "nexusios.patientOrder.requiredMessages.toothShade"
            );
            return false;
          }
        }

        if (!this.lowerOptions.printedTryInRequired) {
          if (
            this.isEntryRequiredForIndicationType(
              "printedTryInRequired",
              this.indicationType
            )
          ) {
            this.requiredFieldMessage = this.$t(
              "nexusios.patientOrder.requiredMessages.printedTryIn"
            );
            return false;
          }
        }
      }
      if (!this.lowerOptions.barMaterial) {
        if (
          this.isEntryRequiredForIndicationType(
            "barMaterial",
            this.indicationType
          )
        ) {
          this.requiredFieldMessage = this.$t(
            "nexusios.patientOrder.requiredMessages.barMaterial"
          );
          return false;
        }
      }
      if (!this.lowerOptions.overlayMaterial) {
        if (
          this.isEntryRequiredForIndicationType(
            "overlayMaterial",
            this.indicationType
          )
        ) {
          this.requiredFieldMessage = this.$t(
            "nexusios.patientOrder.requiredMessages.toothMaterial"
          );
          return false;
        }
      }
      return true;
    },

    validatePreOps() {
      if (this.indicationType === "pre-op") {
        if (!this.preopOptions) {
          return false;
        }
      }
      return true;
    },

    validateUploads() {
      this.requiredFieldMessage = "";
      if (this.sendScanVia == "upload") {
        this.requiredFieldMessage = this.$t(
          "nexusios.patientOrder.requiredMessages.fileUploads"
        );
        return this.scanFileItems.length != 0
          ? this.imageFileItems.lengh != 0
            ? true
            : false
          : false;
      }
      if (!this.dateRequired) {
        this.requiredFieldMessage = this.preopEnabled()
          ? this.$t("nexusios.patientOrder.requiredMessages.surgeryDate")
          : this.$t("nexusios.patientOrder.requiredMessages.requiredDate");
        return false;
      }
      return this.imageFileItems.length != 0 ? true : false;
    },

    // Codes below are all related to backend data update/retrieve
    setSummaryDetails() {
      this.setSummaryUpperBar();
      this.setSummaryLowerBar();
      this.setSummaryPreop();
    },

    setSummaryUpperBar() {
      this.summaryUpperBar = [];
      if (this.indicationType === "design-only-immediate") {
        Object.keys(this.upperOptions).map(key => {
          if (
            this.upperOptions[key] !== null &&
            this.upperOptions !== undefined
          ) {
            this.summaryUpperBar.push({
              name: this._.startCase(key),
              value: this._.startCase(this.upperOptions[key]),
              headingText: this._.startCase(key),
              valueText: this._.startCase(this.upperOptions[key]),
              show: true
            });
          }
        });
        return;
      }
      Object.keys(this.upperOptions).map(key => {
        if (
          this.upperOptions[key] !== null &&
          this.upperOptions !== undefined
        ) {
          this.summaryUpperBar.push({
            name: this._.startCase(key),
            value: this._.startCase(this.upperOptions[key]),
            headingText:
              key != "titaniumSleeveSuperstructure"
                ? this.$t(`temp.${key}`)
                : this.$t(`temp.telescopicFramework`),
            valueText: this.$t(
              `nexusios.orderNow.orderOptions.${this.upperOptions[key]}`
            ),
            show: true
          });
        }
      });
    },

    setSummaryLowerBar() {
      this.summaryLowerBar = [];
      if (this.indicationType === "design-only-immediate") {
        Object.keys(this.upperOptions).map(key => {
          if (
            this.upperOptions[key] !== null &&
            this.upperOptions !== undefined
          ) {
            this.summaryLowerBar.push({
              name: this._.startCase(key),
              value: this._.startCase(this.upperOptions[key]),
              headingText: this._.startCase(key),
              valueText: this._.startCase(this.upperOptions[key]),
              show: true
            });
          }
        });
        return;
      }
      Object.keys(this.lowerOptions).map(key => {
        if (
          this.lowerOptions[key] !== null &&
          this.lowerOptions !== undefined
        ) {
          this.summaryLowerBar.push({
            name: this._.startCase(key),
            value: this._.startCase(this.lowerOptions[key]),
            headingText:
              key != "titaniumSleeveSuperstructure"
                ? this.$t(`temp.${key}`)
                : this.$t(`temp.telescopicFramework`),
            valueText: this.$t(
              `nexusios.orderNow.orderOptions.${this.lowerOptions[key]}`
            ),
            show: true
          });
        }
      });
    },

    setSummaryPreop() {
      this.summaryPreop = [];
      Object.keys(this.preopOptions).map(key => {
        if (
          this.preopOptions[key] !== null &&
          this.preopOptions !== undefined &&
          this.preopOptions[key].length !== 0
        ) {
          this.summaryPreop.push({
            name: this._.startCase(key),
            value: this._.startCase(this.preopOptions[key]),
            headingText: key
              ? this.$t(`temp.${key}`)
              : this.$t(`temp.undefined`),
            valueText: this.preopOptions[key]
              ? this.$t(
                  `nexusios.orderNow.orderOptions.${this.preopOptions[key]}`
                )
              : this.$t(`temp.undefined`),
            show: true
          });
        }
      });
    },

    setImplantPlatform() {
      this.implants = this.savedData.implants;
    },

    setImplantDetails(location) {
      const options = this.savedData.implant_detail.filter(item => {
        return item.implants_loc == location ? item : null;
      });

      let details = {};
      if (options && options.length > 0) {
        details = {
          abutmentMaterial: options[0].abutment_material,
          abutmentTinting: options[0].abutment_tinting,
          attachment: options[0].attachment,
          barMaterial: options[0].bar_material,
          barTinting: options[0].bar_tinting,
          baseMaterial: options[0].base_material,
          distalExtensionsPatientsLeft: options[0].distal_ext_left,
          distalExtensionsPatientsRight: options[0].distal_ext_right,
          facialLayeringRequired: options[0].facial_layering,
          overlayMaterial: options[0].overlay_material,
          pinkGingivalColour: options[0].pink_gingival_color,
          toothShade: options[0].tooth_shade,
          ponticDesign: options[0].pontic_design,
          titaniumSleeveSuperstructure: options[0].telescopic_frame
            ? options[0].telescopic_frame == true
              ? "yes"
              : this.options[0].telescopic_frame == "no"
              ? false
              : null
            : options[0].telescopic_frame,
          tissueRelationshipDistance: options[0].tissue_distance
        };
      }
      return details;
    },

    setUpperOptions() {
      this.upperOptions = this.setImplantDetails("Upper");
    },

    setLowerOptions() {
      this.lowerOptions = this.setImplantDetails("Lower");
    },

    setUpPreopDefaults() {
      this.preopOptions = {
        archesRestored: this.savedData.order_detail[0].preop_arches,
        interimDenture:
          this.savedData.order_detail[0].preop_interim_denture !== null
            ? this.savedData.order_detail[0].preop_interim_denture
              ? "yes"
              : "no"
            : null,
        restorativeProtocol: this.savedData.order_detail[0].preop_restorative,
        rentScanGaugeKit:
          this.savedData.order_detail[0].preop_scan_gauge_kit !== null
            ? this.savedData.order_detail[0].preop_scan_gauge_kit
              ? "yes"
              : "no"
            : null,
        surgicalScanTemplate:
          this.savedData.order_detail[0].preop_surgical_scan !== null
            ? this.savedData.order_detail[0].preop_surgical_scan
              ? "yes"
              : "no"
            : null,
        virtualSmileDesign: this.savedData.order_detail[0].preop_vsdesign
      };
    },

    setUpDefaults() {
      this.practiceId = this.savedData.practice_id;
      this.practiceName = this.savedData.practice_name;
      this.kitId = this.savedData.scanGaugeKitId;
      this.patientName = this.savedData.patient_name;
      this.restorativeClinician = this.savedData.restorative_clinician;
      this.dateRequired = this.savedData.order_detail[0].date_required
        ? this.savedData.order_detail[0].date_required.substr(0, 10)
        : new Date().toISOString().substr(0, 10);
      this.orderIntended = this.savedData.order_detail[0].intended
        ? this.savedData.order_detail[0].intended
        : this.$route.query.intended;
      // "single-bridge" ,"pre-op", "immediate", when "definitve" -> it will be changed to fixed", "removable" as selected
      this.orderProductCategory = this.savedData.order_detail[0]
        .product_category
        ? this.savedData.order_detail[0].product_category
        : this.$route.query.product;
      this.orderProductCategory =
        this.orderProductCategory == "definitive"
          ? this.$route.query.product
          : this.orderProductCategory;
      this.setImplantPlatform();
      this.setUpperOptions();
      this.setLowerOptions();
      this.setUpPreopDefaults();
      this.loading = false;
      this.parentCaseId = this.savedData.parent_case_id
        ? this.savedData.parent_case_id
        : this.orderProductCategory == "immediate"
        ? this.savedData.immediate_ids.preop_oep_case_id
        : null;
    },

    setStageStatus() {
      this.result = this.caseId ? "Pending" : "Incomplete";
      if (this.oepCaseId) this.result = "Ordered";
      if (this.result != "Incomplete") this.cancelScheduledEmail();
      return this.result;
    },

    generatePatchPayload() {
      let payload = {
        order_detail: [
          {
            date_required: this.dateRequired,
            indications: this.indicationType,
            case_id: this.caseId,
            oep_case_id: this.oepCaseId,
            oep_case_mkey: this.oepCaseMkey,
            related_case_id: this.relatedCaseId,
            related_oep_case_id: this.relatedOepCaseId,
            intended: this.orderIntended,
            product_category: this.orderProductCategory,
            patient_photos_uploaded: this.imagesUploaded ? true : false,
            preop_arches: this.preopOptions.archesRestored,
            preop_interim_denture:
              this.preopOptions.interimDenture == "yes" ? true : false,
            preop_restorative: this.preopOptions.restorativeProtocol,
            preop_scan_gauge_kit: this.preopOptions.rentScanGaugeKit,
            preop_surgical_scan:
              this.preopOptions.surgicalScanTemplate == "yes" ? true : false,
            preop_vsdesign: this.preopOptions.virtualSmileDesign,
            scan_sent_type: this.sendScanVia,
            scans_uploaded: this.scansUploaded ? true : false,
            special_request: this.specialRequest,
            stage_status: this.setStageStatus(),
            surgery_date: this.dateRequired,
            terms_confirmed: this.agreeTermsConditions ? true : false
          }
        ],
        implants: this.implants,
        implant_detail: this.implantDetailPayload()
      };
      return payload;
    },

    implantDetailPayload() {
      let payload = [];
      if (this.upperOptions) {
        let detail = {
          order_id: this.orderId,
          order_detail_id: this.savedData.order_detail[0].id,
          implants_loc: "Upper",
          bar_material: this.upperOptions.barMaterial,
          bar_tinting: this.upperOptions.barTinting,
          attachment: this.upperOptions.attachment,
          base_material: this.upperOptions["baseMaterial"],
          telescopic_frame:
            this.upperOptions["titaniumSleeveSuperstructure"] == "yes"
              ? true
              : this.upperOptions["titaniumSleeveSuperstructure"] == "no"
              ? false
              : null,
          abutment_material: this.upperOptions["abutmentMaterial"],
          abutment_tinting: this.upperOptions["abutmentTinting"],
          overlay_material: this.upperOptions["overlayMaterial"],
          tooth_shade: this.upperOptions["toothShade"],
          pink_gingival_color: this.upperOptions["pinkGingivalColour"],
          facial_layering: this.upperOptions["facialLayeringRequired"],
          distal_ext_left: this.upperOptions["distalExtensionsPatientsLeft"],
          distal_ext_right: this.upperOptions["distalExtensionsPatientsRight"],
          tissue_distance: this.upperOptions["tissueRelationshipDistance"],
          pontic_design: this.upperOptions["ponticDesign"]
        };
        payload.push(detail);
      }

      if (this.lowerOptions) {
        let detail = {
          order_id: this.orderId,
          order_detail_id: this.savedData.order_detail[0].id,
          implants_loc: "Lower",
          bar_material: this.lowerOptions.barMaterial,
          bar_tinting: this.lowerOptions.barTinting,
          attachment: this.lowerOptions.attachment,
          base_material: this.lowerOptions["baseMaterial"],
          telescopic_frame:
            this.lowerOptions["titaniumSleeveSuperstructure"] == "yes"
              ? true
              : this.lowerOptions["titaniumSleeveSuperstructure"] == "no"
              ? false
              : null,
          abutment_material: this.lowerOptions["abutmentMaterial"],
          abutment_tinting: this.lowerOptions["abutmentTinting"],
          overlay_material: this.lowerOptions["overlayMaterial"],
          tooth_shade: this.lowerOptions["toothShade"],
          pink_gingival_color: this.lowerOptions["pinkGingivalColour"],
          facial_layering: this.lowerOptions["facialLayeringRequired"],
          distal_ext_left: this.lowerOptions["distalExtensionsPatientsLeft"],
          distal_ext_right: this.lowerOptions["distalExtensionsPatientsRight"],
          tissue_distance: this.lowerOptions["tissueRelationshipDistance"],
          pontic_design: this.lowerOptions["ponticDesign"]
        };
        payload.push(detail);
      }

      return payload;
    },

    generateCreateOrderPayload(locationFlag, relatedUpperOepCaseId) {
      const orderDetailId = this.savedData.order_detail[0].id;
      let payload = {
        order_id: this.orderId,
        detail_id: orderDetailId,
        indication_type: this.createPayloadIndicationType(
          this.orderProductCategory,
          locationFlag,
          this.indicationType
        ),
        kit_id: this.kitId,
        patient_name: this.patientName,
        restorative_clinician: this.restorativeClinician,
        date_required: this.dateRequired,
        time_required: this.timeRequired,
        dispatch_notes: "Date Required: " + this.dateRequired,
        send_scan_via: this.sendScanVia,
        special_request: this.specialRequest,
        implants: this.implantsPayload(locationFlag), // implantPlatform
        preop_options:
          (this.orderProductCategory == "immediate") |
          (this.orderProductCategory == "pre-op")
            ? this.preopOptions
            : null
      };

      // set upper/lower options immediate linked to Preop
      if (this.parentCaseId && this.orderProductCategory == "immediate") {
        if (this.upperOptions && this.upperEnabled) {
          this.upperOptions["send_scan_via"] = this.sendScanVia;
          this.upperOptions["date_required"] = this.dateRequired;
        }
        if (this.lowerOptions && this.lowerEnabled) {
          this.lowerOptions["send_scan_via"] = this.sendScanVia;
          this.lowerOptions["date_required"] = this.dateRequired;
        }
        payload["upper_options"] = this.upperOptions ? this.upperOptions : null;
        payload["lower_options"] = this.lowerOptions ? this.lowerOptions : null;
        payload["immediacy_implants"] = this.implantsPayload("all");
      }

      // set upper/lower options FullArch, Single, and immediate NOT linked to Preop
      if (
        this.orderProductCategory !== "pre-op" &&
        !(this.parentCaseId && this.orderProductCategory == "immediate")
      ) {
        this.upperOptions["printedTryInRequired"] =
          this.upperOptions["printedTryInRequired"] === "tryInYes" ||
          this.upperOptions["printedTryInRequired"] === "tryInDesignOnly"
            ? "yes"
            : "no";
        payload["upper_options"] =
          locationFlag == "upper" || locationFlag == "all"
            ? this.upperOptions
              ? this.upperOptions
              : null
            : null;
        this.lowerOptions["printedTryInRequired"] =
          this.lowerOptions["printedTryInRequired"] === "tryInYes" ||
          this.lowerOptions["printedTryInRequired"] === "tryInDesignOnly"
            ? "yes"
            : "no";
        payload["lower_options"] =
          locationFlag == "lower" || locationFlag == "all"
            ? this.lowerOptions
              ? this.lowerOptions
              : null
            : null;
      }

      if (locationFlag == "lower" && relatedUpperOepCaseId) {
        payload["upper_oep_case_id"] = relatedUpperOepCaseId;
      }
      const immediateId = this.getWorkflowImmediateId();
      if (immediateId) {
        payload["immediate_detail_id"] = immediateId;
      }
      return payload;
    },

    implantsPayload(location) {
      let finalImplants = [];
      if (location == "upper") {
        for (const item of this.implants) {
          if (this.upperTeeth.includes(item.tooth)) {
            finalImplants.push(item);
          }
        }
      }
      if (location == "lower") {
        for (const item of this.implants) {
          if (this.lowerTeeth.includes(item.tooth)) {
            finalImplants.push(item);
          }
        }
      }
      if (location == "all" || location == "pre-op") {
        finalImplants = this.implants;
      }
      return finalImplants;
    },

    createPayloadIndicationType(category, locationFlag, indicationType) {
      if (category == "pre-op") {
        return this.preopOptions.virtualSmileDesign;
      }
      return indicationType;
    },

    setDefaultBarMaterial(location) {
      switch (this.indicationType) {
        case "nexus-reinforced":
          return this.setBarMaterial(location, "peek");
        case "nexus-wraparound-immediate":
          return this.setBarMaterial(location, "titanium");
        case "bridge-on-ti-base-immediate":
          return this.setBarMaterial(location, "titanium");
        case "nexus-wraparound":
          return this.setBarMaterial(location, "titanium");
        case "nexus-removable":
          return this.setBarMaterial(location, "titanium");
        case "nexus-hra":
          return this.setBarMaterial(location, "titanium");
        default:
          return;
      }
    },

    setBarMaterial(location, material) {
      location == "Upper" && this.upperEnabled
        ? (this.upperOptions.barMaterial = material)
        : location == "Lower" && this.lowerEnabled
        ? (this.lowerOptions.barMaterial = material)
        : null;
    },

    setDefaultToothMaterial(location) {
      switch (this.indicationType) {
        case "nexus-reinforced":
          return this.setToothMaterial(location);
        case "nexus-wraparound-immediate":
          return this.setToothMaterial(location);
        case "bridge-on-ti-base-immediate":
          return this.setToothMaterial(location);
        case "nexus-wraparound":
          return this.setToothMaterial(location);
        default:
          return;
      }
    },

    setToothMaterial(location) {
      location == "Upper" && this.upperEnabled
        ? (this.upperOptions.overlayMaterial = "acrylic")
        : location == "Lower" && this.lowerEnabled
        ? (this.lowerOptions.overlayMaterial = "acrylic")
        : null;
    },

    isOrdered() {
      return this.savedData.order_detail[0].case_id ? true : false;
    },

    async cancelScheduledEmail() {
      await this.$httpClient.cancelEmail(this.savedData.scheduled_email_id);
    },

    async patchPatientOrder() {
      const orderDetailId = this.savedData.order_detail[0].id;
      const immediateId = this.getWorkflowImmediateId();
      const definitiveId = this.getWorkflowDefinitiveId();
      await this.$httpClient.updatePatientOrderDetail(
        this.orderId,
        orderDetailId,
        immediateId,
        definitiveId,
        this.generatePatchPayload()
      );
    },

    getWorkflowImmediateId() {
      let immediateId = null;
      if (this.orderIntended == "immediate-restoration-order") {
        if (
          this.orderProductCategory == "pre-op" &&
          this.tab == "upload-finish"
        ) {
          immediateId = this.savedData.immediate_ids.immediate_id;
        }
      }
      return immediateId;
    },

    getWorkflowDefinitiveId() {
      let definitiveId = null;
      if (this.orderProductCategory == "immediate") {
        definitiveId = this.savedData.immediate_ids.definitive_id;
      }
      return definitiveId;
    },

    async getPatientOrder() {
      this.orderId = this.$route.query.id;
      this.orderIntended = this.$route.query.intended;
      // "single-bridge" ,"fixed", "pre-op", "immediate"
      this.orderProductCategory = this.$route.query.product;
      const orderDetailId = this.$route.query.odid;
      await this.$httpClient
        .getPatientOrderDetail(this.orderId, orderDetailId)
        .then(data => {
          this.savedData = data;
          if (this.isOrdered()) {
            this.$router.push({ name: "order" });
          } else {
            this.setUpDefaults();
          }
        });
    },

    async order() {
      if (this.$refs.caseOrderForm.validate()) {
        if (this.orderProductCategory == "pre-op") {
          this.request_order("pre-op");
        } else {
          this.request_order("all");
        }
      }
    },

    async request_order(locationFlag) {
      this.summaryDialog = false;
      this.submitDialog = false;
      this.progressDialog = true;
      await this.$httpClient
        .createOrder({
          practice_id: this.practiceId,
          scan_gauge_kit_id: this.scanGaugeKitId,
          expected_number_of_files: this.totalQueuedUploads(),
          parent_case_id: this.parentCaseId,
          payload: this.generateCreateOrderPayload(locationFlag, null)
        })
        .then(response => {
          this.caseId = response.case_id;
          this.oepCaseId = response.oep_case_id;
          this.oepCaseMkey = response.oep_case_mkey;
          if (this.scanFileItems.length > 0) {
            this.scansUploaded = false;
          }
          if (this.imageFileItems.length > 0) {
            this.imagesUploaded = false;
          }
        });
    }
  },

  async mounted() {
    this.getPatientOrder();
    this.startTab();
    this.productOptionsKey += 1;
    this.step = 2;
  }
};
</script>

<style>
.v-stepper {
  width: 100%;
}

.v-stepper__content {
  padding: 0;
}

.v-btn-toggle > .v-btn {
  border-radius: 10px !important;
}
</style>

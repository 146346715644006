<template>
  <v-btn-toggle
    v-model="required"
    class="d-flex flex-wrap"
    active-class="active-button"
    borderless
    background-color="rgba(0,0,0,0)"
    @change="requiredSelected"
  >
    <v-btn class="ma-1" value="send" :disabled="disabled">
      Yes, I require Ti-Bases and Screws
    </v-btn>
    <v-btn class="ma-1" value="dont-send" :disabled="disabled">
      No, I have required components
    </v-btn>
    <v-btn class="ma-1" value="sourced-from-3rd-party" :disabled="disabled">
      No, I will source tapered screws from a 3rd party
    </v-btn>
    <small class="mt-3">
      *Design Only provisionals are designed to specific component libraries,
      see
      <a
        href="https://www.nexusios.com/provisional-compatibility"
        target="_blank"
        >provisional compatibility list</a
      >
      for more information. A Nexus representative will call you for more
      information if necessary.
    </small>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "RequiredComponents",

  props: ["value", "disabled"],

  data() {
    return {
      required: this.value
    };
  },

  methods: {
    requiredSelected() {
      this.$emit("input", this.required);
    }
  }
};
</script>
